
.map-image{
    --bg-parallax-image: url(../images/map-tile.png);    
    --bg-parallax-opacity: 1;
    height: 320px;
    &.parallax::after{
        border-radius: 30px;
    }
    .map-marker{	//=======================================================Update
        position: absolute;
        top: 60px;
        left: 42%;
		color: var(--#{$prefix}primary);
		&.map-marker-style-1{
			color: var(--#{$prefix}heading-color);
		}
        svg{
            height: 40px;
            width: 40px;
        }
    }    

	&.map-image-2{
		--bg-parallax-image: url(../images/map-tile-2.jpg);    
    	--bg-parallax-opacity: 1;
		height: 575px;
		@include media-breakpoint-down(md) {
			height: 375px;
		}
		.map-popup-content,.map-marker{
			display: none;
		}
		.marker-content{
			position: absolute;
			top: 200px;
			left: 170px;
			.map-popup-content,.map-marker{
				display: block;				
				left: 0;
			}
			.map-marker{
				top: 0;
			}
			.map-popup-content{
				top: 50px;
				left: 12px;				
				border: 2px solid rgba(255,255,255, 0.63);
				background: rgba(255,255,255, 0.8);
				padding: 32px 30px;
				p{
					margin-bottom: 0;
				}
			}
			@include media-breakpoint-down(md) {
				top: 80px;
				left: 125px;
			}
			@include media-breakpoint-down(sm) {
				top: 80px;
				left: 40px;
			}
		}
	}
}
.map-popup-content{
	border-radius: 15px;
	border: 2px solid rgba(255, 255, 255, 0.63);
	background: rgba(255, 255, 255, 0.11);
	backdrop-filter: blur(5px);
	width: 240px;
	height: auto;
	padding: 8px 16px 14px 12px;
	position: absolute;
	left: 47%;
	top: 110px;
	h3,p{
		color: var(--#{$prefix}heading-color);
		font-weight: 600;
	}
	p{
		font-size: 14px;
	}
	@include media-breakpoint-down(md) {
        left: calc(50% - 120px);
    }
}
@media screen and (min-width: 991px) and (max-width: 1399px){
	.map-popup-content{
		left: 28%;
	}
}
.eventiva-marker-icon {
	svg {
		width: 100%;
		height: 100%;
		fill: var(--#{$prefix}primary);
	}
}

.leaflet-popup-close-button{
	top: 5px !important;
	right: 6px !important;
	border-radius: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: $transition-base;

	span{
		position: relative;
		bottom: 1px;
		color: var(--#{$prefix}heading-color);
		transition: $transition-base;
	}
	&:hover{
		span{			
			color: var(--#{$prefix}white);
		}
	}
}
.leaflet-pane{
	z-index: 40;
}
.leaflet-top, .leaflet-bottom{
	z-index: 50;
	
}
.leaflet-popup-tip-container {
	width: 0;
}
.leaflet-routing-collapse-btn {	
	right: 30px;
	color: var(--#{$prefix}primary);
	cursor: pointer;
}
.leaflet-routing-container-hide .leaflet-routing-collapse-btn {	
	left: 2px;
	top: -1px;
	width: 26px;
	height: 23px;	
	background-repeat: no-repeat;
}
#RoutingMap {
	width: 100%;
	min-height: calc(100vh - 250px);
}
.modal#RoutingMapModal {
	--#{$prefix}modal-width: 100%;
	z-index: 999999;
	.eventiva-destination-marker-icon {
		margin-left: -12px;
		margin-top: -35px;
		svg {
			fill: var(--#{$prefix}primary);
		}
	}
	.eventiva-current-marker-icon {
		svg {
			fill: green;
		}
	}
}
.routing-map-modal {
	img {
		&.leaflet-marker-icon {
			opacity: 0;
		}
	}
}

.leaflet-container{
	font-family: var(--#{$prefix}font-family-style-1);
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
	border: 2px solid rgba(var(--#{$prefix}primary-rgb), .7);
	background-clip: padding-box;
}



.leaflet-routing-container {	
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important;
}
.leaflet-popup-content{
	min-width: 184px;
	margin: 0;
}

@include media-breakpoint-up(xl) {
	.direction-style-2{
		position: relative;
		left: -38px;
	}
}

@include media-breakpoint-up(xxl) {
	.direction-style-2{
		position: relative;
		left: -18px;
	}
}

@media (min-width: 1450px){
	.direction-style-2{
		position: relative;
		left: -60px;
	}
}