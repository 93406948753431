// ======================== About-1 ==========================>>>>
.about-1{    
    .ellipse-image-1{
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: -100px;
        margin-left: -35px;
        z-index: -1;
        animation: move-y 4s infinite alternate;
    }
}
.about-image-1{    
    z-index: 1;
    max-width: 582px;
    width: 100%;
    position: relative;        
    .about-image-wrapper{
        --#{$prefix}about-img-border-radius: 30px;
        img{
            border-radius: var(--#{$prefix}about-img-border-radius);
        }
        &::after{
            content:'';
            position:absolute;
            left:0; 
            top:0;
            width:100%; 
            height:100%;
            display:inline-block;
            border-radius: var(--#{$prefix}about-img-border-radius);  
            background: linear-gradient(224deg, rgba(var(--#{$prefix}primary-rgb),.6) 4.06%, rgba(var(--#{$prefix}secondary-rgb),.5) 76.9%); 
        }
    }       
}
.about-content-wrapper{
    --#{$prefix}about-content-margin-right: 65px;
    margin-right: var(--#{$prefix}about-content-margin-right);
    @include media-breakpoint-down(lg) {
        --#{$prefix}about-content-margin-right: 30px;
    }
}
.about-image-2{
    --#{$prefix}about-img-margin-top: 50px;
    --#{$prefix}about-img-margin-left: 65px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: var(--#{$prefix}about-img-margin-top);
    margin-left: var(--#{$prefix}about-img-margin-left);
    max-width: 582px;
    max-height: 440px;
    background: linear-gradient(223deg, var(--#{$prefix}primary) 4.06%, var(--#{$prefix}secondary) 92.2%);
    width: 100%;
    height: 100%;
    border-radius: 30px;
    img{
        border-radius: 33px;
    }
    @include media-breakpoint-down(lg) {        
        --#{$prefix}about-img-margin-top: 24px;
        --#{$prefix}about-img-margin-left: 26px;
    }
}

// ======================== About-1 ==========================>>>>

.about-3-bg{
    --#{$prefix}about-3-bg: #EBEDFF;    
    background-color: var(--#{$prefix}about-3-bg);
}

//=======================================================Update
.hall-image img{
    border-radius: 30px !important;
}