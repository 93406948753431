/*!
   * Bootstrap  v5.3.1 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #04000A;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #04000A;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 4, 0, 10;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Open Sans", "Roboto", "Plus Jakarta Sans", "Poppins", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #04000A;
  --bs-body-color-rgb: 4, 0, 10;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(4, 0, 10, 0.75);
  --bs-secondary-color-rgb: 4, 0, 10;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(4, 0, 10, 0.5);
  --bs-tertiary-color-rgb: 4, 0, 10;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #04000A;
  --bs-body-bg-rgb: 4, 0, 10;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #1c1d25;
  --bs-tertiary-bg-rgb: 28, 29, 37;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.34375rem + 1.125vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.1875rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.5rem + 3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 3.75rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.3875rem + 1.65vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 0.625rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 0.625rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 0.9375rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 0.9375rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 1.25rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 1.25rem;
}

.g-30,
.gx-30 {
  --bs-gutter-x: 1.875rem;
}

.g-30,
.gy-30 {
  --bs-gutter-y: 1.875rem;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 2.5rem;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 2.5rem;
}

.g-50,
.gx-50 {
  --bs-gutter-x: 3.125rem;
}

.g-50,
.gy-50 {
  --bs-gutter-y: 3.125rem;
}

.g-60,
.gx-60 {
  --bs-gutter-x: 3.75rem;
}

.g-60,
.gy-60 {
  --bs-gutter-y: 3.75rem;
}

.g-70,
.gx-70 {
  --bs-gutter-x: 4.375rem;
}

.g-70,
.gy-70 {
  --bs-gutter-y: 4.375rem;
}

.g-80,
.gx-80 {
  --bs-gutter-x: 5rem;
}

.g-80,
.gy-80 {
  --bs-gutter-y: 5rem;
}

.g-90,
.gx-90 {
  --bs-gutter-x: 5.625rem;
}

.g-90,
.gy-90 {
  --bs-gutter-y: 5.625rem;
}

.g-100,
.gx-100 {
  --bs-gutter-x: 6.25rem;
}

.g-100,
.gy-100 {
  --bs-gutter-y: 6.25rem;
}

.g-120,
.gx-120 {
  --bs-gutter-x: 7.5rem;
}

.g-120,
.gy-120 {
  --bs-gutter-y: 7.5rem;
}

.g-130,
.gx-130 {
  --bs-gutter-x: 8.125rem;
}

.g-130,
.gy-130 {
  --bs-gutter-y: 8.125rem;
}

.g-140,
.gx-140 {
  --bs-gutter-x: 8.75rem;
}

.g-140,
.gy-140 {
  --bs-gutter-y: 8.75rem;
}

.g-150,
.gx-150 {
  --bs-gutter-x: 9.375rem;
}

.g-150,
.gy-150 {
  --bs-gutter-y: 9.375rem;
}

.g-180,
.gx-180 {
  --bs-gutter-x: 11.25rem;
}

.g-180,
.gy-180 {
  --bs-gutter-y: 11.25rem;
}

.g-200,
.gx-200 {
  --bs-gutter-x: 12.5rem;
}

.g-200,
.gy-200 {
  --bs-gutter-y: 12.5rem;
}

.g-220,
.gx-220 {
  --bs-gutter-x: 13.75rem;
}

.g-220,
.gy-220 {
  --bs-gutter-y: 13.75rem;
}

.g-230,
.gx-230 {
  --bs-gutter-x: 14.375rem;
}

.g-230,
.gy-230 {
  --bs-gutter-y: 14.375rem;
}

.g-250,
.gx-250 {
  --bs-gutter-x: 15.625rem;
}

.g-250,
.gy-250 {
  --bs-gutter-y: 15.625rem;
}

.g-300,
.gx-300 {
  --bs-gutter-x: 18.75rem;
}

.g-300,
.gy-300 {
  --bs-gutter-y: 18.75rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-50,
  .gx-sm-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-50,
  .gy-sm-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-sm-60,
  .gx-sm-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-60,
  .gy-sm-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-70,
  .gx-sm-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-sm-70,
  .gy-sm-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-sm-80,
  .gx-sm-80 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-80,
  .gy-sm-80 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-90,
  .gx-sm-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-sm-90,
  .gy-sm-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-sm-100,
  .gx-sm-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-sm-100,
  .gy-sm-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-sm-120,
  .gx-sm-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-120,
  .gy-sm-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-130,
  .gx-sm-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-sm-130,
  .gy-sm-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-sm-140,
  .gx-sm-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-sm-140,
  .gy-sm-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-sm-150,
  .gx-sm-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-sm-150,
  .gy-sm-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-sm-180,
  .gx-sm-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-sm-180,
  .gy-sm-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-sm-200,
  .gx-sm-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-sm-200,
  .gy-sm-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-sm-220,
  .gx-sm-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-sm-220,
  .gy-sm-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-sm-230,
  .gx-sm-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-sm-230,
  .gy-sm-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-sm-250,
  .gx-sm-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-sm-250,
  .gy-sm-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-sm-300,
  .gx-sm-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-sm-300,
  .gy-sm-300 {
    --bs-gutter-y: 18.75rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-50,
  .gx-md-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-50,
  .gy-md-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-md-60,
  .gx-md-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-60,
  .gy-md-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-70,
  .gx-md-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-md-70,
  .gy-md-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-md-80,
  .gx-md-80 {
    --bs-gutter-x: 5rem;
  }
  .g-md-80,
  .gy-md-80 {
    --bs-gutter-y: 5rem;
  }
  .g-md-90,
  .gx-md-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-md-90,
  .gy-md-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-md-100,
  .gx-md-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-md-100,
  .gy-md-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-md-120,
  .gx-md-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-120,
  .gy-md-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-130,
  .gx-md-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-md-130,
  .gy-md-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-md-140,
  .gx-md-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-md-140,
  .gy-md-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-md-150,
  .gx-md-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-md-150,
  .gy-md-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-md-180,
  .gx-md-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-md-180,
  .gy-md-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-md-200,
  .gx-md-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-md-200,
  .gy-md-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-md-220,
  .gx-md-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-md-220,
  .gy-md-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-md-230,
  .gx-md-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-md-230,
  .gy-md-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-md-250,
  .gx-md-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-md-250,
  .gy-md-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-md-300,
  .gx-md-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-md-300,
  .gy-md-300 {
    --bs-gutter-y: 18.75rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-50,
  .gx-lg-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-50,
  .gy-lg-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-lg-60,
  .gx-lg-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-60,
  .gy-lg-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-70,
  .gx-lg-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-lg-70,
  .gy-lg-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-lg-80,
  .gx-lg-80 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-80,
  .gy-lg-80 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-90,
  .gx-lg-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-lg-90,
  .gy-lg-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-lg-100,
  .gx-lg-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-lg-100,
  .gy-lg-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-lg-120,
  .gx-lg-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-120,
  .gy-lg-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-130,
  .gx-lg-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-lg-130,
  .gy-lg-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-lg-140,
  .gx-lg-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-lg-140,
  .gy-lg-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-lg-150,
  .gx-lg-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-lg-150,
  .gy-lg-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-lg-180,
  .gx-lg-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-lg-180,
  .gy-lg-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-lg-200,
  .gx-lg-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-lg-200,
  .gy-lg-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-lg-220,
  .gx-lg-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-lg-220,
  .gy-lg-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-lg-230,
  .gx-lg-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-lg-230,
  .gy-lg-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-lg-250,
  .gx-lg-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-lg-250,
  .gy-lg-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-lg-300,
  .gx-lg-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-lg-300,
  .gy-lg-300 {
    --bs-gutter-y: 18.75rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-30,
  .gx-xl-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-30,
  .gy-xl-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-40,
  .gx-xl-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-40,
  .gy-xl-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-50,
  .gx-xl-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-50,
  .gy-xl-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xl-60,
  .gx-xl-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-60,
  .gy-xl-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-70,
  .gx-xl-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xl-70,
  .gy-xl-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xl-80,
  .gx-xl-80 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-80,
  .gy-xl-80 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-90,
  .gx-xl-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xl-90,
  .gy-xl-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xl-100,
  .gx-xl-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xl-100,
  .gy-xl-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xl-120,
  .gx-xl-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xl-120,
  .gy-xl-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xl-130,
  .gx-xl-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-xl-130,
  .gy-xl-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-xl-140,
  .gx-xl-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xl-140,
  .gy-xl-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xl-150,
  .gx-xl-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xl-150,
  .gy-xl-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xl-180,
  .gx-xl-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-xl-180,
  .gy-xl-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-xl-200,
  .gx-xl-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xl-200,
  .gy-xl-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-xl-220,
  .gx-xl-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-xl-220,
  .gy-xl-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-xl-230,
  .gx-xl-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-xl-230,
  .gy-xl-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-xl-250,
  .gx-xl-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-xl-250,
  .gy-xl-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-xl-300,
  .gx-xl-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-xl-300,
  .gy-xl-300 {
    --bs-gutter-y: 18.75rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-30,
  .gx-xxl-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-30,
  .gy-xxl-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-40,
  .gx-xxl-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-40,
  .gy-xxl-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-50,
  .gx-xxl-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-50,
  .gy-xxl-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xxl-60,
  .gx-xxl-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-60,
  .gy-xxl-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-70,
  .gx-xxl-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xxl-70,
  .gy-xxl-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xxl-80,
  .gx-xxl-80 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-80,
  .gy-xxl-80 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-90,
  .gx-xxl-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xxl-90,
  .gy-xxl-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xxl-100,
  .gx-xxl-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xxl-100,
  .gy-xxl-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xxl-120,
  .gx-xxl-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxl-120,
  .gy-xxl-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxl-130,
  .gx-xxl-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-xxl-130,
  .gy-xxl-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-xxl-140,
  .gx-xxl-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xxl-140,
  .gy-xxl-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xxl-150,
  .gx-xxl-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xxl-150,
  .gy-xxl-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xxl-180,
  .gx-xxl-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-xxl-180,
  .gy-xxl-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-xxl-200,
  .gx-xxl-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xxl-200,
  .gy-xxl-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-xxl-220,
  .gx-xxl-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-xxl-220,
  .gy-xxl-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-xxl-230,
  .gx-xxl-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-xxl-230,
  .gy-xxl-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-xxl-250,
  .gx-xxl-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-xxl-250,
  .gy-xxl-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-xxl-300,
  .gx-xxl-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-xxl-300,
  .gy-xxl-300 {
    --bs-gutter-y: 18.75rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #04000A;
  --bs-table-border-color: #1d1a23;
  --bs-table-striped-bg: #110d16;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1d1a23;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #17131c;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: linear-gradient(276deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #04000A;
  --bs-btn-border-color: #04000A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2a262f;
  --bs-btn-hover-border-color: #1d1a23;
  --bs-btn-focus-shadow-rgb: 42, 38, 47;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #36333b;
  --bs-btn-active-border-color: #1d1a23;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #04000A;
  --bs-btn-disabled-border-color: #04000A;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #04000A;
  --bs-btn-border-color: #04000A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #04000A;
  --bs-btn-hover-border-color: #04000A;
  --bs-btn-focus-shadow-rgb: 4, 0, 10;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #04000A;
  --bs-btn-active-border-color: #04000A;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #04000A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #04000A;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}

.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%284, 0, 10, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2304000A'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(3, 0, 8, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(3, 0, 8, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}

.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-10 {
  margin: 0.625rem !important;
}

.m-15 {
  margin: 0.9375rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.m-30 {
  margin: 1.875rem !important;
}

.m-40 {
  margin: 2.5rem !important;
}

.m-50 {
  margin: 3.125rem !important;
}

.m-60 {
  margin: 3.75rem !important;
}

.m-70 {
  margin: 4.375rem !important;
}

.m-80 {
  margin: 5rem !important;
}

.m-90 {
  margin: 5.625rem !important;
}

.m-100 {
  margin: 6.25rem !important;
}

.m-120 {
  margin: 7.5rem !important;
}

.m-130 {
  margin: 8.125rem !important;
}

.m-140 {
  margin: 8.75rem !important;
}

.m-150 {
  margin: 9.375rem !important;
}

.m-180 {
  margin: 11.25rem !important;
}

.m-200 {
  margin: 12.5rem !important;
}

.m-220 {
  margin: 13.75rem !important;
}

.m-230 {
  margin: 14.375rem !important;
}

.m-250 {
  margin: 15.625rem !important;
}

.m-300 {
  margin: 18.75rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-10 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-15 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important;
}

.mx-20 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-30 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-40 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-50 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-60 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-70 {
  margin-right: 4.375rem !important;
  margin-left: 4.375rem !important;
}

.mx-80 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-90 {
  margin-right: 5.625rem !important;
  margin-left: 5.625rem !important;
}

.mx-100 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important;
}

.mx-120 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-130 {
  margin-right: 8.125rem !important;
  margin-left: 8.125rem !important;
}

.mx-140 {
  margin-right: 8.75rem !important;
  margin-left: 8.75rem !important;
}

.mx-150 {
  margin-right: 9.375rem !important;
  margin-left: 9.375rem !important;
}

.mx-180 {
  margin-right: 11.25rem !important;
  margin-left: 11.25rem !important;
}

.mx-200 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important;
}

.mx-220 {
  margin-right: 13.75rem !important;
  margin-left: 13.75rem !important;
}

.mx-230 {
  margin-right: 14.375rem !important;
  margin-left: 14.375rem !important;
}

.mx-250 {
  margin-right: 15.625rem !important;
  margin-left: 15.625rem !important;
}

.mx-300 {
  margin-right: 18.75rem !important;
  margin-left: 18.75rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-10 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-15 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-30 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-50 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-60 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-70 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.my-80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-90 {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important;
}

.my-100 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.my-120 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-130 {
  margin-top: 8.125rem !important;
  margin-bottom: 8.125rem !important;
}

.my-140 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important;
}

.my-150 {
  margin-top: 9.375rem !important;
  margin-bottom: 9.375rem !important;
}

.my-180 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important;
}

.my-200 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.my-220 {
  margin-top: 13.75rem !important;
  margin-bottom: 13.75rem !important;
}

.my-230 {
  margin-top: 14.375rem !important;
  margin-bottom: 14.375rem !important;
}

.my-250 {
  margin-top: 15.625rem !important;
  margin-bottom: 15.625rem !important;
}

.my-300 {
  margin-top: 18.75rem !important;
  margin-bottom: 18.75rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mt-15 {
  margin-top: 0.9375rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mt-60 {
  margin-top: 3.75rem !important;
}

.mt-70 {
  margin-top: 4.375rem !important;
}

.mt-80 {
  margin-top: 5rem !important;
}

.mt-90 {
  margin-top: 5.625rem !important;
}

.mt-100 {
  margin-top: 6.25rem !important;
}

.mt-120 {
  margin-top: 7.5rem !important;
}

.mt-130 {
  margin-top: 8.125rem !important;
}

.mt-140 {
  margin-top: 8.75rem !important;
}

.mt-150 {
  margin-top: 9.375rem !important;
}

.mt-180 {
  margin-top: 11.25rem !important;
}

.mt-200 {
  margin-top: 12.5rem !important;
}

.mt-220 {
  margin-top: 13.75rem !important;
}

.mt-230 {
  margin-top: 14.375rem !important;
}

.mt-250 {
  margin-top: 15.625rem !important;
}

.mt-300 {
  margin-top: 18.75rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-10 {
  margin-right: 0.625rem !important;
}

.me-15 {
  margin-right: 0.9375rem !important;
}

.me-20 {
  margin-right: 1.25rem !important;
}

.me-30 {
  margin-right: 1.875rem !important;
}

.me-40 {
  margin-right: 2.5rem !important;
}

.me-50 {
  margin-right: 3.125rem !important;
}

.me-60 {
  margin-right: 3.75rem !important;
}

.me-70 {
  margin-right: 4.375rem !important;
}

.me-80 {
  margin-right: 5rem !important;
}

.me-90 {
  margin-right: 5.625rem !important;
}

.me-100 {
  margin-right: 6.25rem !important;
}

.me-120 {
  margin-right: 7.5rem !important;
}

.me-130 {
  margin-right: 8.125rem !important;
}

.me-140 {
  margin-right: 8.75rem !important;
}

.me-150 {
  margin-right: 9.375rem !important;
}

.me-180 {
  margin-right: 11.25rem !important;
}

.me-200 {
  margin-right: 12.5rem !important;
}

.me-220 {
  margin-right: 13.75rem !important;
}

.me-230 {
  margin-right: 14.375rem !important;
}

.me-250 {
  margin-right: 15.625rem !important;
}

.me-300 {
  margin-right: 18.75rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.mb-15 {
  margin-bottom: 0.9375rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.mb-60 {
  margin-bottom: 3.75rem !important;
}

.mb-70 {
  margin-bottom: 4.375rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.mb-90 {
  margin-bottom: 5.625rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.mb-120 {
  margin-bottom: 7.5rem !important;
}

.mb-130 {
  margin-bottom: 8.125rem !important;
}

.mb-140 {
  margin-bottom: 8.75rem !important;
}

.mb-150 {
  margin-bottom: 9.375rem !important;
}

.mb-180 {
  margin-bottom: 11.25rem !important;
}

.mb-200 {
  margin-bottom: 12.5rem !important;
}

.mb-220 {
  margin-bottom: 13.75rem !important;
}

.mb-230 {
  margin-bottom: 14.375rem !important;
}

.mb-250 {
  margin-bottom: 15.625rem !important;
}

.mb-300 {
  margin-bottom: 18.75rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-10 {
  margin-left: 0.625rem !important;
}

.ms-15 {
  margin-left: 0.9375rem !important;
}

.ms-20 {
  margin-left: 1.25rem !important;
}

.ms-30 {
  margin-left: 1.875rem !important;
}

.ms-40 {
  margin-left: 2.5rem !important;
}

.ms-50 {
  margin-left: 3.125rem !important;
}

.ms-60 {
  margin-left: 3.75rem !important;
}

.ms-70 {
  margin-left: 4.375rem !important;
}

.ms-80 {
  margin-left: 5rem !important;
}

.ms-90 {
  margin-left: 5.625rem !important;
}

.ms-100 {
  margin-left: 6.25rem !important;
}

.ms-120 {
  margin-left: 7.5rem !important;
}

.ms-130 {
  margin-left: 8.125rem !important;
}

.ms-140 {
  margin-left: 8.75rem !important;
}

.ms-150 {
  margin-left: 9.375rem !important;
}

.ms-180 {
  margin-left: 11.25rem !important;
}

.ms-200 {
  margin-left: 12.5rem !important;
}

.ms-220 {
  margin-left: 13.75rem !important;
}

.ms-230 {
  margin-left: 14.375rem !important;
}

.ms-250 {
  margin-left: 15.625rem !important;
}

.ms-300 {
  margin-left: 18.75rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n10 {
  margin: -0.625rem !important;
}

.m-n15 {
  margin: -0.9375rem !important;
}

.m-n20 {
  margin: -1.25rem !important;
}

.m-n30 {
  margin: -1.875rem !important;
}

.m-n40 {
  margin: -2.5rem !important;
}

.m-n50 {
  margin: -3.125rem !important;
}

.m-n60 {
  margin: -3.75rem !important;
}

.m-n70 {
  margin: -4.375rem !important;
}

.m-n80 {
  margin: -5rem !important;
}

.m-n90 {
  margin: -5.625rem !important;
}

.m-n100 {
  margin: -6.25rem !important;
}

.m-n120 {
  margin: -7.5rem !important;
}

.m-n130 {
  margin: -8.125rem !important;
}

.m-n140 {
  margin: -8.75rem !important;
}

.m-n150 {
  margin: -9.375rem !important;
}

.m-n180 {
  margin: -11.25rem !important;
}

.m-n200 {
  margin: -12.5rem !important;
}

.m-n220 {
  margin: -13.75rem !important;
}

.m-n230 {
  margin: -14.375rem !important;
}

.m-n250 {
  margin: -15.625rem !important;
}

.m-n300 {
  margin: -18.75rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n10 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n15 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

.mx-n20 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n30 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n40 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n50 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.mx-n60 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n70 {
  margin-right: -4.375rem !important;
  margin-left: -4.375rem !important;
}

.mx-n80 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n90 {
  margin-right: -5.625rem !important;
  margin-left: -5.625rem !important;
}

.mx-n100 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important;
}

.mx-n120 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.mx-n130 {
  margin-right: -8.125rem !important;
  margin-left: -8.125rem !important;
}

.mx-n140 {
  margin-right: -8.75rem !important;
  margin-left: -8.75rem !important;
}

.mx-n150 {
  margin-right: -9.375rem !important;
  margin-left: -9.375rem !important;
}

.mx-n180 {
  margin-right: -11.25rem !important;
  margin-left: -11.25rem !important;
}

.mx-n200 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important;
}

.mx-n220 {
  margin-right: -13.75rem !important;
  margin-left: -13.75rem !important;
}

.mx-n230 {
  margin-right: -14.375rem !important;
  margin-left: -14.375rem !important;
}

.mx-n250 {
  margin-right: -15.625rem !important;
  margin-left: -15.625rem !important;
}

.mx-n300 {
  margin-right: -18.75rem !important;
  margin-left: -18.75rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n10 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n15 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important;
}

.my-n20 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n30 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n40 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n50 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.my-n60 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n70 {
  margin-top: -4.375rem !important;
  margin-bottom: -4.375rem !important;
}

.my-n80 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n90 {
  margin-top: -5.625rem !important;
  margin-bottom: -5.625rem !important;
}

.my-n100 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important;
}

.my-n120 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.my-n130 {
  margin-top: -8.125rem !important;
  margin-bottom: -8.125rem !important;
}

.my-n140 {
  margin-top: -8.75rem !important;
  margin-bottom: -8.75rem !important;
}

.my-n150 {
  margin-top: -9.375rem !important;
  margin-bottom: -9.375rem !important;
}

.my-n180 {
  margin-top: -11.25rem !important;
  margin-bottom: -11.25rem !important;
}

.my-n200 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important;
}

.my-n220 {
  margin-top: -13.75rem !important;
  margin-bottom: -13.75rem !important;
}

.my-n230 {
  margin-top: -14.375rem !important;
  margin-bottom: -14.375rem !important;
}

.my-n250 {
  margin-top: -15.625rem !important;
  margin-bottom: -15.625rem !important;
}

.my-n300 {
  margin-top: -18.75rem !important;
  margin-bottom: -18.75rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n10 {
  margin-top: -0.625rem !important;
}

.mt-n15 {
  margin-top: -0.9375rem !important;
}

.mt-n20 {
  margin-top: -1.25rem !important;
}

.mt-n30 {
  margin-top: -1.875rem !important;
}

.mt-n40 {
  margin-top: -2.5rem !important;
}

.mt-n50 {
  margin-top: -3.125rem !important;
}

.mt-n60 {
  margin-top: -3.75rem !important;
}

.mt-n70 {
  margin-top: -4.375rem !important;
}

.mt-n80 {
  margin-top: -5rem !important;
}

.mt-n90 {
  margin-top: -5.625rem !important;
}

.mt-n100 {
  margin-top: -6.25rem !important;
}

.mt-n120 {
  margin-top: -7.5rem !important;
}

.mt-n130 {
  margin-top: -8.125rem !important;
}

.mt-n140 {
  margin-top: -8.75rem !important;
}

.mt-n150 {
  margin-top: -9.375rem !important;
}

.mt-n180 {
  margin-top: -11.25rem !important;
}

.mt-n200 {
  margin-top: -12.5rem !important;
}

.mt-n220 {
  margin-top: -13.75rem !important;
}

.mt-n230 {
  margin-top: -14.375rem !important;
}

.mt-n250 {
  margin-top: -15.625rem !important;
}

.mt-n300 {
  margin-top: -18.75rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n10 {
  margin-right: -0.625rem !important;
}

.me-n15 {
  margin-right: -0.9375rem !important;
}

.me-n20 {
  margin-right: -1.25rem !important;
}

.me-n30 {
  margin-right: -1.875rem !important;
}

.me-n40 {
  margin-right: -2.5rem !important;
}

.me-n50 {
  margin-right: -3.125rem !important;
}

.me-n60 {
  margin-right: -3.75rem !important;
}

.me-n70 {
  margin-right: -4.375rem !important;
}

.me-n80 {
  margin-right: -5rem !important;
}

.me-n90 {
  margin-right: -5.625rem !important;
}

.me-n100 {
  margin-right: -6.25rem !important;
}

.me-n120 {
  margin-right: -7.5rem !important;
}

.me-n130 {
  margin-right: -8.125rem !important;
}

.me-n140 {
  margin-right: -8.75rem !important;
}

.me-n150 {
  margin-right: -9.375rem !important;
}

.me-n180 {
  margin-right: -11.25rem !important;
}

.me-n200 {
  margin-right: -12.5rem !important;
}

.me-n220 {
  margin-right: -13.75rem !important;
}

.me-n230 {
  margin-right: -14.375rem !important;
}

.me-n250 {
  margin-right: -15.625rem !important;
}

.me-n300 {
  margin-right: -18.75rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n10 {
  margin-bottom: -0.625rem !important;
}

.mb-n15 {
  margin-bottom: -0.9375rem !important;
}

.mb-n20 {
  margin-bottom: -1.25rem !important;
}

.mb-n30 {
  margin-bottom: -1.875rem !important;
}

.mb-n40 {
  margin-bottom: -2.5rem !important;
}

.mb-n50 {
  margin-bottom: -3.125rem !important;
}

.mb-n60 {
  margin-bottom: -3.75rem !important;
}

.mb-n70 {
  margin-bottom: -4.375rem !important;
}

.mb-n80 {
  margin-bottom: -5rem !important;
}

.mb-n90 {
  margin-bottom: -5.625rem !important;
}

.mb-n100 {
  margin-bottom: -6.25rem !important;
}

.mb-n120 {
  margin-bottom: -7.5rem !important;
}

.mb-n130 {
  margin-bottom: -8.125rem !important;
}

.mb-n140 {
  margin-bottom: -8.75rem !important;
}

.mb-n150 {
  margin-bottom: -9.375rem !important;
}

.mb-n180 {
  margin-bottom: -11.25rem !important;
}

.mb-n200 {
  margin-bottom: -12.5rem !important;
}

.mb-n220 {
  margin-bottom: -13.75rem !important;
}

.mb-n230 {
  margin-bottom: -14.375rem !important;
}

.mb-n250 {
  margin-bottom: -15.625rem !important;
}

.mb-n300 {
  margin-bottom: -18.75rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n10 {
  margin-left: -0.625rem !important;
}

.ms-n15 {
  margin-left: -0.9375rem !important;
}

.ms-n20 {
  margin-left: -1.25rem !important;
}

.ms-n30 {
  margin-left: -1.875rem !important;
}

.ms-n40 {
  margin-left: -2.5rem !important;
}

.ms-n50 {
  margin-left: -3.125rem !important;
}

.ms-n60 {
  margin-left: -3.75rem !important;
}

.ms-n70 {
  margin-left: -4.375rem !important;
}

.ms-n80 {
  margin-left: -5rem !important;
}

.ms-n90 {
  margin-left: -5.625rem !important;
}

.ms-n100 {
  margin-left: -6.25rem !important;
}

.ms-n120 {
  margin-left: -7.5rem !important;
}

.ms-n130 {
  margin-left: -8.125rem !important;
}

.ms-n140 {
  margin-left: -8.75rem !important;
}

.ms-n150 {
  margin-left: -9.375rem !important;
}

.ms-n180 {
  margin-left: -11.25rem !important;
}

.ms-n200 {
  margin-left: -12.5rem !important;
}

.ms-n220 {
  margin-left: -13.75rem !important;
}

.ms-n230 {
  margin-left: -14.375rem !important;
}

.ms-n250 {
  margin-left: -15.625rem !important;
}

.ms-n300 {
  margin-left: -18.75rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-10 {
  padding: 0.625rem !important;
}

.p-15 {
  padding: 0.9375rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.p-30 {
  padding: 1.875rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}

.p-50 {
  padding: 3.125rem !important;
}

.p-60 {
  padding: 3.75rem !important;
}

.p-70 {
  padding: 4.375rem !important;
}

.p-80 {
  padding: 5rem !important;
}

.p-90 {
  padding: 5.625rem !important;
}

.p-100 {
  padding: 6.25rem !important;
}

.p-120 {
  padding: 7.5rem !important;
}

.p-130 {
  padding: 8.125rem !important;
}

.p-140 {
  padding: 8.75rem !important;
}

.p-150 {
  padding: 9.375rem !important;
}

.p-180 {
  padding: 11.25rem !important;
}

.p-200 {
  padding: 12.5rem !important;
}

.p-220 {
  padding: 13.75rem !important;
}

.p-230 {
  padding: 14.375rem !important;
}

.p-250 {
  padding: 15.625rem !important;
}

.p-300 {
  padding: 18.75rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-10 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-15 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important;
}

.px-20 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-30 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-40 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-50 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.px-60 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-70 {
  padding-right: 4.375rem !important;
  padding-left: 4.375rem !important;
}

.px-80 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-90 {
  padding-right: 5.625rem !important;
  padding-left: 5.625rem !important;
}

.px-100 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important;
}

.px-120 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-130 {
  padding-right: 8.125rem !important;
  padding-left: 8.125rem !important;
}

.px-140 {
  padding-right: 8.75rem !important;
  padding-left: 8.75rem !important;
}

.px-150 {
  padding-right: 9.375rem !important;
  padding-left: 9.375rem !important;
}

.px-180 {
  padding-right: 11.25rem !important;
  padding-left: 11.25rem !important;
}

.px-200 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important;
}

.px-220 {
  padding-right: 13.75rem !important;
  padding-left: 13.75rem !important;
}

.px-230 {
  padding-right: 14.375rem !important;
  padding-left: 14.375rem !important;
}

.px-250 {
  padding-right: 15.625rem !important;
  padding-left: 15.625rem !important;
}

.px-300 {
  padding-right: 18.75rem !important;
  padding-left: 18.75rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-15 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.py-120 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-130 {
  padding-top: 8.125rem !important;
  padding-bottom: 8.125rem !important;
}

.py-140 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important;
}

.py-150 {
  padding-top: 9.375rem !important;
  padding-bottom: 9.375rem !important;
}

.py-180 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important;
}

.py-200 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.py-220 {
  padding-top: 13.75rem !important;
  padding-bottom: 13.75rem !important;
}

.py-230 {
  padding-top: 14.375rem !important;
  padding-bottom: 14.375rem !important;
}

.py-250 {
  padding-top: 15.625rem !important;
  padding-bottom: 15.625rem !important;
}

.py-300 {
  padding-top: 18.75rem !important;
  padding-bottom: 18.75rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-10 {
  padding-top: 0.625rem !important;
}

.pt-15 {
  padding-top: 0.9375rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.pt-30 {
  padding-top: 1.875rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.pt-50 {
  padding-top: 3.125rem !important;
}

.pt-60 {
  padding-top: 3.75rem !important;
}

.pt-70 {
  padding-top: 4.375rem !important;
}

.pt-80 {
  padding-top: 5rem !important;
}

.pt-90 {
  padding-top: 5.625rem !important;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.pt-120 {
  padding-top: 7.5rem !important;
}

.pt-130 {
  padding-top: 8.125rem !important;
}

.pt-140 {
  padding-top: 8.75rem !important;
}

.pt-150 {
  padding-top: 9.375rem !important;
}

.pt-180 {
  padding-top: 11.25rem !important;
}

.pt-200 {
  padding-top: 12.5rem !important;
}

.pt-220 {
  padding-top: 13.75rem !important;
}

.pt-230 {
  padding-top: 14.375rem !important;
}

.pt-250 {
  padding-top: 15.625rem !important;
}

.pt-300 {
  padding-top: 18.75rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-10 {
  padding-right: 0.625rem !important;
}

.pe-15 {
  padding-right: 0.9375rem !important;
}

.pe-20 {
  padding-right: 1.25rem !important;
}

.pe-30 {
  padding-right: 1.875rem !important;
}

.pe-40 {
  padding-right: 2.5rem !important;
}

.pe-50 {
  padding-right: 3.125rem !important;
}

.pe-60 {
  padding-right: 3.75rem !important;
}

.pe-70 {
  padding-right: 4.375rem !important;
}

.pe-80 {
  padding-right: 5rem !important;
}

.pe-90 {
  padding-right: 5.625rem !important;
}

.pe-100 {
  padding-right: 6.25rem !important;
}

.pe-120 {
  padding-right: 7.5rem !important;
}

.pe-130 {
  padding-right: 8.125rem !important;
}

.pe-140 {
  padding-right: 8.75rem !important;
}

.pe-150 {
  padding-right: 9.375rem !important;
}

.pe-180 {
  padding-right: 11.25rem !important;
}

.pe-200 {
  padding-right: 12.5rem !important;
}

.pe-220 {
  padding-right: 13.75rem !important;
}

.pe-230 {
  padding-right: 14.375rem !important;
}

.pe-250 {
  padding-right: 15.625rem !important;
}

.pe-300 {
  padding-right: 18.75rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-10 {
  padding-bottom: 0.625rem !important;
}

.pb-15 {
  padding-bottom: 0.9375rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.pb-30 {
  padding-bottom: 1.875rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.pb-50 {
  padding-bottom: 3.125rem !important;
}

.pb-60 {
  padding-bottom: 3.75rem !important;
}

.pb-70 {
  padding-bottom: 4.375rem !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
}

.pb-90 {
  padding-bottom: 5.625rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.pb-120 {
  padding-bottom: 7.5rem !important;
}

.pb-130 {
  padding-bottom: 8.125rem !important;
}

.pb-140 {
  padding-bottom: 8.75rem !important;
}

.pb-150 {
  padding-bottom: 9.375rem !important;
}

.pb-180 {
  padding-bottom: 11.25rem !important;
}

.pb-200 {
  padding-bottom: 12.5rem !important;
}

.pb-220 {
  padding-bottom: 13.75rem !important;
}

.pb-230 {
  padding-bottom: 14.375rem !important;
}

.pb-250 {
  padding-bottom: 15.625rem !important;
}

.pb-300 {
  padding-bottom: 18.75rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-10 {
  padding-left: 0.625rem !important;
}

.ps-15 {
  padding-left: 0.9375rem !important;
}

.ps-20 {
  padding-left: 1.25rem !important;
}

.ps-30 {
  padding-left: 1.875rem !important;
}

.ps-40 {
  padding-left: 2.5rem !important;
}

.ps-50 {
  padding-left: 3.125rem !important;
}

.ps-60 {
  padding-left: 3.75rem !important;
}

.ps-70 {
  padding-left: 4.375rem !important;
}

.ps-80 {
  padding-left: 5rem !important;
}

.ps-90 {
  padding-left: 5.625rem !important;
}

.ps-100 {
  padding-left: 6.25rem !important;
}

.ps-120 {
  padding-left: 7.5rem !important;
}

.ps-130 {
  padding-left: 8.125rem !important;
}

.ps-140 {
  padding-left: 8.75rem !important;
}

.ps-150 {
  padding-left: 9.375rem !important;
}

.ps-180 {
  padding-left: 11.25rem !important;
}

.ps-200 {
  padding-left: 12.5rem !important;
}

.ps-220 {
  padding-left: 13.75rem !important;
}

.ps-230 {
  padding-left: 14.375rem !important;
}

.ps-250 {
  padding-left: 15.625rem !important;
}

.ps-300 {
  padding-left: 18.75rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-10 {
  gap: 0.625rem !important;
}

.gap-15 {
  gap: 0.9375rem !important;
}

.gap-20 {
  gap: 1.25rem !important;
}

.gap-30 {
  gap: 1.875rem !important;
}

.gap-40 {
  gap: 2.5rem !important;
}

.gap-50 {
  gap: 3.125rem !important;
}

.gap-60 {
  gap: 3.75rem !important;
}

.gap-70 {
  gap: 4.375rem !important;
}

.gap-80 {
  gap: 5rem !important;
}

.gap-90 {
  gap: 5.625rem !important;
}

.gap-100 {
  gap: 6.25rem !important;
}

.gap-120 {
  gap: 7.5rem !important;
}

.gap-130 {
  gap: 8.125rem !important;
}

.gap-140 {
  gap: 8.75rem !important;
}

.gap-150 {
  gap: 9.375rem !important;
}

.gap-180 {
  gap: 11.25rem !important;
}

.gap-200 {
  gap: 12.5rem !important;
}

.gap-220 {
  gap: 13.75rem !important;
}

.gap-230 {
  gap: 14.375rem !important;
}

.gap-250 {
  gap: 15.625rem !important;
}

.gap-300 {
  gap: 18.75rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 2rem !important;
}

.row-gap-10 {
  row-gap: 0.625rem !important;
}

.row-gap-15 {
  row-gap: 0.9375rem !important;
}

.row-gap-20 {
  row-gap: 1.25rem !important;
}

.row-gap-30 {
  row-gap: 1.875rem !important;
}

.row-gap-40 {
  row-gap: 2.5rem !important;
}

.row-gap-50 {
  row-gap: 3.125rem !important;
}

.row-gap-60 {
  row-gap: 3.75rem !important;
}

.row-gap-70 {
  row-gap: 4.375rem !important;
}

.row-gap-80 {
  row-gap: 5rem !important;
}

.row-gap-90 {
  row-gap: 5.625rem !important;
}

.row-gap-100 {
  row-gap: 6.25rem !important;
}

.row-gap-120 {
  row-gap: 7.5rem !important;
}

.row-gap-130 {
  row-gap: 8.125rem !important;
}

.row-gap-140 {
  row-gap: 8.75rem !important;
}

.row-gap-150 {
  row-gap: 9.375rem !important;
}

.row-gap-180 {
  row-gap: 11.25rem !important;
}

.row-gap-200 {
  row-gap: 12.5rem !important;
}

.row-gap-220 {
  row-gap: 13.75rem !important;
}

.row-gap-230 {
  row-gap: 14.375rem !important;
}

.row-gap-250 {
  row-gap: 15.625rem !important;
}

.row-gap-300 {
  row-gap: 18.75rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 2rem !important;
}

.column-gap-10 {
  column-gap: 0.625rem !important;
}

.column-gap-15 {
  column-gap: 0.9375rem !important;
}

.column-gap-20 {
  column-gap: 1.25rem !important;
}

.column-gap-30 {
  column-gap: 1.875rem !important;
}

.column-gap-40 {
  column-gap: 2.5rem !important;
}

.column-gap-50 {
  column-gap: 3.125rem !important;
}

.column-gap-60 {
  column-gap: 3.75rem !important;
}

.column-gap-70 {
  column-gap: 4.375rem !important;
}

.column-gap-80 {
  column-gap: 5rem !important;
}

.column-gap-90 {
  column-gap: 5.625rem !important;
}

.column-gap-100 {
  column-gap: 6.25rem !important;
}

.column-gap-120 {
  column-gap: 7.5rem !important;
}

.column-gap-130 {
  column-gap: 8.125rem !important;
}

.column-gap-140 {
  column-gap: 8.75rem !important;
}

.column-gap-150 {
  column-gap: 9.375rem !important;
}

.column-gap-180 {
  column-gap: 11.25rem !important;
}

.column-gap-200 {
  column-gap: 12.5rem !important;
}

.column-gap-220 {
  column-gap: 13.75rem !important;
}

.column-gap-230 {
  column-gap: 14.375rem !important;
}

.column-gap-250 {
  column-gap: 15.625rem !important;
}

.column-gap-300 {
  column-gap: 18.75rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .m-sm-10 {
    margin: 0.625rem !important;
  }
  .m-sm-15 {
    margin: 0.9375rem !important;
  }
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .m-sm-30 {
    margin: 1.875rem !important;
  }
  .m-sm-40 {
    margin: 2.5rem !important;
  }
  .m-sm-50 {
    margin: 3.125rem !important;
  }
  .m-sm-60 {
    margin: 3.75rem !important;
  }
  .m-sm-70 {
    margin: 4.375rem !important;
  }
  .m-sm-80 {
    margin: 5rem !important;
  }
  .m-sm-90 {
    margin: 5.625rem !important;
  }
  .m-sm-100 {
    margin: 6.25rem !important;
  }
  .m-sm-120 {
    margin: 7.5rem !important;
  }
  .m-sm-130 {
    margin: 8.125rem !important;
  }
  .m-sm-140 {
    margin: 8.75rem !important;
  }
  .m-sm-150 {
    margin: 9.375rem !important;
  }
  .m-sm-180 {
    margin: 11.25rem !important;
  }
  .m-sm-200 {
    margin: 12.5rem !important;
  }
  .m-sm-220 {
    margin: 13.75rem !important;
  }
  .m-sm-230 {
    margin: 14.375rem !important;
  }
  .m-sm-250 {
    margin: 15.625rem !important;
  }
  .m-sm-300 {
    margin: 18.75rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-15 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-sm-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-sm-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-sm-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-sm-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-sm-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-sm-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-sm-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-sm-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-sm-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-sm-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-sm-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-15 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-sm-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-sm-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-sm-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-sm-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-sm-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-sm-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-sm-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-sm-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-sm-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-sm-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-sm-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-sm-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
  .mt-sm-10 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-15 {
    margin-top: 0.9375rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-30 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-40 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-50 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-60 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-70 {
    margin-top: 4.375rem !important;
  }
  .mt-sm-80 {
    margin-top: 5rem !important;
  }
  .mt-sm-90 {
    margin-top: 5.625rem !important;
  }
  .mt-sm-100 {
    margin-top: 6.25rem !important;
  }
  .mt-sm-120 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-130 {
    margin-top: 8.125rem !important;
  }
  .mt-sm-140 {
    margin-top: 8.75rem !important;
  }
  .mt-sm-150 {
    margin-top: 9.375rem !important;
  }
  .mt-sm-180 {
    margin-top: 11.25rem !important;
  }
  .mt-sm-200 {
    margin-top: 12.5rem !important;
  }
  .mt-sm-220 {
    margin-top: 13.75rem !important;
  }
  .mt-sm-230 {
    margin-top: 14.375rem !important;
  }
  .mt-sm-250 {
    margin-top: 15.625rem !important;
  }
  .mt-sm-300 {
    margin-top: 18.75rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2rem !important;
  }
  .me-sm-10 {
    margin-right: 0.625rem !important;
  }
  .me-sm-15 {
    margin-right: 0.9375rem !important;
  }
  .me-sm-20 {
    margin-right: 1.25rem !important;
  }
  .me-sm-30 {
    margin-right: 1.875rem !important;
  }
  .me-sm-40 {
    margin-right: 2.5rem !important;
  }
  .me-sm-50 {
    margin-right: 3.125rem !important;
  }
  .me-sm-60 {
    margin-right: 3.75rem !important;
  }
  .me-sm-70 {
    margin-right: 4.375rem !important;
  }
  .me-sm-80 {
    margin-right: 5rem !important;
  }
  .me-sm-90 {
    margin-right: 5.625rem !important;
  }
  .me-sm-100 {
    margin-right: 6.25rem !important;
  }
  .me-sm-120 {
    margin-right: 7.5rem !important;
  }
  .me-sm-130 {
    margin-right: 8.125rem !important;
  }
  .me-sm-140 {
    margin-right: 8.75rem !important;
  }
  .me-sm-150 {
    margin-right: 9.375rem !important;
  }
  .me-sm-180 {
    margin-right: 11.25rem !important;
  }
  .me-sm-200 {
    margin-right: 12.5rem !important;
  }
  .me-sm-220 {
    margin-right: 13.75rem !important;
  }
  .me-sm-230 {
    margin-right: 14.375rem !important;
  }
  .me-sm-250 {
    margin-right: 15.625rem !important;
  }
  .me-sm-300 {
    margin-right: 18.75rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-sm-80 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-sm-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-sm-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-sm-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-sm-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-sm-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-sm-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-sm-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-sm-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-sm-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-sm-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2rem !important;
  }
  .ms-sm-10 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-15 {
    margin-left: 0.9375rem !important;
  }
  .ms-sm-20 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-30 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-40 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-50 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-60 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-70 {
    margin-left: 4.375rem !important;
  }
  .ms-sm-80 {
    margin-left: 5rem !important;
  }
  .ms-sm-90 {
    margin-left: 5.625rem !important;
  }
  .ms-sm-100 {
    margin-left: 6.25rem !important;
  }
  .ms-sm-120 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-130 {
    margin-left: 8.125rem !important;
  }
  .ms-sm-140 {
    margin-left: 8.75rem !important;
  }
  .ms-sm-150 {
    margin-left: 9.375rem !important;
  }
  .ms-sm-180 {
    margin-left: 11.25rem !important;
  }
  .ms-sm-200 {
    margin-left: 12.5rem !important;
  }
  .ms-sm-220 {
    margin-left: 13.75rem !important;
  }
  .ms-sm-230 {
    margin-left: 14.375rem !important;
  }
  .ms-sm-250 {
    margin-left: 15.625rem !important;
  }
  .ms-sm-300 {
    margin-left: 18.75rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .m-sm-n10 {
    margin: -0.625rem !important;
  }
  .m-sm-n15 {
    margin: -0.9375rem !important;
  }
  .m-sm-n20 {
    margin: -1.25rem !important;
  }
  .m-sm-n30 {
    margin: -1.875rem !important;
  }
  .m-sm-n40 {
    margin: -2.5rem !important;
  }
  .m-sm-n50 {
    margin: -3.125rem !important;
  }
  .m-sm-n60 {
    margin: -3.75rem !important;
  }
  .m-sm-n70 {
    margin: -4.375rem !important;
  }
  .m-sm-n80 {
    margin: -5rem !important;
  }
  .m-sm-n90 {
    margin: -5.625rem !important;
  }
  .m-sm-n100 {
    margin: -6.25rem !important;
  }
  .m-sm-n120 {
    margin: -7.5rem !important;
  }
  .m-sm-n130 {
    margin: -8.125rem !important;
  }
  .m-sm-n140 {
    margin: -8.75rem !important;
  }
  .m-sm-n150 {
    margin: -9.375rem !important;
  }
  .m-sm-n180 {
    margin: -11.25rem !important;
  }
  .m-sm-n200 {
    margin: -12.5rem !important;
  }
  .m-sm-n220 {
    margin: -13.75rem !important;
  }
  .m-sm-n230 {
    margin: -14.375rem !important;
  }
  .m-sm-n250 {
    margin: -15.625rem !important;
  }
  .m-sm-n300 {
    margin: -18.75rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n15 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-sm-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-sm-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-sm-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-sm-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-sm-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-sm-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-sm-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-sm-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-sm-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-sm-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-sm-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-sm-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-sm-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-sm-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-sm-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n15 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-sm-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-sm-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-sm-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-sm-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-sm-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-sm-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-sm-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-sm-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-sm-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-sm-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-sm-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-sm-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-sm-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-sm-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-sm-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2rem !important;
  }
  .mt-sm-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n15 {
    margin-top: -0.9375rem !important;
  }
  .mt-sm-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-sm-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-sm-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-sm-n80 {
    margin-top: -5rem !important;
  }
  .mt-sm-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-sm-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-sm-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-sm-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-sm-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-sm-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-sm-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-sm-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-sm-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-sm-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-sm-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-sm-n300 {
    margin-top: -18.75rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2rem !important;
  }
  .me-sm-n10 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n15 {
    margin-right: -0.9375rem !important;
  }
  .me-sm-n20 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n30 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n40 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n50 {
    margin-right: -3.125rem !important;
  }
  .me-sm-n60 {
    margin-right: -3.75rem !important;
  }
  .me-sm-n70 {
    margin-right: -4.375rem !important;
  }
  .me-sm-n80 {
    margin-right: -5rem !important;
  }
  .me-sm-n90 {
    margin-right: -5.625rem !important;
  }
  .me-sm-n100 {
    margin-right: -6.25rem !important;
  }
  .me-sm-n120 {
    margin-right: -7.5rem !important;
  }
  .me-sm-n130 {
    margin-right: -8.125rem !important;
  }
  .me-sm-n140 {
    margin-right: -8.75rem !important;
  }
  .me-sm-n150 {
    margin-right: -9.375rem !important;
  }
  .me-sm-n180 {
    margin-right: -11.25rem !important;
  }
  .me-sm-n200 {
    margin-right: -12.5rem !important;
  }
  .me-sm-n220 {
    margin-right: -13.75rem !important;
  }
  .me-sm-n230 {
    margin-right: -14.375rem !important;
  }
  .me-sm-n250 {
    margin-right: -15.625rem !important;
  }
  .me-sm-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-sm-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-sm-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-sm-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-sm-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-sm-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-sm-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-sm-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-sm-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-sm-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-sm-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-sm-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-sm-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-sm-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-sm-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-sm-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2rem !important;
  }
  .ms-sm-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n15 {
    margin-left: -0.9375rem !important;
  }
  .ms-sm-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-sm-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-sm-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-sm-n80 {
    margin-left: -5rem !important;
  }
  .ms-sm-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-sm-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-sm-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-sm-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-sm-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-sm-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-sm-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-sm-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-sm-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-sm-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-sm-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-sm-n300 {
    margin-left: -18.75rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .p-sm-10 {
    padding: 0.625rem !important;
  }
  .p-sm-15 {
    padding: 0.9375rem !important;
  }
  .p-sm-20 {
    padding: 1.25rem !important;
  }
  .p-sm-30 {
    padding: 1.875rem !important;
  }
  .p-sm-40 {
    padding: 2.5rem !important;
  }
  .p-sm-50 {
    padding: 3.125rem !important;
  }
  .p-sm-60 {
    padding: 3.75rem !important;
  }
  .p-sm-70 {
    padding: 4.375rem !important;
  }
  .p-sm-80 {
    padding: 5rem !important;
  }
  .p-sm-90 {
    padding: 5.625rem !important;
  }
  .p-sm-100 {
    padding: 6.25rem !important;
  }
  .p-sm-120 {
    padding: 7.5rem !important;
  }
  .p-sm-130 {
    padding: 8.125rem !important;
  }
  .p-sm-140 {
    padding: 8.75rem !important;
  }
  .p-sm-150 {
    padding: 9.375rem !important;
  }
  .p-sm-180 {
    padding: 11.25rem !important;
  }
  .p-sm-200 {
    padding: 12.5rem !important;
  }
  .p-sm-220 {
    padding: 13.75rem !important;
  }
  .p-sm-230 {
    padding: 14.375rem !important;
  }
  .p-sm-250 {
    padding: 15.625rem !important;
  }
  .p-sm-300 {
    padding: 18.75rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-15 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-sm-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-sm-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-sm-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-sm-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-sm-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-sm-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-sm-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-sm-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-sm-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-sm-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-sm-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-sm-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-sm-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-sm-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-15 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-sm-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-sm-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-sm-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-sm-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-sm-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-sm-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-sm-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-sm-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-sm-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-sm-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-sm-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-sm-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-sm-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-sm-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
  .pt-sm-10 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-15 {
    padding-top: 0.9375rem !important;
  }
  .pt-sm-20 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-30 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-40 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-50 {
    padding-top: 3.125rem !important;
  }
  .pt-sm-60 {
    padding-top: 3.75rem !important;
  }
  .pt-sm-70 {
    padding-top: 4.375rem !important;
  }
  .pt-sm-80 {
    padding-top: 5rem !important;
  }
  .pt-sm-90 {
    padding-top: 5.625rem !important;
  }
  .pt-sm-100 {
    padding-top: 6.25rem !important;
  }
  .pt-sm-120 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-130 {
    padding-top: 8.125rem !important;
  }
  .pt-sm-140 {
    padding-top: 8.75rem !important;
  }
  .pt-sm-150 {
    padding-top: 9.375rem !important;
  }
  .pt-sm-180 {
    padding-top: 11.25rem !important;
  }
  .pt-sm-200 {
    padding-top: 12.5rem !important;
  }
  .pt-sm-220 {
    padding-top: 13.75rem !important;
  }
  .pt-sm-230 {
    padding-top: 14.375rem !important;
  }
  .pt-sm-250 {
    padding-top: 15.625rem !important;
  }
  .pt-sm-300 {
    padding-top: 18.75rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2rem !important;
  }
  .pe-sm-10 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-15 {
    padding-right: 0.9375rem !important;
  }
  .pe-sm-20 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-30 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-40 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-50 {
    padding-right: 3.125rem !important;
  }
  .pe-sm-60 {
    padding-right: 3.75rem !important;
  }
  .pe-sm-70 {
    padding-right: 4.375rem !important;
  }
  .pe-sm-80 {
    padding-right: 5rem !important;
  }
  .pe-sm-90 {
    padding-right: 5.625rem !important;
  }
  .pe-sm-100 {
    padding-right: 6.25rem !important;
  }
  .pe-sm-120 {
    padding-right: 7.5rem !important;
  }
  .pe-sm-130 {
    padding-right: 8.125rem !important;
  }
  .pe-sm-140 {
    padding-right: 8.75rem !important;
  }
  .pe-sm-150 {
    padding-right: 9.375rem !important;
  }
  .pe-sm-180 {
    padding-right: 11.25rem !important;
  }
  .pe-sm-200 {
    padding-right: 12.5rem !important;
  }
  .pe-sm-220 {
    padding-right: 13.75rem !important;
  }
  .pe-sm-230 {
    padding-right: 14.375rem !important;
  }
  .pe-sm-250 {
    padding-right: 15.625rem !important;
  }
  .pe-sm-300 {
    padding-right: 18.75rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-sm-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-sm-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-sm-80 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-sm-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-sm-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-sm-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-sm-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-sm-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-sm-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-sm-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-sm-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-sm-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-sm-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2rem !important;
  }
  .ps-sm-10 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-15 {
    padding-left: 0.9375rem !important;
  }
  .ps-sm-20 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-30 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-40 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-50 {
    padding-left: 3.125rem !important;
  }
  .ps-sm-60 {
    padding-left: 3.75rem !important;
  }
  .ps-sm-70 {
    padding-left: 4.375rem !important;
  }
  .ps-sm-80 {
    padding-left: 5rem !important;
  }
  .ps-sm-90 {
    padding-left: 5.625rem !important;
  }
  .ps-sm-100 {
    padding-left: 6.25rem !important;
  }
  .ps-sm-120 {
    padding-left: 7.5rem !important;
  }
  .ps-sm-130 {
    padding-left: 8.125rem !important;
  }
  .ps-sm-140 {
    padding-left: 8.75rem !important;
  }
  .ps-sm-150 {
    padding-left: 9.375rem !important;
  }
  .ps-sm-180 {
    padding-left: 11.25rem !important;
  }
  .ps-sm-200 {
    padding-left: 12.5rem !important;
  }
  .ps-sm-220 {
    padding-left: 13.75rem !important;
  }
  .ps-sm-230 {
    padding-left: 14.375rem !important;
  }
  .ps-sm-250 {
    padding-left: 15.625rem !important;
  }
  .ps-sm-300 {
    padding-left: 18.75rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2rem !important;
  }
  .gap-sm-10 {
    gap: 0.625rem !important;
  }
  .gap-sm-15 {
    gap: 0.9375rem !important;
  }
  .gap-sm-20 {
    gap: 1.25rem !important;
  }
  .gap-sm-30 {
    gap: 1.875rem !important;
  }
  .gap-sm-40 {
    gap: 2.5rem !important;
  }
  .gap-sm-50 {
    gap: 3.125rem !important;
  }
  .gap-sm-60 {
    gap: 3.75rem !important;
  }
  .gap-sm-70 {
    gap: 4.375rem !important;
  }
  .gap-sm-80 {
    gap: 5rem !important;
  }
  .gap-sm-90 {
    gap: 5.625rem !important;
  }
  .gap-sm-100 {
    gap: 6.25rem !important;
  }
  .gap-sm-120 {
    gap: 7.5rem !important;
  }
  .gap-sm-130 {
    gap: 8.125rem !important;
  }
  .gap-sm-140 {
    gap: 8.75rem !important;
  }
  .gap-sm-150 {
    gap: 9.375rem !important;
  }
  .gap-sm-180 {
    gap: 11.25rem !important;
  }
  .gap-sm-200 {
    gap: 12.5rem !important;
  }
  .gap-sm-220 {
    gap: 13.75rem !important;
  }
  .gap-sm-230 {
    gap: 14.375rem !important;
  }
  .gap-sm-250 {
    gap: 15.625rem !important;
  }
  .gap-sm-300 {
    gap: 18.75rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 0.9375rem !important;
  }
  .row-gap-sm-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-sm-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-sm-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-sm-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-sm-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-sm-80 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-sm-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-sm-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-sm-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-sm-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-sm-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-sm-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-sm-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-sm-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-sm-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-sm-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-sm-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 2rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-sm-15 {
    column-gap: 0.9375rem !important;
  }
  .column-gap-sm-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-sm-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-sm-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-sm-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-sm-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-sm-80 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-sm-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-sm-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-sm-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-sm-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-sm-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-sm-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-sm-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-sm-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-sm-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-sm-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-sm-300 {
    column-gap: 18.75rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .m-md-10 {
    margin: 0.625rem !important;
  }
  .m-md-15 {
    margin: 0.9375rem !important;
  }
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .m-md-30 {
    margin: 1.875rem !important;
  }
  .m-md-40 {
    margin: 2.5rem !important;
  }
  .m-md-50 {
    margin: 3.125rem !important;
  }
  .m-md-60 {
    margin: 3.75rem !important;
  }
  .m-md-70 {
    margin: 4.375rem !important;
  }
  .m-md-80 {
    margin: 5rem !important;
  }
  .m-md-90 {
    margin: 5.625rem !important;
  }
  .m-md-100 {
    margin: 6.25rem !important;
  }
  .m-md-120 {
    margin: 7.5rem !important;
  }
  .m-md-130 {
    margin: 8.125rem !important;
  }
  .m-md-140 {
    margin: 8.75rem !important;
  }
  .m-md-150 {
    margin: 9.375rem !important;
  }
  .m-md-180 {
    margin: 11.25rem !important;
  }
  .m-md-200 {
    margin: 12.5rem !important;
  }
  .m-md-220 {
    margin: 13.75rem !important;
  }
  .m-md-230 {
    margin: 14.375rem !important;
  }
  .m-md-250 {
    margin: 15.625rem !important;
  }
  .m-md-300 {
    margin: 18.75rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-15 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-md-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-md-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-md-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-md-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-md-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-md-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-md-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-md-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-md-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-md-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-md-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-15 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-md-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-md-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-md-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-md-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-md-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-md-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-md-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-md-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-md-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-md-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-md-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-md-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .mt-md-10 {
    margin-top: 0.625rem !important;
  }
  .mt-md-15 {
    margin-top: 0.9375rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mt-md-30 {
    margin-top: 1.875rem !important;
  }
  .mt-md-40 {
    margin-top: 2.5rem !important;
  }
  .mt-md-50 {
    margin-top: 3.125rem !important;
  }
  .mt-md-60 {
    margin-top: 3.75rem !important;
  }
  .mt-md-70 {
    margin-top: 4.375rem !important;
  }
  .mt-md-80 {
    margin-top: 5rem !important;
  }
  .mt-md-90 {
    margin-top: 5.625rem !important;
  }
  .mt-md-100 {
    margin-top: 6.25rem !important;
  }
  .mt-md-120 {
    margin-top: 7.5rem !important;
  }
  .mt-md-130 {
    margin-top: 8.125rem !important;
  }
  .mt-md-140 {
    margin-top: 8.75rem !important;
  }
  .mt-md-150 {
    margin-top: 9.375rem !important;
  }
  .mt-md-180 {
    margin-top: 11.25rem !important;
  }
  .mt-md-200 {
    margin-top: 12.5rem !important;
  }
  .mt-md-220 {
    margin-top: 13.75rem !important;
  }
  .mt-md-230 {
    margin-top: 14.375rem !important;
  }
  .mt-md-250 {
    margin-top: 15.625rem !important;
  }
  .mt-md-300 {
    margin-top: 18.75rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2rem !important;
  }
  .me-md-10 {
    margin-right: 0.625rem !important;
  }
  .me-md-15 {
    margin-right: 0.9375rem !important;
  }
  .me-md-20 {
    margin-right: 1.25rem !important;
  }
  .me-md-30 {
    margin-right: 1.875rem !important;
  }
  .me-md-40 {
    margin-right: 2.5rem !important;
  }
  .me-md-50 {
    margin-right: 3.125rem !important;
  }
  .me-md-60 {
    margin-right: 3.75rem !important;
  }
  .me-md-70 {
    margin-right: 4.375rem !important;
  }
  .me-md-80 {
    margin-right: 5rem !important;
  }
  .me-md-90 {
    margin-right: 5.625rem !important;
  }
  .me-md-100 {
    margin-right: 6.25rem !important;
  }
  .me-md-120 {
    margin-right: 7.5rem !important;
  }
  .me-md-130 {
    margin-right: 8.125rem !important;
  }
  .me-md-140 {
    margin-right: 8.75rem !important;
  }
  .me-md-150 {
    margin-right: 9.375rem !important;
  }
  .me-md-180 {
    margin-right: 11.25rem !important;
  }
  .me-md-200 {
    margin-right: 12.5rem !important;
  }
  .me-md-220 {
    margin-right: 13.75rem !important;
  }
  .me-md-230 {
    margin-right: 14.375rem !important;
  }
  .me-md-250 {
    margin-right: 15.625rem !important;
  }
  .me-md-300 {
    margin-right: 18.75rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mb-md-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-15 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-md-80 {
    margin-bottom: 5rem !important;
  }
  .mb-md-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-md-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-md-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-md-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-md-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-md-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-md-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-md-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-md-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-md-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-md-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-10 {
    margin-left: 0.625rem !important;
  }
  .ms-md-15 {
    margin-left: 0.9375rem !important;
  }
  .ms-md-20 {
    margin-left: 1.25rem !important;
  }
  .ms-md-30 {
    margin-left: 1.875rem !important;
  }
  .ms-md-40 {
    margin-left: 2.5rem !important;
  }
  .ms-md-50 {
    margin-left: 3.125rem !important;
  }
  .ms-md-60 {
    margin-left: 3.75rem !important;
  }
  .ms-md-70 {
    margin-left: 4.375rem !important;
  }
  .ms-md-80 {
    margin-left: 5rem !important;
  }
  .ms-md-90 {
    margin-left: 5.625rem !important;
  }
  .ms-md-100 {
    margin-left: 6.25rem !important;
  }
  .ms-md-120 {
    margin-left: 7.5rem !important;
  }
  .ms-md-130 {
    margin-left: 8.125rem !important;
  }
  .ms-md-140 {
    margin-left: 8.75rem !important;
  }
  .ms-md-150 {
    margin-left: 9.375rem !important;
  }
  .ms-md-180 {
    margin-left: 11.25rem !important;
  }
  .ms-md-200 {
    margin-left: 12.5rem !important;
  }
  .ms-md-220 {
    margin-left: 13.75rem !important;
  }
  .ms-md-230 {
    margin-left: 14.375rem !important;
  }
  .ms-md-250 {
    margin-left: 15.625rem !important;
  }
  .ms-md-300 {
    margin-left: 18.75rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .m-md-n10 {
    margin: -0.625rem !important;
  }
  .m-md-n15 {
    margin: -0.9375rem !important;
  }
  .m-md-n20 {
    margin: -1.25rem !important;
  }
  .m-md-n30 {
    margin: -1.875rem !important;
  }
  .m-md-n40 {
    margin: -2.5rem !important;
  }
  .m-md-n50 {
    margin: -3.125rem !important;
  }
  .m-md-n60 {
    margin: -3.75rem !important;
  }
  .m-md-n70 {
    margin: -4.375rem !important;
  }
  .m-md-n80 {
    margin: -5rem !important;
  }
  .m-md-n90 {
    margin: -5.625rem !important;
  }
  .m-md-n100 {
    margin: -6.25rem !important;
  }
  .m-md-n120 {
    margin: -7.5rem !important;
  }
  .m-md-n130 {
    margin: -8.125rem !important;
  }
  .m-md-n140 {
    margin: -8.75rem !important;
  }
  .m-md-n150 {
    margin: -9.375rem !important;
  }
  .m-md-n180 {
    margin: -11.25rem !important;
  }
  .m-md-n200 {
    margin: -12.5rem !important;
  }
  .m-md-n220 {
    margin: -13.75rem !important;
  }
  .m-md-n230 {
    margin: -14.375rem !important;
  }
  .m-md-n250 {
    margin: -15.625rem !important;
  }
  .m-md-n300 {
    margin: -18.75rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n15 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-md-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-md-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-md-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-md-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-md-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-md-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-md-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-md-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-md-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-md-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-md-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-md-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-md-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-md-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-md-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n15 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-md-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-md-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-md-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-md-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-md-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-md-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-md-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-md-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-md-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-md-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-md-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-md-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-md-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-md-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-md-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2rem !important;
  }
  .mt-md-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n15 {
    margin-top: -0.9375rem !important;
  }
  .mt-md-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-md-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-md-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-md-n80 {
    margin-top: -5rem !important;
  }
  .mt-md-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-md-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-md-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-md-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-md-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-md-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-md-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-md-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-md-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-md-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-md-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-md-n300 {
    margin-top: -18.75rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2rem !important;
  }
  .me-md-n10 {
    margin-right: -0.625rem !important;
  }
  .me-md-n15 {
    margin-right: -0.9375rem !important;
  }
  .me-md-n20 {
    margin-right: -1.25rem !important;
  }
  .me-md-n30 {
    margin-right: -1.875rem !important;
  }
  .me-md-n40 {
    margin-right: -2.5rem !important;
  }
  .me-md-n50 {
    margin-right: -3.125rem !important;
  }
  .me-md-n60 {
    margin-right: -3.75rem !important;
  }
  .me-md-n70 {
    margin-right: -4.375rem !important;
  }
  .me-md-n80 {
    margin-right: -5rem !important;
  }
  .me-md-n90 {
    margin-right: -5.625rem !important;
  }
  .me-md-n100 {
    margin-right: -6.25rem !important;
  }
  .me-md-n120 {
    margin-right: -7.5rem !important;
  }
  .me-md-n130 {
    margin-right: -8.125rem !important;
  }
  .me-md-n140 {
    margin-right: -8.75rem !important;
  }
  .me-md-n150 {
    margin-right: -9.375rem !important;
  }
  .me-md-n180 {
    margin-right: -11.25rem !important;
  }
  .me-md-n200 {
    margin-right: -12.5rem !important;
  }
  .me-md-n220 {
    margin-right: -13.75rem !important;
  }
  .me-md-n230 {
    margin-right: -14.375rem !important;
  }
  .me-md-n250 {
    margin-right: -15.625rem !important;
  }
  .me-md-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-md-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-md-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-md-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-md-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-md-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-md-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-md-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-md-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-md-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-md-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-md-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-md-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-md-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-md-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-md-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2rem !important;
  }
  .ms-md-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n15 {
    margin-left: -0.9375rem !important;
  }
  .ms-md-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-md-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-md-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-md-n80 {
    margin-left: -5rem !important;
  }
  .ms-md-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-md-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-md-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-md-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-md-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-md-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-md-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-md-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-md-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-md-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-md-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-md-n300 {
    margin-left: -18.75rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .p-md-10 {
    padding: 0.625rem !important;
  }
  .p-md-15 {
    padding: 0.9375rem !important;
  }
  .p-md-20 {
    padding: 1.25rem !important;
  }
  .p-md-30 {
    padding: 1.875rem !important;
  }
  .p-md-40 {
    padding: 2.5rem !important;
  }
  .p-md-50 {
    padding: 3.125rem !important;
  }
  .p-md-60 {
    padding: 3.75rem !important;
  }
  .p-md-70 {
    padding: 4.375rem !important;
  }
  .p-md-80 {
    padding: 5rem !important;
  }
  .p-md-90 {
    padding: 5.625rem !important;
  }
  .p-md-100 {
    padding: 6.25rem !important;
  }
  .p-md-120 {
    padding: 7.5rem !important;
  }
  .p-md-130 {
    padding: 8.125rem !important;
  }
  .p-md-140 {
    padding: 8.75rem !important;
  }
  .p-md-150 {
    padding: 9.375rem !important;
  }
  .p-md-180 {
    padding: 11.25rem !important;
  }
  .p-md-200 {
    padding: 12.5rem !important;
  }
  .p-md-220 {
    padding: 13.75rem !important;
  }
  .p-md-230 {
    padding: 14.375rem !important;
  }
  .p-md-250 {
    padding: 15.625rem !important;
  }
  .p-md-300 {
    padding: 18.75rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-15 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-md-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-md-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-md-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-md-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-md-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-md-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-md-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-md-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-md-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-md-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-md-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-md-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-md-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-md-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-15 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-md-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-md-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-md-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-md-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-md-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-md-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-md-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-md-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-md-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-md-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-md-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-md-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-md-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-md-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2rem !important;
  }
  .pt-md-10 {
    padding-top: 0.625rem !important;
  }
  .pt-md-15 {
    padding-top: 0.9375rem !important;
  }
  .pt-md-20 {
    padding-top: 1.25rem !important;
  }
  .pt-md-30 {
    padding-top: 1.875rem !important;
  }
  .pt-md-40 {
    padding-top: 2.5rem !important;
  }
  .pt-md-50 {
    padding-top: 3.125rem !important;
  }
  .pt-md-60 {
    padding-top: 3.75rem !important;
  }
  .pt-md-70 {
    padding-top: 4.375rem !important;
  }
  .pt-md-80 {
    padding-top: 5rem !important;
  }
  .pt-md-90 {
    padding-top: 5.625rem !important;
  }
  .pt-md-100 {
    padding-top: 6.25rem !important;
  }
  .pt-md-120 {
    padding-top: 7.5rem !important;
  }
  .pt-md-130 {
    padding-top: 8.125rem !important;
  }
  .pt-md-140 {
    padding-top: 8.75rem !important;
  }
  .pt-md-150 {
    padding-top: 9.375rem !important;
  }
  .pt-md-180 {
    padding-top: 11.25rem !important;
  }
  .pt-md-200 {
    padding-top: 12.5rem !important;
  }
  .pt-md-220 {
    padding-top: 13.75rem !important;
  }
  .pt-md-230 {
    padding-top: 14.375rem !important;
  }
  .pt-md-250 {
    padding-top: 15.625rem !important;
  }
  .pt-md-300 {
    padding-top: 18.75rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2rem !important;
  }
  .pe-md-10 {
    padding-right: 0.625rem !important;
  }
  .pe-md-15 {
    padding-right: 0.9375rem !important;
  }
  .pe-md-20 {
    padding-right: 1.25rem !important;
  }
  .pe-md-30 {
    padding-right: 1.875rem !important;
  }
  .pe-md-40 {
    padding-right: 2.5rem !important;
  }
  .pe-md-50 {
    padding-right: 3.125rem !important;
  }
  .pe-md-60 {
    padding-right: 3.75rem !important;
  }
  .pe-md-70 {
    padding-right: 4.375rem !important;
  }
  .pe-md-80 {
    padding-right: 5rem !important;
  }
  .pe-md-90 {
    padding-right: 5.625rem !important;
  }
  .pe-md-100 {
    padding-right: 6.25rem !important;
  }
  .pe-md-120 {
    padding-right: 7.5rem !important;
  }
  .pe-md-130 {
    padding-right: 8.125rem !important;
  }
  .pe-md-140 {
    padding-right: 8.75rem !important;
  }
  .pe-md-150 {
    padding-right: 9.375rem !important;
  }
  .pe-md-180 {
    padding-right: 11.25rem !important;
  }
  .pe-md-200 {
    padding-right: 12.5rem !important;
  }
  .pe-md-220 {
    padding-right: 13.75rem !important;
  }
  .pe-md-230 {
    padding-right: 14.375rem !important;
  }
  .pe-md-250 {
    padding-right: 15.625rem !important;
  }
  .pe-md-300 {
    padding-right: 18.75rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
  .pb-md-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-md-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-md-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-md-80 {
    padding-bottom: 5rem !important;
  }
  .pb-md-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-md-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-md-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-md-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-md-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-md-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-md-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-md-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-md-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-md-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-md-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2rem !important;
  }
  .ps-md-10 {
    padding-left: 0.625rem !important;
  }
  .ps-md-15 {
    padding-left: 0.9375rem !important;
  }
  .ps-md-20 {
    padding-left: 1.25rem !important;
  }
  .ps-md-30 {
    padding-left: 1.875rem !important;
  }
  .ps-md-40 {
    padding-left: 2.5rem !important;
  }
  .ps-md-50 {
    padding-left: 3.125rem !important;
  }
  .ps-md-60 {
    padding-left: 3.75rem !important;
  }
  .ps-md-70 {
    padding-left: 4.375rem !important;
  }
  .ps-md-80 {
    padding-left: 5rem !important;
  }
  .ps-md-90 {
    padding-left: 5.625rem !important;
  }
  .ps-md-100 {
    padding-left: 6.25rem !important;
  }
  .ps-md-120 {
    padding-left: 7.5rem !important;
  }
  .ps-md-130 {
    padding-left: 8.125rem !important;
  }
  .ps-md-140 {
    padding-left: 8.75rem !important;
  }
  .ps-md-150 {
    padding-left: 9.375rem !important;
  }
  .ps-md-180 {
    padding-left: 11.25rem !important;
  }
  .ps-md-200 {
    padding-left: 12.5rem !important;
  }
  .ps-md-220 {
    padding-left: 13.75rem !important;
  }
  .ps-md-230 {
    padding-left: 14.375rem !important;
  }
  .ps-md-250 {
    padding-left: 15.625rem !important;
  }
  .ps-md-300 {
    padding-left: 18.75rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2rem !important;
  }
  .gap-md-10 {
    gap: 0.625rem !important;
  }
  .gap-md-15 {
    gap: 0.9375rem !important;
  }
  .gap-md-20 {
    gap: 1.25rem !important;
  }
  .gap-md-30 {
    gap: 1.875rem !important;
  }
  .gap-md-40 {
    gap: 2.5rem !important;
  }
  .gap-md-50 {
    gap: 3.125rem !important;
  }
  .gap-md-60 {
    gap: 3.75rem !important;
  }
  .gap-md-70 {
    gap: 4.375rem !important;
  }
  .gap-md-80 {
    gap: 5rem !important;
  }
  .gap-md-90 {
    gap: 5.625rem !important;
  }
  .gap-md-100 {
    gap: 6.25rem !important;
  }
  .gap-md-120 {
    gap: 7.5rem !important;
  }
  .gap-md-130 {
    gap: 8.125rem !important;
  }
  .gap-md-140 {
    gap: 8.75rem !important;
  }
  .gap-md-150 {
    gap: 9.375rem !important;
  }
  .gap-md-180 {
    gap: 11.25rem !important;
  }
  .gap-md-200 {
    gap: 12.5rem !important;
  }
  .gap-md-220 {
    gap: 13.75rem !important;
  }
  .gap-md-230 {
    gap: 14.375rem !important;
  }
  .gap-md-250 {
    gap: 15.625rem !important;
  }
  .gap-md-300 {
    gap: 18.75rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 2rem !important;
  }
  .row-gap-md-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-md-15 {
    row-gap: 0.9375rem !important;
  }
  .row-gap-md-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-md-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-md-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-md-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-md-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-md-80 {
    row-gap: 5rem !important;
  }
  .row-gap-md-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-md-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-md-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-md-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-md-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-md-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-md-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-md-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-md-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-md-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-md-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-md-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 2rem !important;
  }
  .column-gap-md-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-md-15 {
    column-gap: 0.9375rem !important;
  }
  .column-gap-md-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-md-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-md-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-md-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-md-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-md-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-md-80 {
    column-gap: 5rem !important;
  }
  .column-gap-md-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-md-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-md-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-md-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-md-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-md-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-md-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-md-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-md-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-md-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-md-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-md-300 {
    column-gap: 18.75rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .m-lg-10 {
    margin: 0.625rem !important;
  }
  .m-lg-15 {
    margin: 0.9375rem !important;
  }
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .m-lg-30 {
    margin: 1.875rem !important;
  }
  .m-lg-40 {
    margin: 2.5rem !important;
  }
  .m-lg-50 {
    margin: 3.125rem !important;
  }
  .m-lg-60 {
    margin: 3.75rem !important;
  }
  .m-lg-70 {
    margin: 4.375rem !important;
  }
  .m-lg-80 {
    margin: 5rem !important;
  }
  .m-lg-90 {
    margin: 5.625rem !important;
  }
  .m-lg-100 {
    margin: 6.25rem !important;
  }
  .m-lg-120 {
    margin: 7.5rem !important;
  }
  .m-lg-130 {
    margin: 8.125rem !important;
  }
  .m-lg-140 {
    margin: 8.75rem !important;
  }
  .m-lg-150 {
    margin: 9.375rem !important;
  }
  .m-lg-180 {
    margin: 11.25rem !important;
  }
  .m-lg-200 {
    margin: 12.5rem !important;
  }
  .m-lg-220 {
    margin: 13.75rem !important;
  }
  .m-lg-230 {
    margin: 14.375rem !important;
  }
  .m-lg-250 {
    margin: 15.625rem !important;
  }
  .m-lg-300 {
    margin: 18.75rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-15 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-lg-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-lg-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-lg-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-lg-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-lg-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-lg-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-lg-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-lg-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-lg-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-lg-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-lg-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-15 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-lg-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-lg-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-lg-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-lg-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-lg-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-lg-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-lg-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-lg-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-lg-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-lg-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-lg-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-lg-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2rem !important;
  }
  .mt-lg-10 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-15 {
    margin-top: 0.9375rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-30 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-40 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-50 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-60 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-70 {
    margin-top: 4.375rem !important;
  }
  .mt-lg-80 {
    margin-top: 5rem !important;
  }
  .mt-lg-90 {
    margin-top: 5.625rem !important;
  }
  .mt-lg-100 {
    margin-top: 6.25rem !important;
  }
  .mt-lg-120 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-130 {
    margin-top: 8.125rem !important;
  }
  .mt-lg-140 {
    margin-top: 8.75rem !important;
  }
  .mt-lg-150 {
    margin-top: 9.375rem !important;
  }
  .mt-lg-180 {
    margin-top: 11.25rem !important;
  }
  .mt-lg-200 {
    margin-top: 12.5rem !important;
  }
  .mt-lg-220 {
    margin-top: 13.75rem !important;
  }
  .mt-lg-230 {
    margin-top: 14.375rem !important;
  }
  .mt-lg-250 {
    margin-top: 15.625rem !important;
  }
  .mt-lg-300 {
    margin-top: 18.75rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2rem !important;
  }
  .me-lg-10 {
    margin-right: 0.625rem !important;
  }
  .me-lg-15 {
    margin-right: 0.9375rem !important;
  }
  .me-lg-20 {
    margin-right: 1.25rem !important;
  }
  .me-lg-30 {
    margin-right: 1.875rem !important;
  }
  .me-lg-40 {
    margin-right: 2.5rem !important;
  }
  .me-lg-50 {
    margin-right: 3.125rem !important;
  }
  .me-lg-60 {
    margin-right: 3.75rem !important;
  }
  .me-lg-70 {
    margin-right: 4.375rem !important;
  }
  .me-lg-80 {
    margin-right: 5rem !important;
  }
  .me-lg-90 {
    margin-right: 5.625rem !important;
  }
  .me-lg-100 {
    margin-right: 6.25rem !important;
  }
  .me-lg-120 {
    margin-right: 7.5rem !important;
  }
  .me-lg-130 {
    margin-right: 8.125rem !important;
  }
  .me-lg-140 {
    margin-right: 8.75rem !important;
  }
  .me-lg-150 {
    margin-right: 9.375rem !important;
  }
  .me-lg-180 {
    margin-right: 11.25rem !important;
  }
  .me-lg-200 {
    margin-right: 12.5rem !important;
  }
  .me-lg-220 {
    margin-right: 13.75rem !important;
  }
  .me-lg-230 {
    margin-right: 14.375rem !important;
  }
  .me-lg-250 {
    margin-right: 15.625rem !important;
  }
  .me-lg-300 {
    margin-right: 18.75rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-lg-80 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-lg-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-lg-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-lg-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-lg-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-lg-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-lg-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-lg-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-lg-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-lg-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-lg-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
  .ms-lg-10 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-15 {
    margin-left: 0.9375rem !important;
  }
  .ms-lg-20 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-30 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-40 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-50 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-60 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-70 {
    margin-left: 4.375rem !important;
  }
  .ms-lg-80 {
    margin-left: 5rem !important;
  }
  .ms-lg-90 {
    margin-left: 5.625rem !important;
  }
  .ms-lg-100 {
    margin-left: 6.25rem !important;
  }
  .ms-lg-120 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-130 {
    margin-left: 8.125rem !important;
  }
  .ms-lg-140 {
    margin-left: 8.75rem !important;
  }
  .ms-lg-150 {
    margin-left: 9.375rem !important;
  }
  .ms-lg-180 {
    margin-left: 11.25rem !important;
  }
  .ms-lg-200 {
    margin-left: 12.5rem !important;
  }
  .ms-lg-220 {
    margin-left: 13.75rem !important;
  }
  .ms-lg-230 {
    margin-left: 14.375rem !important;
  }
  .ms-lg-250 {
    margin-left: 15.625rem !important;
  }
  .ms-lg-300 {
    margin-left: 18.75rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .m-lg-n10 {
    margin: -0.625rem !important;
  }
  .m-lg-n15 {
    margin: -0.9375rem !important;
  }
  .m-lg-n20 {
    margin: -1.25rem !important;
  }
  .m-lg-n30 {
    margin: -1.875rem !important;
  }
  .m-lg-n40 {
    margin: -2.5rem !important;
  }
  .m-lg-n50 {
    margin: -3.125rem !important;
  }
  .m-lg-n60 {
    margin: -3.75rem !important;
  }
  .m-lg-n70 {
    margin: -4.375rem !important;
  }
  .m-lg-n80 {
    margin: -5rem !important;
  }
  .m-lg-n90 {
    margin: -5.625rem !important;
  }
  .m-lg-n100 {
    margin: -6.25rem !important;
  }
  .m-lg-n120 {
    margin: -7.5rem !important;
  }
  .m-lg-n130 {
    margin: -8.125rem !important;
  }
  .m-lg-n140 {
    margin: -8.75rem !important;
  }
  .m-lg-n150 {
    margin: -9.375rem !important;
  }
  .m-lg-n180 {
    margin: -11.25rem !important;
  }
  .m-lg-n200 {
    margin: -12.5rem !important;
  }
  .m-lg-n220 {
    margin: -13.75rem !important;
  }
  .m-lg-n230 {
    margin: -14.375rem !important;
  }
  .m-lg-n250 {
    margin: -15.625rem !important;
  }
  .m-lg-n300 {
    margin: -18.75rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n15 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-lg-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-lg-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-lg-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-lg-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-lg-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-lg-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-lg-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-lg-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-lg-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-lg-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-lg-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-lg-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-lg-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-lg-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-lg-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n15 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-lg-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-lg-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-lg-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-lg-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-lg-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-lg-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-lg-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-lg-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-lg-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-lg-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-lg-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-lg-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-lg-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-lg-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-lg-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2rem !important;
  }
  .mt-lg-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n15 {
    margin-top: -0.9375rem !important;
  }
  .mt-lg-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-lg-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-lg-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-lg-n80 {
    margin-top: -5rem !important;
  }
  .mt-lg-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-lg-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-lg-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-lg-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-lg-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-lg-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-lg-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-lg-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-lg-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-lg-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-lg-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-lg-n300 {
    margin-top: -18.75rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2rem !important;
  }
  .me-lg-n10 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n15 {
    margin-right: -0.9375rem !important;
  }
  .me-lg-n20 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n30 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n40 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n50 {
    margin-right: -3.125rem !important;
  }
  .me-lg-n60 {
    margin-right: -3.75rem !important;
  }
  .me-lg-n70 {
    margin-right: -4.375rem !important;
  }
  .me-lg-n80 {
    margin-right: -5rem !important;
  }
  .me-lg-n90 {
    margin-right: -5.625rem !important;
  }
  .me-lg-n100 {
    margin-right: -6.25rem !important;
  }
  .me-lg-n120 {
    margin-right: -7.5rem !important;
  }
  .me-lg-n130 {
    margin-right: -8.125rem !important;
  }
  .me-lg-n140 {
    margin-right: -8.75rem !important;
  }
  .me-lg-n150 {
    margin-right: -9.375rem !important;
  }
  .me-lg-n180 {
    margin-right: -11.25rem !important;
  }
  .me-lg-n200 {
    margin-right: -12.5rem !important;
  }
  .me-lg-n220 {
    margin-right: -13.75rem !important;
  }
  .me-lg-n230 {
    margin-right: -14.375rem !important;
  }
  .me-lg-n250 {
    margin-right: -15.625rem !important;
  }
  .me-lg-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-lg-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-lg-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-lg-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-lg-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-lg-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-lg-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-lg-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-lg-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-lg-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-lg-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-lg-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-lg-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-lg-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-lg-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-lg-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2rem !important;
  }
  .ms-lg-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n15 {
    margin-left: -0.9375rem !important;
  }
  .ms-lg-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-lg-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-lg-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-lg-n80 {
    margin-left: -5rem !important;
  }
  .ms-lg-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-lg-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-lg-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-lg-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-lg-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-lg-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-lg-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-lg-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-lg-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-lg-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-lg-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-lg-n300 {
    margin-left: -18.75rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .p-lg-10 {
    padding: 0.625rem !important;
  }
  .p-lg-15 {
    padding: 0.9375rem !important;
  }
  .p-lg-20 {
    padding: 1.25rem !important;
  }
  .p-lg-30 {
    padding: 1.875rem !important;
  }
  .p-lg-40 {
    padding: 2.5rem !important;
  }
  .p-lg-50 {
    padding: 3.125rem !important;
  }
  .p-lg-60 {
    padding: 3.75rem !important;
  }
  .p-lg-70 {
    padding: 4.375rem !important;
  }
  .p-lg-80 {
    padding: 5rem !important;
  }
  .p-lg-90 {
    padding: 5.625rem !important;
  }
  .p-lg-100 {
    padding: 6.25rem !important;
  }
  .p-lg-120 {
    padding: 7.5rem !important;
  }
  .p-lg-130 {
    padding: 8.125rem !important;
  }
  .p-lg-140 {
    padding: 8.75rem !important;
  }
  .p-lg-150 {
    padding: 9.375rem !important;
  }
  .p-lg-180 {
    padding: 11.25rem !important;
  }
  .p-lg-200 {
    padding: 12.5rem !important;
  }
  .p-lg-220 {
    padding: 13.75rem !important;
  }
  .p-lg-230 {
    padding: 14.375rem !important;
  }
  .p-lg-250 {
    padding: 15.625rem !important;
  }
  .p-lg-300 {
    padding: 18.75rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-15 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-lg-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-lg-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-lg-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-lg-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-lg-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-lg-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-lg-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-lg-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-lg-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-lg-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-lg-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-lg-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-lg-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-lg-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-15 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-lg-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-lg-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-lg-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-lg-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-lg-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-lg-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-lg-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-lg-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-lg-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-lg-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-lg-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-lg-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-lg-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-lg-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2rem !important;
  }
  .pt-lg-10 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-15 {
    padding-top: 0.9375rem !important;
  }
  .pt-lg-20 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-30 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-40 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-50 {
    padding-top: 3.125rem !important;
  }
  .pt-lg-60 {
    padding-top: 3.75rem !important;
  }
  .pt-lg-70 {
    padding-top: 4.375rem !important;
  }
  .pt-lg-80 {
    padding-top: 5rem !important;
  }
  .pt-lg-90 {
    padding-top: 5.625rem !important;
  }
  .pt-lg-100 {
    padding-top: 6.25rem !important;
  }
  .pt-lg-120 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-130 {
    padding-top: 8.125rem !important;
  }
  .pt-lg-140 {
    padding-top: 8.75rem !important;
  }
  .pt-lg-150 {
    padding-top: 9.375rem !important;
  }
  .pt-lg-180 {
    padding-top: 11.25rem !important;
  }
  .pt-lg-200 {
    padding-top: 12.5rem !important;
  }
  .pt-lg-220 {
    padding-top: 13.75rem !important;
  }
  .pt-lg-230 {
    padding-top: 14.375rem !important;
  }
  .pt-lg-250 {
    padding-top: 15.625rem !important;
  }
  .pt-lg-300 {
    padding-top: 18.75rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2rem !important;
  }
  .pe-lg-10 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-15 {
    padding-right: 0.9375rem !important;
  }
  .pe-lg-20 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-30 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-40 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-50 {
    padding-right: 3.125rem !important;
  }
  .pe-lg-60 {
    padding-right: 3.75rem !important;
  }
  .pe-lg-70 {
    padding-right: 4.375rem !important;
  }
  .pe-lg-80 {
    padding-right: 5rem !important;
  }
  .pe-lg-90 {
    padding-right: 5.625rem !important;
  }
  .pe-lg-100 {
    padding-right: 6.25rem !important;
  }
  .pe-lg-120 {
    padding-right: 7.5rem !important;
  }
  .pe-lg-130 {
    padding-right: 8.125rem !important;
  }
  .pe-lg-140 {
    padding-right: 8.75rem !important;
  }
  .pe-lg-150 {
    padding-right: 9.375rem !important;
  }
  .pe-lg-180 {
    padding-right: 11.25rem !important;
  }
  .pe-lg-200 {
    padding-right: 12.5rem !important;
  }
  .pe-lg-220 {
    padding-right: 13.75rem !important;
  }
  .pe-lg-230 {
    padding-right: 14.375rem !important;
  }
  .pe-lg-250 {
    padding-right: 15.625rem !important;
  }
  .pe-lg-300 {
    padding-right: 18.75rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-lg-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-lg-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-lg-80 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-lg-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-lg-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-lg-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-lg-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-lg-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-lg-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-lg-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-lg-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-lg-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-lg-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2rem !important;
  }
  .ps-lg-10 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-15 {
    padding-left: 0.9375rem !important;
  }
  .ps-lg-20 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-30 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-40 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-50 {
    padding-left: 3.125rem !important;
  }
  .ps-lg-60 {
    padding-left: 3.75rem !important;
  }
  .ps-lg-70 {
    padding-left: 4.375rem !important;
  }
  .ps-lg-80 {
    padding-left: 5rem !important;
  }
  .ps-lg-90 {
    padding-left: 5.625rem !important;
  }
  .ps-lg-100 {
    padding-left: 6.25rem !important;
  }
  .ps-lg-120 {
    padding-left: 7.5rem !important;
  }
  .ps-lg-130 {
    padding-left: 8.125rem !important;
  }
  .ps-lg-140 {
    padding-left: 8.75rem !important;
  }
  .ps-lg-150 {
    padding-left: 9.375rem !important;
  }
  .ps-lg-180 {
    padding-left: 11.25rem !important;
  }
  .ps-lg-200 {
    padding-left: 12.5rem !important;
  }
  .ps-lg-220 {
    padding-left: 13.75rem !important;
  }
  .ps-lg-230 {
    padding-left: 14.375rem !important;
  }
  .ps-lg-250 {
    padding-left: 15.625rem !important;
  }
  .ps-lg-300 {
    padding-left: 18.75rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2rem !important;
  }
  .gap-lg-10 {
    gap: 0.625rem !important;
  }
  .gap-lg-15 {
    gap: 0.9375rem !important;
  }
  .gap-lg-20 {
    gap: 1.25rem !important;
  }
  .gap-lg-30 {
    gap: 1.875rem !important;
  }
  .gap-lg-40 {
    gap: 2.5rem !important;
  }
  .gap-lg-50 {
    gap: 3.125rem !important;
  }
  .gap-lg-60 {
    gap: 3.75rem !important;
  }
  .gap-lg-70 {
    gap: 4.375rem !important;
  }
  .gap-lg-80 {
    gap: 5rem !important;
  }
  .gap-lg-90 {
    gap: 5.625rem !important;
  }
  .gap-lg-100 {
    gap: 6.25rem !important;
  }
  .gap-lg-120 {
    gap: 7.5rem !important;
  }
  .gap-lg-130 {
    gap: 8.125rem !important;
  }
  .gap-lg-140 {
    gap: 8.75rem !important;
  }
  .gap-lg-150 {
    gap: 9.375rem !important;
  }
  .gap-lg-180 {
    gap: 11.25rem !important;
  }
  .gap-lg-200 {
    gap: 12.5rem !important;
  }
  .gap-lg-220 {
    gap: 13.75rem !important;
  }
  .gap-lg-230 {
    gap: 14.375rem !important;
  }
  .gap-lg-250 {
    gap: 15.625rem !important;
  }
  .gap-lg-300 {
    gap: 18.75rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 0.9375rem !important;
  }
  .row-gap-lg-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-lg-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-lg-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-lg-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-lg-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-lg-80 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-lg-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-lg-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-lg-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-lg-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-lg-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-lg-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-lg-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-lg-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-lg-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-lg-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-lg-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 2rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-lg-15 {
    column-gap: 0.9375rem !important;
  }
  .column-gap-lg-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-lg-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-lg-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-lg-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-lg-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-lg-80 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-lg-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-lg-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-lg-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-lg-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-lg-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-lg-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-lg-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-lg-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-lg-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-lg-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-lg-300 {
    column-gap: 18.75rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .m-xl-10 {
    margin: 0.625rem !important;
  }
  .m-xl-15 {
    margin: 0.9375rem !important;
  }
  .m-xl-20 {
    margin: 1.25rem !important;
  }
  .m-xl-30 {
    margin: 1.875rem !important;
  }
  .m-xl-40 {
    margin: 2.5rem !important;
  }
  .m-xl-50 {
    margin: 3.125rem !important;
  }
  .m-xl-60 {
    margin: 3.75rem !important;
  }
  .m-xl-70 {
    margin: 4.375rem !important;
  }
  .m-xl-80 {
    margin: 5rem !important;
  }
  .m-xl-90 {
    margin: 5.625rem !important;
  }
  .m-xl-100 {
    margin: 6.25rem !important;
  }
  .m-xl-120 {
    margin: 7.5rem !important;
  }
  .m-xl-130 {
    margin: 8.125rem !important;
  }
  .m-xl-140 {
    margin: 8.75rem !important;
  }
  .m-xl-150 {
    margin: 9.375rem !important;
  }
  .m-xl-180 {
    margin: 11.25rem !important;
  }
  .m-xl-200 {
    margin: 12.5rem !important;
  }
  .m-xl-220 {
    margin: 13.75rem !important;
  }
  .m-xl-230 {
    margin: 14.375rem !important;
  }
  .m-xl-250 {
    margin: 15.625rem !important;
  }
  .m-xl-300 {
    margin: 18.75rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-15 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xl-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xl-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-xl-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xl-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-xl-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-xl-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-xl-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-xl-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xl-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-xl-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-xl-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-xl-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-15 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xl-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xl-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-xl-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xl-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-xl-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-xl-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-xl-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-xl-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xl-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-xl-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-xl-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-xl-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2rem !important;
  }
  .mt-xl-10 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-15 {
    margin-top: 0.9375rem !important;
  }
  .mt-xl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-30 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-40 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-50 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-60 {
    margin-top: 3.75rem !important;
  }
  .mt-xl-70 {
    margin-top: 4.375rem !important;
  }
  .mt-xl-80 {
    margin-top: 5rem !important;
  }
  .mt-xl-90 {
    margin-top: 5.625rem !important;
  }
  .mt-xl-100 {
    margin-top: 6.25rem !important;
  }
  .mt-xl-120 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-130 {
    margin-top: 8.125rem !important;
  }
  .mt-xl-140 {
    margin-top: 8.75rem !important;
  }
  .mt-xl-150 {
    margin-top: 9.375rem !important;
  }
  .mt-xl-180 {
    margin-top: 11.25rem !important;
  }
  .mt-xl-200 {
    margin-top: 12.5rem !important;
  }
  .mt-xl-220 {
    margin-top: 13.75rem !important;
  }
  .mt-xl-230 {
    margin-top: 14.375rem !important;
  }
  .mt-xl-250 {
    margin-top: 15.625rem !important;
  }
  .mt-xl-300 {
    margin-top: 18.75rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2rem !important;
  }
  .me-xl-10 {
    margin-right: 0.625rem !important;
  }
  .me-xl-15 {
    margin-right: 0.9375rem !important;
  }
  .me-xl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xl-30 {
    margin-right: 1.875rem !important;
  }
  .me-xl-40 {
    margin-right: 2.5rem !important;
  }
  .me-xl-50 {
    margin-right: 3.125rem !important;
  }
  .me-xl-60 {
    margin-right: 3.75rem !important;
  }
  .me-xl-70 {
    margin-right: 4.375rem !important;
  }
  .me-xl-80 {
    margin-right: 5rem !important;
  }
  .me-xl-90 {
    margin-right: 5.625rem !important;
  }
  .me-xl-100 {
    margin-right: 6.25rem !important;
  }
  .me-xl-120 {
    margin-right: 7.5rem !important;
  }
  .me-xl-130 {
    margin-right: 8.125rem !important;
  }
  .me-xl-140 {
    margin-right: 8.75rem !important;
  }
  .me-xl-150 {
    margin-right: 9.375rem !important;
  }
  .me-xl-180 {
    margin-right: 11.25rem !important;
  }
  .me-xl-200 {
    margin-right: 12.5rem !important;
  }
  .me-xl-220 {
    margin-right: 13.75rem !important;
  }
  .me-xl-230 {
    margin-right: 14.375rem !important;
  }
  .me-xl-250 {
    margin-right: 15.625rem !important;
  }
  .me-xl-300 {
    margin-right: 18.75rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xl-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xl-80 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-xl-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xl-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-xl-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-xl-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-xl-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-xl-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xl-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-xl-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-xl-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-xl-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2rem !important;
  }
  .ms-xl-10 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-15 {
    margin-left: 0.9375rem !important;
  }
  .ms-xl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-30 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-40 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-50 {
    margin-left: 3.125rem !important;
  }
  .ms-xl-60 {
    margin-left: 3.75rem !important;
  }
  .ms-xl-70 {
    margin-left: 4.375rem !important;
  }
  .ms-xl-80 {
    margin-left: 5rem !important;
  }
  .ms-xl-90 {
    margin-left: 5.625rem !important;
  }
  .ms-xl-100 {
    margin-left: 6.25rem !important;
  }
  .ms-xl-120 {
    margin-left: 7.5rem !important;
  }
  .ms-xl-130 {
    margin-left: 8.125rem !important;
  }
  .ms-xl-140 {
    margin-left: 8.75rem !important;
  }
  .ms-xl-150 {
    margin-left: 9.375rem !important;
  }
  .ms-xl-180 {
    margin-left: 11.25rem !important;
  }
  .ms-xl-200 {
    margin-left: 12.5rem !important;
  }
  .ms-xl-220 {
    margin-left: 13.75rem !important;
  }
  .ms-xl-230 {
    margin-left: 14.375rem !important;
  }
  .ms-xl-250 {
    margin-left: 15.625rem !important;
  }
  .ms-xl-300 {
    margin-left: 18.75rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .m-xl-n10 {
    margin: -0.625rem !important;
  }
  .m-xl-n15 {
    margin: -0.9375rem !important;
  }
  .m-xl-n20 {
    margin: -1.25rem !important;
  }
  .m-xl-n30 {
    margin: -1.875rem !important;
  }
  .m-xl-n40 {
    margin: -2.5rem !important;
  }
  .m-xl-n50 {
    margin: -3.125rem !important;
  }
  .m-xl-n60 {
    margin: -3.75rem !important;
  }
  .m-xl-n70 {
    margin: -4.375rem !important;
  }
  .m-xl-n80 {
    margin: -5rem !important;
  }
  .m-xl-n90 {
    margin: -5.625rem !important;
  }
  .m-xl-n100 {
    margin: -6.25rem !important;
  }
  .m-xl-n120 {
    margin: -7.5rem !important;
  }
  .m-xl-n130 {
    margin: -8.125rem !important;
  }
  .m-xl-n140 {
    margin: -8.75rem !important;
  }
  .m-xl-n150 {
    margin: -9.375rem !important;
  }
  .m-xl-n180 {
    margin: -11.25rem !important;
  }
  .m-xl-n200 {
    margin: -12.5rem !important;
  }
  .m-xl-n220 {
    margin: -13.75rem !important;
  }
  .m-xl-n230 {
    margin: -14.375rem !important;
  }
  .m-xl-n250 {
    margin: -15.625rem !important;
  }
  .m-xl-n300 {
    margin: -18.75rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n15 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-xl-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xl-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-xl-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-xl-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-xl-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xl-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-xl-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-xl-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-xl-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-xl-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-xl-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-xl-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-xl-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-xl-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n15 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-xl-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xl-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-xl-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-xl-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-xl-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xl-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-xl-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-xl-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-xl-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-xl-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-xl-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-xl-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-xl-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-xl-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xl-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n15 {
    margin-top: -0.9375rem !important;
  }
  .mt-xl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-xl-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-xl-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-xl-n80 {
    margin-top: -5rem !important;
  }
  .mt-xl-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-xl-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-xl-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-xl-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-xl-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-xl-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-xl-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-xl-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-xl-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-xl-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-xl-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-xl-n300 {
    margin-top: -18.75rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2rem !important;
  }
  .me-xl-n10 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n15 {
    margin-right: -0.9375rem !important;
  }
  .me-xl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n30 {
    margin-right: -1.875rem !important;
  }
  .me-xl-n40 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n50 {
    margin-right: -3.125rem !important;
  }
  .me-xl-n60 {
    margin-right: -3.75rem !important;
  }
  .me-xl-n70 {
    margin-right: -4.375rem !important;
  }
  .me-xl-n80 {
    margin-right: -5rem !important;
  }
  .me-xl-n90 {
    margin-right: -5.625rem !important;
  }
  .me-xl-n100 {
    margin-right: -6.25rem !important;
  }
  .me-xl-n120 {
    margin-right: -7.5rem !important;
  }
  .me-xl-n130 {
    margin-right: -8.125rem !important;
  }
  .me-xl-n140 {
    margin-right: -8.75rem !important;
  }
  .me-xl-n150 {
    margin-right: -9.375rem !important;
  }
  .me-xl-n180 {
    margin-right: -11.25rem !important;
  }
  .me-xl-n200 {
    margin-right: -12.5rem !important;
  }
  .me-xl-n220 {
    margin-right: -13.75rem !important;
  }
  .me-xl-n230 {
    margin-right: -14.375rem !important;
  }
  .me-xl-n250 {
    margin-right: -15.625rem !important;
  }
  .me-xl-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-xl-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xl-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-xl-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-xl-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-xl-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xl-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-xl-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-xl-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-xl-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-xl-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-xl-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-xl-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-xl-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-xl-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xl-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n15 {
    margin-left: -0.9375rem !important;
  }
  .ms-xl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-xl-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-xl-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-xl-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-xl-n80 {
    margin-left: -5rem !important;
  }
  .ms-xl-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-xl-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-xl-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-xl-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-xl-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-xl-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-xl-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-xl-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-xl-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-xl-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-xl-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-xl-n300 {
    margin-left: -18.75rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .p-xl-10 {
    padding: 0.625rem !important;
  }
  .p-xl-15 {
    padding: 0.9375rem !important;
  }
  .p-xl-20 {
    padding: 1.25rem !important;
  }
  .p-xl-30 {
    padding: 1.875rem !important;
  }
  .p-xl-40 {
    padding: 2.5rem !important;
  }
  .p-xl-50 {
    padding: 3.125rem !important;
  }
  .p-xl-60 {
    padding: 3.75rem !important;
  }
  .p-xl-70 {
    padding: 4.375rem !important;
  }
  .p-xl-80 {
    padding: 5rem !important;
  }
  .p-xl-90 {
    padding: 5.625rem !important;
  }
  .p-xl-100 {
    padding: 6.25rem !important;
  }
  .p-xl-120 {
    padding: 7.5rem !important;
  }
  .p-xl-130 {
    padding: 8.125rem !important;
  }
  .p-xl-140 {
    padding: 8.75rem !important;
  }
  .p-xl-150 {
    padding: 9.375rem !important;
  }
  .p-xl-180 {
    padding: 11.25rem !important;
  }
  .p-xl-200 {
    padding: 12.5rem !important;
  }
  .p-xl-220 {
    padding: 13.75rem !important;
  }
  .p-xl-230 {
    padding: 14.375rem !important;
  }
  .p-xl-250 {
    padding: 15.625rem !important;
  }
  .p-xl-300 {
    padding: 18.75rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-15 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-xl-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xl-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-xl-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-xl-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xl-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xl-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-xl-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-xl-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-xl-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-xl-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xl-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-xl-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-xl-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-xl-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-15 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-xl-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xl-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-xl-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-xl-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xl-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xl-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-xl-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-xl-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-xl-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-xl-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xl-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-xl-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-xl-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-xl-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2rem !important;
  }
  .pt-xl-10 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-15 {
    padding-top: 0.9375rem !important;
  }
  .pt-xl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-30 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-40 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-50 {
    padding-top: 3.125rem !important;
  }
  .pt-xl-60 {
    padding-top: 3.75rem !important;
  }
  .pt-xl-70 {
    padding-top: 4.375rem !important;
  }
  .pt-xl-80 {
    padding-top: 5rem !important;
  }
  .pt-xl-90 {
    padding-top: 5.625rem !important;
  }
  .pt-xl-100 {
    padding-top: 6.25rem !important;
  }
  .pt-xl-120 {
    padding-top: 7.5rem !important;
  }
  .pt-xl-130 {
    padding-top: 8.125rem !important;
  }
  .pt-xl-140 {
    padding-top: 8.75rem !important;
  }
  .pt-xl-150 {
    padding-top: 9.375rem !important;
  }
  .pt-xl-180 {
    padding-top: 11.25rem !important;
  }
  .pt-xl-200 {
    padding-top: 12.5rem !important;
  }
  .pt-xl-220 {
    padding-top: 13.75rem !important;
  }
  .pt-xl-230 {
    padding-top: 14.375rem !important;
  }
  .pt-xl-250 {
    padding-top: 15.625rem !important;
  }
  .pt-xl-300 {
    padding-top: 18.75rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2rem !important;
  }
  .pe-xl-10 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-15 {
    padding-right: 0.9375rem !important;
  }
  .pe-xl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-30 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-40 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-50 {
    padding-right: 3.125rem !important;
  }
  .pe-xl-60 {
    padding-right: 3.75rem !important;
  }
  .pe-xl-70 {
    padding-right: 4.375rem !important;
  }
  .pe-xl-80 {
    padding-right: 5rem !important;
  }
  .pe-xl-90 {
    padding-right: 5.625rem !important;
  }
  .pe-xl-100 {
    padding-right: 6.25rem !important;
  }
  .pe-xl-120 {
    padding-right: 7.5rem !important;
  }
  .pe-xl-130 {
    padding-right: 8.125rem !important;
  }
  .pe-xl-140 {
    padding-right: 8.75rem !important;
  }
  .pe-xl-150 {
    padding-right: 9.375rem !important;
  }
  .pe-xl-180 {
    padding-right: 11.25rem !important;
  }
  .pe-xl-200 {
    padding-right: 12.5rem !important;
  }
  .pe-xl-220 {
    padding-right: 13.75rem !important;
  }
  .pe-xl-230 {
    padding-right: 14.375rem !important;
  }
  .pe-xl-250 {
    padding-right: 15.625rem !important;
  }
  .pe-xl-300 {
    padding-right: 18.75rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-xl-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xl-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-xl-80 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-xl-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xl-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xl-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-xl-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-xl-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-xl-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-xl-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xl-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-xl-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-xl-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-xl-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2rem !important;
  }
  .ps-xl-10 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-15 {
    padding-left: 0.9375rem !important;
  }
  .ps-xl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-30 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-40 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-50 {
    padding-left: 3.125rem !important;
  }
  .ps-xl-60 {
    padding-left: 3.75rem !important;
  }
  .ps-xl-70 {
    padding-left: 4.375rem !important;
  }
  .ps-xl-80 {
    padding-left: 5rem !important;
  }
  .ps-xl-90 {
    padding-left: 5.625rem !important;
  }
  .ps-xl-100 {
    padding-left: 6.25rem !important;
  }
  .ps-xl-120 {
    padding-left: 7.5rem !important;
  }
  .ps-xl-130 {
    padding-left: 8.125rem !important;
  }
  .ps-xl-140 {
    padding-left: 8.75rem !important;
  }
  .ps-xl-150 {
    padding-left: 9.375rem !important;
  }
  .ps-xl-180 {
    padding-left: 11.25rem !important;
  }
  .ps-xl-200 {
    padding-left: 12.5rem !important;
  }
  .ps-xl-220 {
    padding-left: 13.75rem !important;
  }
  .ps-xl-230 {
    padding-left: 14.375rem !important;
  }
  .ps-xl-250 {
    padding-left: 15.625rem !important;
  }
  .ps-xl-300 {
    padding-left: 18.75rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2rem !important;
  }
  .gap-xl-10 {
    gap: 0.625rem !important;
  }
  .gap-xl-15 {
    gap: 0.9375rem !important;
  }
  .gap-xl-20 {
    gap: 1.25rem !important;
  }
  .gap-xl-30 {
    gap: 1.875rem !important;
  }
  .gap-xl-40 {
    gap: 2.5rem !important;
  }
  .gap-xl-50 {
    gap: 3.125rem !important;
  }
  .gap-xl-60 {
    gap: 3.75rem !important;
  }
  .gap-xl-70 {
    gap: 4.375rem !important;
  }
  .gap-xl-80 {
    gap: 5rem !important;
  }
  .gap-xl-90 {
    gap: 5.625rem !important;
  }
  .gap-xl-100 {
    gap: 6.25rem !important;
  }
  .gap-xl-120 {
    gap: 7.5rem !important;
  }
  .gap-xl-130 {
    gap: 8.125rem !important;
  }
  .gap-xl-140 {
    gap: 8.75rem !important;
  }
  .gap-xl-150 {
    gap: 9.375rem !important;
  }
  .gap-xl-180 {
    gap: 11.25rem !important;
  }
  .gap-xl-200 {
    gap: 12.5rem !important;
  }
  .gap-xl-220 {
    gap: 13.75rem !important;
  }
  .gap-xl-230 {
    gap: 14.375rem !important;
  }
  .gap-xl-250 {
    gap: 15.625rem !important;
  }
  .gap-xl-300 {
    gap: 18.75rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-xl-15 {
    row-gap: 0.9375rem !important;
  }
  .row-gap-xl-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xl-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-xl-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xl-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-xl-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xl-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-xl-80 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-xl-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xl-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xl-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-xl-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xl-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-xl-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-xl-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-xl-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-xl-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-xl-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-xl-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 2rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-xl-15 {
    column-gap: 0.9375rem !important;
  }
  .column-gap-xl-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xl-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-xl-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xl-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-xl-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xl-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-xl-80 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-xl-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xl-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xl-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-xl-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xl-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-xl-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-xl-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-xl-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-xl-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-xl-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-xl-300 {
    column-gap: 18.75rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .m-xxl-10 {
    margin: 0.625rem !important;
  }
  .m-xxl-15 {
    margin: 0.9375rem !important;
  }
  .m-xxl-20 {
    margin: 1.25rem !important;
  }
  .m-xxl-30 {
    margin: 1.875rem !important;
  }
  .m-xxl-40 {
    margin: 2.5rem !important;
  }
  .m-xxl-50 {
    margin: 3.125rem !important;
  }
  .m-xxl-60 {
    margin: 3.75rem !important;
  }
  .m-xxl-70 {
    margin: 4.375rem !important;
  }
  .m-xxl-80 {
    margin: 5rem !important;
  }
  .m-xxl-90 {
    margin: 5.625rem !important;
  }
  .m-xxl-100 {
    margin: 6.25rem !important;
  }
  .m-xxl-120 {
    margin: 7.5rem !important;
  }
  .m-xxl-130 {
    margin: 8.125rem !important;
  }
  .m-xxl-140 {
    margin: 8.75rem !important;
  }
  .m-xxl-150 {
    margin: 9.375rem !important;
  }
  .m-xxl-180 {
    margin: 11.25rem !important;
  }
  .m-xxl-200 {
    margin: 12.5rem !important;
  }
  .m-xxl-220 {
    margin: 13.75rem !important;
  }
  .m-xxl-230 {
    margin: 14.375rem !important;
  }
  .m-xxl-250 {
    margin: 15.625rem !important;
  }
  .m-xxl-300 {
    margin: 18.75rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-15 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xxl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xxl-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xxl-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xxl-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-xxl-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xxl-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-xxl-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-xxl-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-xxl-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-xxl-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xxl-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-xxl-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-xxl-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-xxl-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-15 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xxl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xxl-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xxl-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xxl-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-xxl-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xxl-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-xxl-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-xxl-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-xxl-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-xxl-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xxl-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-xxl-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-xxl-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-xxl-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2rem !important;
  }
  .mt-xxl-10 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-15 {
    margin-top: 0.9375rem !important;
  }
  .mt-xxl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-30 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-40 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-50 {
    margin-top: 3.125rem !important;
  }
  .mt-xxl-60 {
    margin-top: 3.75rem !important;
  }
  .mt-xxl-70 {
    margin-top: 4.375rem !important;
  }
  .mt-xxl-80 {
    margin-top: 5rem !important;
  }
  .mt-xxl-90 {
    margin-top: 5.625rem !important;
  }
  .mt-xxl-100 {
    margin-top: 6.25rem !important;
  }
  .mt-xxl-120 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-130 {
    margin-top: 8.125rem !important;
  }
  .mt-xxl-140 {
    margin-top: 8.75rem !important;
  }
  .mt-xxl-150 {
    margin-top: 9.375rem !important;
  }
  .mt-xxl-180 {
    margin-top: 11.25rem !important;
  }
  .mt-xxl-200 {
    margin-top: 12.5rem !important;
  }
  .mt-xxl-220 {
    margin-top: 13.75rem !important;
  }
  .mt-xxl-230 {
    margin-top: 14.375rem !important;
  }
  .mt-xxl-250 {
    margin-top: 15.625rem !important;
  }
  .mt-xxl-300 {
    margin-top: 18.75rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2rem !important;
  }
  .me-xxl-10 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-15 {
    margin-right: 0.9375rem !important;
  }
  .me-xxl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-30 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-40 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-50 {
    margin-right: 3.125rem !important;
  }
  .me-xxl-60 {
    margin-right: 3.75rem !important;
  }
  .me-xxl-70 {
    margin-right: 4.375rem !important;
  }
  .me-xxl-80 {
    margin-right: 5rem !important;
  }
  .me-xxl-90 {
    margin-right: 5.625rem !important;
  }
  .me-xxl-100 {
    margin-right: 6.25rem !important;
  }
  .me-xxl-120 {
    margin-right: 7.5rem !important;
  }
  .me-xxl-130 {
    margin-right: 8.125rem !important;
  }
  .me-xxl-140 {
    margin-right: 8.75rem !important;
  }
  .me-xxl-150 {
    margin-right: 9.375rem !important;
  }
  .me-xxl-180 {
    margin-right: 11.25rem !important;
  }
  .me-xxl-200 {
    margin-right: 12.5rem !important;
  }
  .me-xxl-220 {
    margin-right: 13.75rem !important;
  }
  .me-xxl-230 {
    margin-right: 14.375rem !important;
  }
  .me-xxl-250 {
    margin-right: 15.625rem !important;
  }
  .me-xxl-300 {
    margin-right: 18.75rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xxl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xxl-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xxl-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xxl-80 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-xxl-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xxl-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-xxl-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-xxl-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-xxl-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-xxl-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xxl-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-xxl-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-xxl-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-xxl-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2rem !important;
  }
  .ms-xxl-10 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-15 {
    margin-left: 0.9375rem !important;
  }
  .ms-xxl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-30 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-40 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-50 {
    margin-left: 3.125rem !important;
  }
  .ms-xxl-60 {
    margin-left: 3.75rem !important;
  }
  .ms-xxl-70 {
    margin-left: 4.375rem !important;
  }
  .ms-xxl-80 {
    margin-left: 5rem !important;
  }
  .ms-xxl-90 {
    margin-left: 5.625rem !important;
  }
  .ms-xxl-100 {
    margin-left: 6.25rem !important;
  }
  .ms-xxl-120 {
    margin-left: 7.5rem !important;
  }
  .ms-xxl-130 {
    margin-left: 8.125rem !important;
  }
  .ms-xxl-140 {
    margin-left: 8.75rem !important;
  }
  .ms-xxl-150 {
    margin-left: 9.375rem !important;
  }
  .ms-xxl-180 {
    margin-left: 11.25rem !important;
  }
  .ms-xxl-200 {
    margin-left: 12.5rem !important;
  }
  .ms-xxl-220 {
    margin-left: 13.75rem !important;
  }
  .ms-xxl-230 {
    margin-left: 14.375rem !important;
  }
  .ms-xxl-250 {
    margin-left: 15.625rem !important;
  }
  .ms-xxl-300 {
    margin-left: 18.75rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .m-xxl-n10 {
    margin: -0.625rem !important;
  }
  .m-xxl-n15 {
    margin: -0.9375rem !important;
  }
  .m-xxl-n20 {
    margin: -1.25rem !important;
  }
  .m-xxl-n30 {
    margin: -1.875rem !important;
  }
  .m-xxl-n40 {
    margin: -2.5rem !important;
  }
  .m-xxl-n50 {
    margin: -3.125rem !important;
  }
  .m-xxl-n60 {
    margin: -3.75rem !important;
  }
  .m-xxl-n70 {
    margin: -4.375rem !important;
  }
  .m-xxl-n80 {
    margin: -5rem !important;
  }
  .m-xxl-n90 {
    margin: -5.625rem !important;
  }
  .m-xxl-n100 {
    margin: -6.25rem !important;
  }
  .m-xxl-n120 {
    margin: -7.5rem !important;
  }
  .m-xxl-n130 {
    margin: -8.125rem !important;
  }
  .m-xxl-n140 {
    margin: -8.75rem !important;
  }
  .m-xxl-n150 {
    margin: -9.375rem !important;
  }
  .m-xxl-n180 {
    margin: -11.25rem !important;
  }
  .m-xxl-n200 {
    margin: -12.5rem !important;
  }
  .m-xxl-n220 {
    margin: -13.75rem !important;
  }
  .m-xxl-n230 {
    margin: -14.375rem !important;
  }
  .m-xxl-n250 {
    margin: -15.625rem !important;
  }
  .m-xxl-n300 {
    margin: -18.75rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xxl-n15 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xxl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xxl-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-xxl-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xxl-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-xxl-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-xxl-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-xxl-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xxl-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-xxl-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-xxl-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-xxl-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-xxl-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-xxl-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-xxl-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-xxl-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-xxl-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xxl-n15 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xxl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xxl-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-xxl-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xxl-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-xxl-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-xxl-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-xxl-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xxl-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-xxl-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-xxl-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-xxl-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-xxl-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-xxl-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-xxl-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-xxl-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-xxl-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -0.9375rem !important;
  }
  .mt-xxl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-xxl-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-xxl-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-xxl-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-xxl-n80 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-xxl-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-xxl-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-xxl-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-xxl-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-xxl-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-xxl-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-xxl-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-xxl-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-xxl-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-xxl-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-xxl-n300 {
    margin-top: -18.75rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2rem !important;
  }
  .me-xxl-n10 {
    margin-right: -0.625rem !important;
  }
  .me-xxl-n15 {
    margin-right: -0.9375rem !important;
  }
  .me-xxl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n30 {
    margin-right: -1.875rem !important;
  }
  .me-xxl-n40 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n50 {
    margin-right: -3.125rem !important;
  }
  .me-xxl-n60 {
    margin-right: -3.75rem !important;
  }
  .me-xxl-n70 {
    margin-right: -4.375rem !important;
  }
  .me-xxl-n80 {
    margin-right: -5rem !important;
  }
  .me-xxl-n90 {
    margin-right: -5.625rem !important;
  }
  .me-xxl-n100 {
    margin-right: -6.25rem !important;
  }
  .me-xxl-n120 {
    margin-right: -7.5rem !important;
  }
  .me-xxl-n130 {
    margin-right: -8.125rem !important;
  }
  .me-xxl-n140 {
    margin-right: -8.75rem !important;
  }
  .me-xxl-n150 {
    margin-right: -9.375rem !important;
  }
  .me-xxl-n180 {
    margin-right: -11.25rem !important;
  }
  .me-xxl-n200 {
    margin-right: -12.5rem !important;
  }
  .me-xxl-n220 {
    margin-right: -13.75rem !important;
  }
  .me-xxl-n230 {
    margin-right: -14.375rem !important;
  }
  .me-xxl-n250 {
    margin-right: -15.625rem !important;
  }
  .me-xxl-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xxl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xxl-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-xxl-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xxl-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-xxl-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-xxl-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-xxl-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xxl-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-xxl-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-xxl-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-xxl-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-xxl-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-xxl-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-xxl-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-xxl-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-xxl-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -0.9375rem !important;
  }
  .ms-xxl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-xxl-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-xxl-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-xxl-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-xxl-n80 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-xxl-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-xxl-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-xxl-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-xxl-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-xxl-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-xxl-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-xxl-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-xxl-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-xxl-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-xxl-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-xxl-n300 {
    margin-left: -18.75rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .p-xxl-10 {
    padding: 0.625rem !important;
  }
  .p-xxl-15 {
    padding: 0.9375rem !important;
  }
  .p-xxl-20 {
    padding: 1.25rem !important;
  }
  .p-xxl-30 {
    padding: 1.875rem !important;
  }
  .p-xxl-40 {
    padding: 2.5rem !important;
  }
  .p-xxl-50 {
    padding: 3.125rem !important;
  }
  .p-xxl-60 {
    padding: 3.75rem !important;
  }
  .p-xxl-70 {
    padding: 4.375rem !important;
  }
  .p-xxl-80 {
    padding: 5rem !important;
  }
  .p-xxl-90 {
    padding: 5.625rem !important;
  }
  .p-xxl-100 {
    padding: 6.25rem !important;
  }
  .p-xxl-120 {
    padding: 7.5rem !important;
  }
  .p-xxl-130 {
    padding: 8.125rem !important;
  }
  .p-xxl-140 {
    padding: 8.75rem !important;
  }
  .p-xxl-150 {
    padding: 9.375rem !important;
  }
  .p-xxl-180 {
    padding: 11.25rem !important;
  }
  .p-xxl-200 {
    padding: 12.5rem !important;
  }
  .p-xxl-220 {
    padding: 13.75rem !important;
  }
  .p-xxl-230 {
    padding: 14.375rem !important;
  }
  .p-xxl-250 {
    padding: 15.625rem !important;
  }
  .p-xxl-300 {
    padding: 18.75rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-15 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xxl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-xxl-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xxl-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-xxl-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-xxl-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xxl-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xxl-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-xxl-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-xxl-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-xxl-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-xxl-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xxl-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-xxl-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-xxl-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-xxl-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-15 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xxl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-xxl-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xxl-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-xxl-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-xxl-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xxl-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xxl-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-xxl-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-xxl-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-xxl-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-xxl-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xxl-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-xxl-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-xxl-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-xxl-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2rem !important;
  }
  .pt-xxl-10 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-15 {
    padding-top: 0.9375rem !important;
  }
  .pt-xxl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-30 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-40 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-50 {
    padding-top: 3.125rem !important;
  }
  .pt-xxl-60 {
    padding-top: 3.75rem !important;
  }
  .pt-xxl-70 {
    padding-top: 4.375rem !important;
  }
  .pt-xxl-80 {
    padding-top: 5rem !important;
  }
  .pt-xxl-90 {
    padding-top: 5.625rem !important;
  }
  .pt-xxl-100 {
    padding-top: 6.25rem !important;
  }
  .pt-xxl-120 {
    padding-top: 7.5rem !important;
  }
  .pt-xxl-130 {
    padding-top: 8.125rem !important;
  }
  .pt-xxl-140 {
    padding-top: 8.75rem !important;
  }
  .pt-xxl-150 {
    padding-top: 9.375rem !important;
  }
  .pt-xxl-180 {
    padding-top: 11.25rem !important;
  }
  .pt-xxl-200 {
    padding-top: 12.5rem !important;
  }
  .pt-xxl-220 {
    padding-top: 13.75rem !important;
  }
  .pt-xxl-230 {
    padding-top: 14.375rem !important;
  }
  .pt-xxl-250 {
    padding-top: 15.625rem !important;
  }
  .pt-xxl-300 {
    padding-top: 18.75rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2rem !important;
  }
  .pe-xxl-10 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-15 {
    padding-right: 0.9375rem !important;
  }
  .pe-xxl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-30 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-40 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-50 {
    padding-right: 3.125rem !important;
  }
  .pe-xxl-60 {
    padding-right: 3.75rem !important;
  }
  .pe-xxl-70 {
    padding-right: 4.375rem !important;
  }
  .pe-xxl-80 {
    padding-right: 5rem !important;
  }
  .pe-xxl-90 {
    padding-right: 5.625rem !important;
  }
  .pe-xxl-100 {
    padding-right: 6.25rem !important;
  }
  .pe-xxl-120 {
    padding-right: 7.5rem !important;
  }
  .pe-xxl-130 {
    padding-right: 8.125rem !important;
  }
  .pe-xxl-140 {
    padding-right: 8.75rem !important;
  }
  .pe-xxl-150 {
    padding-right: 9.375rem !important;
  }
  .pe-xxl-180 {
    padding-right: 11.25rem !important;
  }
  .pe-xxl-200 {
    padding-right: 12.5rem !important;
  }
  .pe-xxl-220 {
    padding-right: 13.75rem !important;
  }
  .pe-xxl-230 {
    padding-right: 14.375rem !important;
  }
  .pe-xxl-250 {
    padding-right: 15.625rem !important;
  }
  .pe-xxl-300 {
    padding-right: 18.75rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xxl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-xxl-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xxl-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-xxl-80 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-xxl-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xxl-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xxl-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-xxl-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-xxl-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-xxl-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-xxl-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xxl-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-xxl-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-xxl-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-xxl-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2rem !important;
  }
  .ps-xxl-10 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-15 {
    padding-left: 0.9375rem !important;
  }
  .ps-xxl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-30 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-40 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-50 {
    padding-left: 3.125rem !important;
  }
  .ps-xxl-60 {
    padding-left: 3.75rem !important;
  }
  .ps-xxl-70 {
    padding-left: 4.375rem !important;
  }
  .ps-xxl-80 {
    padding-left: 5rem !important;
  }
  .ps-xxl-90 {
    padding-left: 5.625rem !important;
  }
  .ps-xxl-100 {
    padding-left: 6.25rem !important;
  }
  .ps-xxl-120 {
    padding-left: 7.5rem !important;
  }
  .ps-xxl-130 {
    padding-left: 8.125rem !important;
  }
  .ps-xxl-140 {
    padding-left: 8.75rem !important;
  }
  .ps-xxl-150 {
    padding-left: 9.375rem !important;
  }
  .ps-xxl-180 {
    padding-left: 11.25rem !important;
  }
  .ps-xxl-200 {
    padding-left: 12.5rem !important;
  }
  .ps-xxl-220 {
    padding-left: 13.75rem !important;
  }
  .ps-xxl-230 {
    padding-left: 14.375rem !important;
  }
  .ps-xxl-250 {
    padding-left: 15.625rem !important;
  }
  .ps-xxl-300 {
    padding-left: 18.75rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2rem !important;
  }
  .gap-xxl-10 {
    gap: 0.625rem !important;
  }
  .gap-xxl-15 {
    gap: 0.9375rem !important;
  }
  .gap-xxl-20 {
    gap: 1.25rem !important;
  }
  .gap-xxl-30 {
    gap: 1.875rem !important;
  }
  .gap-xxl-40 {
    gap: 2.5rem !important;
  }
  .gap-xxl-50 {
    gap: 3.125rem !important;
  }
  .gap-xxl-60 {
    gap: 3.75rem !important;
  }
  .gap-xxl-70 {
    gap: 4.375rem !important;
  }
  .gap-xxl-80 {
    gap: 5rem !important;
  }
  .gap-xxl-90 {
    gap: 5.625rem !important;
  }
  .gap-xxl-100 {
    gap: 6.25rem !important;
  }
  .gap-xxl-120 {
    gap: 7.5rem !important;
  }
  .gap-xxl-130 {
    gap: 8.125rem !important;
  }
  .gap-xxl-140 {
    gap: 8.75rem !important;
  }
  .gap-xxl-150 {
    gap: 9.375rem !important;
  }
  .gap-xxl-180 {
    gap: 11.25rem !important;
  }
  .gap-xxl-200 {
    gap: 12.5rem !important;
  }
  .gap-xxl-220 {
    gap: 13.75rem !important;
  }
  .gap-xxl-230 {
    gap: 14.375rem !important;
  }
  .gap-xxl-250 {
    gap: 15.625rem !important;
  }
  .gap-xxl-300 {
    gap: 18.75rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-xxl-15 {
    row-gap: 0.9375rem !important;
  }
  .row-gap-xxl-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xxl-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-xxl-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xxl-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-xxl-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xxl-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-xxl-80 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-xxl-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xxl-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xxl-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-xxl-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xxl-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-xxl-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-xxl-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-xxl-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-xxl-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-xxl-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-xxl-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 2rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-xxl-15 {
    column-gap: 0.9375rem !important;
  }
  .column-gap-xxl-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xxl-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-xxl-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xxl-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-xxl-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xxl-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-xxl-80 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-xxl-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xxl-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xxl-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-xxl-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xxl-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-xxl-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-xxl-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-xxl-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-xxl-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-xxl-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-xxl-300 {
    column-gap: 18.75rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }
  .fs-2 {
    font-size: 1.875rem !important;
  }
  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

.faq-arrow {
  transform: rotate(45deg);
}

svg {
  fill: currentColor;
  transition: all 0.2s ease-in-out;
}

.next-icon, .previous-icon {
  stroke: currentColor;
}

a {
  transition: all 0.2s ease-in-out;
}

@media screen and (min-width: 1940px) {
  .max-width {
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
  }
}

.menu-list {
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
}

.text-opacity span {
  opacity: 0.15;
}

.custom-font-jakarta {
  font-family: var(--bs-font-family-style-4);
}

.section-title {
  position: relative;
  z-index: 9;
}

.section-title .title, .section-title .sub-title {
  font-family: var(--bs-font-family-style-4);
}

@media (max-width: 1399.98px) {
  .section-title .title, .section-title .sub-title {
    font-size: 3.3rem;
  }
}

@media (max-width: 575.98px) {
  .section-title .title, .section-title .sub-title {
    font-size: 2.8rem;
  }
}

.section-title.section-title-style-2 .title, .section-title.section-title-style-2 .sub-title {
  font-family: var(--bs-font-family-style-3);
}

.primary-text-shadow {
  -webkit-text-stroke: 1.5px var(--bs-primary);
  color: transparent !important;
}

.secondary-text-shadow {
  -webkit-text-stroke: 1.5px var(--bs-secondary);
  color: transparent !important;
}

.custom-inner-bg {
  --bs-custom-inner-bg: rgba(248,248,255, 0.8);
  --bs-custom-inner-border-color: rgba(255, 255, 255, 0.11);
  --bs-custom-inner-shadow-color: rgba(0, 0, 0, 0.39);
  border-radius: 30px;
  border: 2px solid var(--bs-custom-inner-border-color);
  background: var(--bs-custom-inner-bg);
  box-shadow: 0px 1px 14px -5px var(--bs-custom-inner-shadow-color);
  transition: all 0.2s ease-in-out;
}

.sticky-contents {
  position: sticky !important;
  top: 100px;
}

.scroll-light-text {
  color: var(--bs-heading-color);
}

.scroll {
  overflow: hidden;
  position: relative;
  height: 160px;
  display: flex;
  align-items: center;
}

.scroll div {
  display: block;
  width: 200%;
  position: absolute;
  overflow: hidden;
  animation: scroll-animation 30s linear infinite;
}

.scroll div .scroll-text {
  display: table-cell;
  width: 50%;
}

@media (max-width: 1399.98px) {
  .scroll {
    height: 130px;
  }
}

@media (max-width: 991.98px) {
  .scroll {
    height: 100px;
  }
}

@media (max-width: 767.98px) {
  .scroll {
    height: 80px;
  }
}

@media (max-width: 575.98px) {
  .scroll {
    height: 70px;
  }
  .scroll div {
    animation: scroll-animation 20s linear infinite;
  }
}

.marquee-elements {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  --marquee-width: 100vw;
  --marquee-elements-displayed: 2;
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
  --marquee-animation-duration: calc(var(--marquee-elements) * 20s);
  --marquee-element-value: -0.6;
}

.scroll-elements {
  display: flex;
  align-items: center;
  width: 100%;
  transform: translateX(0);
  animation: textScroll var(--marquee-animation-duration) linear infinite;
}

.scroll-items {
  display: inline-block;
  font-family: var(--bs-font-family-style-1);
}

@keyframes textScroll {
  0% {
    transform: translateX(calc(var(--marquee-element-value) * var(--marquee-element-width) * var(--marquee-elements)));
  }
  100% {
    transform: translateX(0);
  }
}

.breadcrumb {
  --bs-breadcrumb-divider-color: #fff;
  --bs-breadcrumb-item-active-color: #888492;
  --bs-breadcrumb-font-size: 18px;
  --bs-link-color-rgb: #fff;
}

.blog-breadcrumb .breadcrumb-item {
  font-weight: 600;
}

.blog-breadcrumb .breadcrumb-item a {
  color: var(--bs-link-color-rgb) !important;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.blog-breadcrumb .breadcrumb-item:hover a {
  color: var(--bs-primary) !important;
}

.dot-separator {
  position: absolute;
  top: calc(50% - 1px);
  right: -12px;
  width: 3px;
  height: 3px;
  background: var(--bs-primary);
  border-radius: 50%;
}

.bg-lg {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1399.98px) {
  .bg-lg {
    max-width: 94%;
  }
}

@media screen and (min-width: 1850px) {
  .bg-lg {
    max-width: 74.5%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1849px) {
  .bg-lg {
    max-width: 85%;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
  .bg-lg {
    max-width: 95%;
  }
}

:root {
  --bs-headings-color: #28303F;
  --bs-heading-color: #28303F;
  --bs-link-color: #28303F;
  --bs-heading-color-rgb: 40, 48, 63;
  --bs-link-color-rgb: 40, 48, 63;
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  --bs-link-hover-color: var(--bs-primary);
  --bs-font-family-style-1: 'Open Sans', sans-serif;
  --bs-font-family-style-2: 'Roboto', sans-serif;
  --bs-font-family-style-3: 'Poppins' , sans-serif;
  --bs-font-family-style-4: 'Plus Jakarta Sans' , sans-serif;
}

:root .leaflet-container a:hover {
  color: #080E19 !important;
  background-color: var(--bs-primary);
}

:root .leaflet-container a:focus {
  background-color: var(--bs-primary);
}

:root .section-title .sub-title {
  text-shadow: none;
}

:root .ticket-form-wrapper {
  --bs-border-color:rgba(255, 255, 255, 0.11);
}

:root .logo-light {
  display: none;
}

:root .logo-dark {
  display: block;
}

:root .ticket-1 {
  --bs-headings-color: #28303F;
}

:root .separator {
  --bs-separator-color: rgba(136, 132, 146, 0.20);
  border: 1px solid var(--bs-separator-color);
}

:root .ellipse-image-2 img, :root .ellipse-image-1 img {
  opacity: .2;
}

:root .highlight-section {
  --bs-headings-color: #28303F;
}

[data-bs-theme="dark"] {
  --bs-heading-color: #d2d2d2;
  --bs-link-color: #d2d2d2;
  --bs-heading-color-rgb: 210, 210, 210;
  --bs-link-color-rgb: 210, 210, 210;
  --bs-headings-color: #d2d2d2;
}

[data-bs-theme="dark"] .eventiva-landing {
  --bs-body-bg: #0A111F;
  --bs-secondary: #B20D5D;
  --bs-secondary-rgb: 178, 13, 93;
  --bs-primary: #4361EE;
  --bs-primary-rgb: 67, 97, 238;
  --bs-body-color: #B5B4BA;
  --bs-body-color-light: #8EA3CA;
}

[data-bs-theme="dark"] .highlights-item-3:hover {
  box-shadow: rgba(255, 255, 255, 0.18) 0px 2px 4px;
}

[data-bs-theme="dark"] .offcanvas-pages-dropdown, [data-bs-theme="dark"] .single-pages-dropdown {
  --bs-dropdown-shadow: rgba(255, 255, 255, 0.18) 0px 2px 4px;
}

[data-bs-theme="dark"] .blog {
  --bs-body-bg: #080E19;
}

[data-bs-theme="dark"] .map-image.map-image-2 .marker-content .map-popup-content {
  border: 2px solid rgba(8, 14, 25, 0.49);
  background: rgba(8, 14, 25, 0.21);
}

[data-bs-theme="dark"] .offcanvas-light {
  background-color: var(--bs-dark);
}

[data-bs-theme="dark"] .contact-1 {
  --bs-contact-1-bg:#0B0711;
}

[data-bs-theme="dark"] .custom-heading-color-1 {
  --bs-heading-color: #E5E5E5;
}

[data-bs-theme="dark"] .custom-heading-color-2 {
  --bs-heading-color: #838490 !important;
}

[data-bs-theme="dark"] .contact-lists {
  --bs-link-color: #838490;
  --bs-link-color-rgb: 131, 132, 144;
}

[data-bs-theme="dark"] .countdown-label {
  --bs-countdown-text-stroke-color: #000;
}

[data-bs-theme="dark"] .map-image {
  --bg-parallax-image: url(../images/map-tile-dark.png);
}

[data-bs-theme="dark"] .separator {
  --bs-separator-color: rgba(136, 132, 146, 0.20);
}

[data-bs-theme="dark"] .features-landing {
  --bs-features-heading-color: #E5E5E5;
}

[data-bs-theme="dark"] .landing-inner-bg {
  --bs-landing-inner-bg: #151E31;
}

[data-bs-theme="dark"] .pricing-landing-wrapper {
  --bs-pricing-text-color: #CECCDA;
}

[data-bs-theme="dark"] .header-3.transparent-header .shop-icon-xl, [data-bs-theme="dark"] .header-3.transparent-header .offcanvas-menu-icon {
  color: #fff;
}

[data-bs-theme="dark"] .header-3.transparent-header .logo-custom-light {
  display: block;
}

[data-bs-theme="dark"] .header-3.transparent-header .logo-custom-dark {
  display: none;
}

[data-bs-theme="dark"] .header-3.sticky {
  background-color: var(--bs-dark);
  box-shadow: rgba(255, 255, 255, 0.13) 0px 2px 4px;
}

[data-bs-theme="dark"] .header-3.sticky.transparent-header .shop-icon-xl, [data-bs-theme="dark"] .header-3.sticky.transparent-header .offcanvas-menu-icon {
  color: #fff;
}

[data-bs-theme="dark"] .header-3.sticky.transparent-header .logo-custom-light {
  display: block;
}

[data-bs-theme="dark"] .header-3.sticky.transparent-header .logo-custom-dark {
  display: none;
}

[data-bs-theme="dark"] .header-3.sticky.transparent-header .btn {
  --bs-btn-hover-color: #fff !important;
}

[data-bs-theme="dark"] .header-3.sticky.transparent-header .btn:hover svg {
  fill: #fff !important;
}

[data-bs-theme="dark"] .loading-wrapper {
  --bs-loading-text-color: #CECCDA;
}

[data-bs-theme="dark"] .logo-dark {
  display: none;
}

[data-bs-theme="dark"] .logo-light {
  display: block;
}

[data-bs-theme="dark"] .bg-ticket {
  --bs-ticket-bg: #0C0614;
  --bs-pricing-border-color: #1E1728 ;
}

[data-bs-theme="dark"] .bg-ticket.bg-ticket-style-2 {
  --bs-ticket-bg: #0C1527;
  --bs-pricing-border-color: #162646 ;
}

[data-bs-theme="dark"] .demo-title {
  --bs-demo-title-color: #CECCDA;
}

[data-bs-theme="dark"] .loading-progress {
  --progress-bg: #151E31;
}

[data-bs-theme="dark"] .subscription-form {
  --bs-subscription-border-color: rgba(255, 255, 255, 0.1);
  --bs-subscription-placeholder-color: rgba(255, 255, 255, 0.25);
}

[data-bs-theme="dark"] .ticket-arrow {
  --bs-circle-fill-color: rgba(255, 255, 255, 0.19);
}

[data-bs-theme="dark"] .ticket-arrow.arrow-style-2 {
  --bs-circle-fill-color: rgba(255, 255, 255, 0.19);
}

[data-bs-theme="dark"] .highlight-section {
  --bs-highlights-bg: #0C0614;
}

[data-bs-theme="dark"] .author-wrapper {
  --bs-author-wrapper-bg: rgba(255, 255, 255, 0.19);
}

[data-bs-theme="dark"] .header-landing.sticky {
  background-color: #151E31;
}

[data-bs-theme="dark"] .header-landing .navbar {
  --bs-navbar-color:#B5B4BA;
}

[data-bs-theme="dark"] .header-4 {
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 0px 10px 3px rgba(255, 255, 255, 0.1);
}

[data-bs-theme="dark"] .header-2 {
  --bs-header-2-border-bottom: rgba(104, 102, 102, 0.30);
}

[data-bs-theme="dark"] .social-icons-list {
  background-color: #000;
}

[data-bs-theme="dark"] .social-icons-list.social-icons-toggle {
  background-color: #000;
}

[data-bs-theme="dark"] .subscription-2 {
  --bs-subscription-2-bg: rgba(0, 0, 0, 0.20);
}

[data-bs-theme="dark"] .hero-7 {
  --bs-hero-7-border-color: rgba(255, 255, 255, .08);
}

[data-bs-theme="dark"] .hero-7 .hero-wrapper {
  --bg-parallax-opacity: .15;
}

[data-bs-theme="dark"] .about-3-bg {
  --bs-about-3-bg: #182235;
}

[data-bs-theme="dark"] .custom-inner-bg {
  --bs-custom-inner-bg: rgba(38, 31, 48, 0.22);
  box-shadow: 8px 5px 21px 18px var(--bs-custom-inner-shadow-color);
}

[data-bs-theme="dark"] .schedule-style-3 .schedule-tabs.custom-inner-bg {
  --bs-custom-inner-bg: rgba(37, 63, 116, 0.15);
}

[data-bs-theme="dark"] .faq-wrapper {
  --bs-custom-inner-bg: rgba(38, 31, 48, 0.22);
}

[data-bs-theme="dark"] .schedule-1 {
  --bs-scheduletabs-active-bg: #04000A;
}

[data-bs-theme="dark"] .award-2 {
  --bs-custom-inner-bg: #0C0614;
}

[data-bs-theme="dark"] .blog-forms, [data-bs-theme="dark"] .contact-us-form {
  --bs-border-color: rgba(var(--bs-white-rgb),.3);
  --blog-froms-bg: transparent;
}

[data-bs-theme="dark"] .blog-inner-content, [data-bs-theme="dark"] .blog-swiper-wrapper {
  --bs-border-color: rgba(var(--bs-white-rgb),.3);
}

[data-bs-theme="dark"] .leaflet-container a {
  color: #080E19 !important;
}

[data-bs-theme="dark"] .leaflet-container a:hover {
  color: #fff !important;
  background-color: var(--bs-primary);
}

[data-bs-theme="dark"] .leaflet-container a:focus {
  background-color: var(--bs-primary);
}

[data-bs-theme="dark"] .map-popup-content {
  border: 2px solid rgba(255, 255, 255, 0.11);
  background: rgba(38, 31, 48, 0.22);
}

[data-bs-theme="dark"] .primary-text-shadow {
  filter: drop-shadow(10px 9px 46px rgba(var(--bs-primary-rgb), 0.6)) drop-shadow(0 11px 15px rgba(var(--bs-primary-rgb), 0.2)) drop-shadow(0 24px 38px rgba(var(--bs-primary-rgb), 0.2)) drop-shadow(0 -3px 5px rgba(var(--bs-primary-rgb), 0.2));
}

[data-bs-theme="dark"] .merchandise-1 {
  --bs-merchandise-1-bg: rgba(0, 0, 0, 0.20);
  --bs-merchandise-border: rgba(104, 102, 102, 0.30);
  --bs-merchandise-wrapper-bg: #0A111F;
}

[data-bs-theme="dark"] .schedule-style-3 {
  --bs-scheduletabs-active-bg: #0A111F;
  --bs-custom-inner-bg: rgba(37, 63, 116, 0.15);
}

[data-bs-theme="dark"] .btn-custom-dark {
  --bs-btn-dark-color: #fff;
}

[data-bs-theme="dark"] .btn-primary.btn-custom-dark {
  --bs-btn-dark-color: #000;
  --bs-btn-custom-hover-color: #fff;
}

[data-bs-theme="dark"] .home-6 {
  --bs-heading-color-rgb: 229, 229, 229;
  --bs-heading-color: #E5E5E5;
  --bs-link-color: #E5E5E5;
  --bs-link-color-rgb: 229, 229, 229;
  --bs-circle-fill-color:  rgba(0, 0, 0, 0.17);
  --bs-btns-color: #E5E5E5;
  --bs-btns-hover-color: #E5E5E5;
  --bs-svg-color: #000;
  --bs-body-bg: #0A111F;
  background-color: var(--bs-body-bg);
  --bs-body-color: #838490;
}

@media (max-width: 991.98px) {
  .shop-icon-xl svg {
    width: 30px;
    height: 30px;
  }
}

.custom-navbar-nav .nav-link {
  font-size: 120px;
  font-weight: 800;
  font-family: var(--bs-font-family-style-4);
  line-height: 1.02;
}

.custom-navbar-nav .nav-link.active, .custom-navbar-nav .nav-link:hover {
  color: var(--bs-primary);
}

@media (max-width: 1399.98px) {
  .custom-navbar-nav .nav-link {
    font-size: 78px;
  }
}

@media (max-width: 991.98px) {
  .custom-navbar-nav .nav-link {
    font-size: 65px;
  }
}

@media (max-width: 767.98px) {
  .custom-navbar-nav .nav-link {
    font-size: 34px;
  }
}

.musicfest-image {
  width: auto;
  height: auto;
}

@media (max-width: 991.98px) {
  .musicfest-image {
    width: 300px;
  }
}

@media (max-width: 767.98px) {
  .musicfest-image {
    width: 220px;
  }
}

@media (min-width: 1200px) and (max-width: 1590px) {
  .musicfest-image {
    max-width: 400px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .musicfest-image {
    max-width: 380px;
  }
}

.download-link {
  text-decoration: underline;
  text-underline-offset: 10px;
  transition: all 0.2s ease-in-out;
}

.download-link span {
  color: var(--bs-heading-color);
  transition: transform .4s ease-in-out;
}

.download-link:hover {
  color: var(--bs-primary);
}

.download-link:hover span {
  color: var(--bs-primary);
}

.download-link:hover span svg {
  transform: rotate(45deg);
}

.download-link:hover .arrow-down-right svg {
  transform: rotate(-45deg);
}

.calendar {
  position: relative;
  bottom: 2px;
}

.radio-checked-bg {
  --bs-headings-color: #d2d2d2;
  --bs-table-color: var(--bs-headings-color);
  --bs-table-bg: linear-gradient(276deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  --bs-table-border-color: linear-gradient(276deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: linear-gradient(276deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
}

.ticket-arrow {
  --bs-circle-fill-color: rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: var(--bs-circle-fill-color);
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.ticket-arrow.arrow-down-right svg, .ticket-arrow.arrow-up-right svg {
  transition: transform 0.3s ease-in-out;
}

.ticket-arrow:hover {
  color: var(--bs-heading-color);
}

.ticket-arrow:hover.arrow-down-right svg {
  transform: rotate(-45deg);
}

.ticket-arrow:hover.arrow-up-right svg {
  transform: rotate(45deg);
}

.ticket-arrow.arrow-style-2 {
  --bs-circle-fill-color: #f6f6f7;
  width: 78px;
  height: 78px;
  color: var(--bs-primary);
}

.straight-line-wrapper {
  padding-left: 50px;
  transition: all 0.4s ease-in-out;
  font-family: var(--bs-font-family-style-4);
  color: var(--bs-heading-color);
}

.straight-line-wrapper .straight-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 34px;
  height: 2px;
  z-index: 50;
  background: var(--bs-primary);
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: all 0.4s ease-in-out;
}

.straight-line-wrapper:hover {
  padding-left: 68px;
}

.straight-line-wrapper:hover .straight-line {
  transform: scaleX(1.5);
  transform-origin: bottom left;
}

@media (max-width: 575.98px) {
  .straight-line-wrapper.fs-3 {
    font-size: 20px;
  }
}

.arrow-up-short {
  transform: rotate(45deg);
  position: relative;
  top: -5px;
  transition: all 0.2s ease-in-out;
}

.arrow-up-short svg {
  fill: currentColor;
}

.gradient-btn-arrow {
  transform: rotate(0deg);
}

.circle-wrapper {
  --bs-circle-color: #d9d9d9;
  --bs-circle-bg: rgba(217, 217, 217, 0.10);
  position: absolute;
  width: 168px;
  height: 168px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .circle-wrapper {
    top: -52px;
  }
}

.circle-wrapper .circle-image {
  position: absolute;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23d2d2d2' stroke-width='2' stroke-dasharray='15%25%2c 13%25' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 50%;
  background-color: var(--bs-circle-bg);
}

.circle-wrapper .circle-bg {
  position: absolute;
  width: 168px;
  height: 168px;
  background-color: var(--bs-circle-bg);
  border-radius: 50%;
}

.circle-wrapper .rotate-text, .circle-wrapper .rotate-text2, .circle-wrapper .rotate-text3 {
  --bs-rotate-text-color: var(--bs-heading-color);
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotateText 15s linear infinite;
}

.circle-wrapper .rotate-text p, .circle-wrapper .rotate-text2 p, .circle-wrapper .rotate-text3 p {
  line-height: 3;
  color: var(--bs-rotate-text-color);
}

.circle-wrapper .rotate-text span, .circle-wrapper .rotate-text2 span, .circle-wrapper .rotate-text3 span {
  position: absolute;
  left: 50%;
  font-size: 11px;
  transform-origin: 0 85px;
}

.circle-wrapper::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  background: var(--bs-circle-color);
  border-radius: 50%;
  z-index: 999;
}

.circle-wrapper.hero-circle {
  top: 0;
  right: 0;
  margin-top: 1rem;
  backdrop-filter: blur(13px);
}

@media (max-width: 1399.98px) {
  .circle-wrapper.hero-circle {
    margin-top: -2rem;
    top: 0;
    right: 0;
  }
}

@media (max-width: 1199.98px) {
  .circle-wrapper.hero-circle {
    margin-top: -3rem;
    margin-right: -1rem;
    top: 0;
    right: 0;
  }
}

@media (max-width: 991.98px) {
  .circle-wrapper.hero-circle {
    margin-top: -185px;
    right: auto;
    left: 0;
  }
}

@media (max-width: 767.98px) {
  .circle-wrapper.hero-circle {
    margin-top: -28px;
    right: 6px;
    left: auto;
    width: 100px;
    height: 100px;
  }
  .circle-wrapper.hero-circle .circle-image {
    width: 100px;
    height: 100px;
  }
  .circle-wrapper.hero-circle .circle-bg {
    width: 100px;
    height: 100px;
  }
  .circle-wrapper.hero-circle .rotate-text p {
    line-height: 2;
  }
  .circle-wrapper.hero-circle .rotate-text span {
    font-size: 7px;
    transform-origin: 0 50px;
  }
}

.circle-wrapper.hero-2-circle {
  --bs-circle-wrapper-border-color: rgba(255, 255, 255, .20);
  bottom: 0;
  right: 0;
  margin-right: -302px;
  margin-bottom: 138px;
  width: 238px;
  height: 238px;
}

.circle-wrapper.hero-2-circle .circle-bg {
  display: none;
}

.circle-wrapper.hero-2-circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: 0;
  border-style: solid;
  border-width: 44px;
  border-color: var(--bs-circle-wrapper-border-color);
  background-color: transparent;
}

.circle-wrapper.hero-2-circle .star-icon {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-wrapper.hero-2-circle .rotate-text, .circle-wrapper.hero-2-circle .rotate-text2, .circle-wrapper.hero-2-circle .rotate-text3 {
  --bs-rotate-text-color: var(--bs-white);
}

.circle-wrapper.hero-2-circle .rotate-text p, .circle-wrapper.hero-2-circle .rotate-text2 p, .circle-wrapper.hero-2-circle .rotate-text3 p {
  line-height: 2.5;
}

.circle-wrapper.hero-2-circle .rotate-text span, .circle-wrapper.hero-2-circle .rotate-text2 span, .circle-wrapper.hero-2-circle .rotate-text3 span {
  font-size: 16px;
  transform-origin: 0 119px;
}

@media (max-width: 991.98px) {
  .circle-wrapper.hero-2-circle {
    margin-right: -28px;
    margin-bottom: 100px;
    width: 130px;
    height: 130px;
  }
  .circle-wrapper.hero-2-circle::before {
    border-width: 25px !important;
  }
  .circle-wrapper.hero-2-circle .rotate-text p {
    line-height: 2.7;
  }
  .circle-wrapper.hero-2-circle .rotate-text span {
    font-size: 7px;
    transform-origin: 0 65px;
  }
}

@media (max-width: 767.98px) {
  .circle-wrapper.hero-2-circle {
    margin-top: -72px;
    right: auto;
    left: 0;
    width: 100px;
    height: 100px;
  }
  .circle-wrapper.hero-2-circle::before {
    border-width: 20px !important;
  }
  .circle-wrapper.hero-2-circle .rotate-text p {
    line-height: 2.7;
  }
  .circle-wrapper.hero-2-circle .rotate-text span {
    font-size: 7px;
    transform-origin: 0 50px;
  }
}

.circle-wrapper.hero-3-circle {
  --bs-circle-wrapper-border-color: rgba(81, 110, 145, .9);
  bottom: 0;
  right: 0;
  margin-right: -80px;
  margin-bottom: 2px;
  z-index: 1;
  width: 238px;
  height: 238px;
}

.circle-wrapper.hero-3-circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: 0;
  border-style: solid;
  border-width: 44px;
  border-color: var(--bs-circle-wrapper-border-color);
  background-color: transparent;
}

.circle-wrapper.hero-3-circle .star-icon {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-wrapper.hero-3-circle .circle-bg {
  display: none;
}

.circle-wrapper.hero-3-circle .rotate-text p, .circle-wrapper.hero-3-circle .rotate-text2 p, .circle-wrapper.hero-3-circle .rotate-text3 p {
  line-height: 3;
}

.circle-wrapper.hero-3-circle .rotate-text span, .circle-wrapper.hero-3-circle .rotate-text2 span, .circle-wrapper.hero-3-circle .rotate-text3 span {
  transform-origin: 0 119px;
  font-size: 15px;
  font-weight: 600;
}

.circle-wrapper.hero-3-circle .rotate-text p {
  color: #fff;
}

@media (max-width: 991.98px) {
  .circle-wrapper.hero-3-circle {
    width: 140px;
    height: 140px;
    margin-right: 23%;
    margin-bottom: -5px;
  }
  .circle-wrapper.hero-3-circle::before {
    content: "";
    border-width: 26px;
  }
  .circle-wrapper.hero-3-circle .rotate-text p, .circle-wrapper.hero-3-circle .rotate-text2 p, .circle-wrapper.hero-3-circle .rotate-text3 p {
    line-height: 2.4;
  }
  .circle-wrapper.hero-3-circle .rotate-text span, .circle-wrapper.hero-3-circle .rotate-text2 span, .circle-wrapper.hero-3-circle .rotate-text3 span {
    transform-origin: 0 70px;
    font-size: 10px;
  }
}

@media (max-width: 767.98px) {
  .circle-wrapper.hero-3-circle {
    margin-top: -120px;
    top: 0;
    right: auto;
    left: 0;
    width: 100px;
    height: 100px;
  }
  .circle-wrapper.hero-3-circle::before {
    border-width: 20px;
  }
  .circle-wrapper.hero-3-circle .circle-bg {
    width: 100px;
    height: 100px;
  }
  .circle-wrapper.hero-3-circle .rotate-text p {
    line-height: 2.5;
  }
  .circle-wrapper.hero-3-circle .rotate-text span {
    font-size: 7px;
    transform-origin: 0 50px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1560px) {
  .circle-wrapper.hero-2-circle {
    width: 210px;
    height: 210px;
    margin-right: -180px;
    margin-bottom: 95px;
  }
  .circle-wrapper.hero-2-circle::before {
    content: "";
    border-width: 40px;
  }
  .circle-wrapper.hero-2-circle .rotate-text p, .circle-wrapper.hero-2-circle .rotate-text2 p, .circle-wrapper.hero-2-circle .rotate-text3 p {
    line-height: 3;
  }
  .circle-wrapper.hero-2-circle .rotate-text span, .circle-wrapper.hero-2-circle .rotate-text2 span, .circle-wrapper.hero-2-circle .rotate-text3 span {
    transform-origin: 0 105px;
    font-size: 13px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .circle-wrapper.hero-2-circle {
    width: 200px;
    height: 200px;
    margin-right: -130px;
    margin-bottom: 100px;
  }
  .circle-wrapper.hero-2-circle::before {
    content: "";
    border-width: 38px;
  }
  .circle-wrapper.hero-2-circle .rotate-text p, .circle-wrapper.hero-2-circle .rotate-text2 p, .circle-wrapper.hero-2-circle .rotate-text3 p {
    line-height: 3;
  }
  .circle-wrapper.hero-2-circle .rotate-text span, .circle-wrapper.hero-2-circle .rotate-text2 span, .circle-wrapper.hero-2-circle .rotate-text3 span {
    transform-origin: 0 100px;
    font-size: 13px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .circle-wrapper.hero-2-circle {
    width: 180px;
    height: 180px;
    margin-right: -18px;
    margin-bottom: 60px;
  }
  .circle-wrapper.hero-2-circle::before {
    content: "";
    border-width: 35px;
  }
  .circle-wrapper.hero-2-circle .rotate-text p, .circle-wrapper.hero-2-circle .rotate-text2 p, .circle-wrapper.hero-2-circle .rotate-text3 p {
    line-height: 2.7;
  }
  .circle-wrapper.hero-2-circle .rotate-text span, .circle-wrapper.hero-2-circle .rotate-text2 span, .circle-wrapper.hero-2-circle .rotate-text3 span {
    transform-origin: 0 90px;
    font-size: 13px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1590px) {
  .circle-wrapper.hero-3-circle {
    width: 210px;
    height: 210px;
    margin-right: 130px;
  }
  .circle-wrapper.hero-3-circle::before {
    content: "";
    border-width: 38px;
  }
  .circle-wrapper.hero-3-circle .rotate-text p, .circle-wrapper.hero-3-circle .rotate-text2 p, .circle-wrapper.hero-3-circle .rotate-text3 p {
    line-height: 3;
  }
  .circle-wrapper.hero-3-circle .rotate-text span, .circle-wrapper.hero-3-circle .rotate-text2 span, .circle-wrapper.hero-3-circle .rotate-text3 span {
    transform-origin: 0 105px;
    font-size: 13px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .circle-wrapper.hero-3-circle {
    width: 180px;
    height: 180px;
    margin-right: 190px;
  }
  .circle-wrapper.hero-3-circle::before {
    content: "";
    border-width: 34px;
  }
  .circle-wrapper.hero-3-circle .rotate-text p, .circle-wrapper.hero-3-circle .rotate-text2 p, .circle-wrapper.hero-3-circle .rotate-text3 p {
    line-height: 2.7;
  }
  .circle-wrapper.hero-3-circle .rotate-text span, .circle-wrapper.hero-3-circle .rotate-text2 span, .circle-wrapper.hero-3-circle .rotate-text3 span {
    transform-origin: 0 90px;
    font-size: 12px;
  }
}

@media (max-width: 991.98px) {
  .star-icon svg {
    width: 40px;
  }
}

@media (max-width: 767.98px) {
  .star-icon svg {
    width: 30px;
  }
}

.video-popup {
  top: calc(50% - 90px);
}

.video-popup .circle-wrapper {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  top: auto;
  backdrop-filter: blur(13px);
}

.video-popup .circle-wrapper::before {
  content: none;
}

.video-popup .circle-wrapper .inner-circle {
  position: absolute;
  width: 62px;
  height: 62px;
  top: calc(50% - 31px);
  left: calc(50% - 31px);
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.video-popup .circle-wrapper .video-icon {
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.video-popup .circle-wrapper .video-icon svg {
  width: 45px;
  height: 45px;
  margin-left: 7px;
}

.video-popup.video-popup-left {
  left: -90px;
}

@media (max-width: 991.98px) {
  .video-popup.video-popup-left {
    left: calc(50% - 90px);
  }
}

.video-popup.video-popup-center {
  left: calc(50% - 90px);
}

.navbar-toggler-icon:focus {
  box-shadow: none;
}

.parallax {
  background-image: var(--bg-cover-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.parallax:after {
  content: "";
  background-image: var(--bg-parallax-image);
  opacity: var(--bg-parallax-opacity, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

.parallax > * {
  position: relative;
  z-index: 1;
}

.bg-mask {
  mask-image: var(--bg-mask-image);
  mask-repeat: no-repeat;
  mask-size: cover;
}

.text-gradient {
  background: linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px var(--bs-headings-color);
}

.text-gradient.gradient-reverse {
  background: linear-gradient(277deg, var(--bs-primary) 4.11%, var(--bs-secondary) 91.07%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px var(--bs-headings-color);
}

.text-gradient.no-stroke {
  -webkit-text-stroke: 0;
}

.text-gradient-stroke {
  background: linear-gradient(96deg, var(--bs-primary) 20%, var(--bs-secondary) 100%);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 8px transparent;
}

.gradient-border {
  z-index: 3;
  background: linear-gradient(3deg, var(--bs-primary) 10%, var(--bs-secondary) 50%);
  background: -webkit-linear-gradient(3deg, var(--bs-primary) 10%, var(--bs-secondary) 50%);
  -webkit-text-stroke: 24px rgba(255, 255, 255, 0.01);
  -webkit-background-clip: text;
  background-clip: text;
  padding-left: 15px;
}

@media (max-width: 1399.98px) {
  .gradient-border {
    -webkit-text-stroke: 20px rgba(255, 255, 255, 0.01);
    padding-left: 14px;
  }
}

@media (max-width: 1199.98px) {
  .gradient-border {
    -webkit-text-stroke: 28px rgba(255, 255, 255, 0.01);
    padding-left: 13px;
  }
}

@media (max-width: 991.98px) {
  .gradient-border {
    -webkit-text-stroke: 13px rgba(255, 255, 255, 0.01);
    padding-left: 8px;
  }
}

@media (max-width: 575.98px) {
  .gradient-border {
    -webkit-text-stroke: 10px rgba(255, 255, 255, 0.01);
    padding-left: 7px;
  }
}

.hero-inner-text {
  max-width: 1024px;
}

.soundscapes {
  --bs-soundscapes-color: #A29A9D;
  font-weight: 800;
  background: -webkit-linear-gradient(10deg, var(--bs-primary) 40%, var(--bs-secondary) 60%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 7px rgba(255, 255, 255, 0.01);
  color: var(--bs-soundscapes-color) !important;
  letter-spacing: -1.2px;
  min-height: 265px;
  margin-top: 18px;
  line-height: .94;
}

@media (max-width: 991.98px) {
  .soundscapes {
    min-height: 114px;
    margin-top: 8px;
  }
}

@media (max-width: 575.98px) {
  .soundscapes {
    min-height: 86px;
    margin-top: 5px;
  }
}

@media (max-width: 767.98px) {
  .soundscapes {
    -webkit-text-stroke: 4px rgba(255, 255, 255, 0.01);
  }
}

.soundscapes.no-stroke {
  -webkit-text-stroke: 0;
  color: var(--bs-white) !important;
  min-height: 255px;
  margin-top: 18px;
}

@media (max-width: 1399.98px) {
  .soundscapes.no-stroke {
    min-height: 218px;
  }
}

@media (max-width: 1199.98px) {
  .soundscapes.no-stroke {
    min-height: 172px;
    margin-top: 8px;
  }
}

@media (max-width: 991.98px) {
  .soundscapes.no-stroke {
    min-height: 108px;
    margin-top: 0;
  }
}

@media (max-width: 575.98px) {
  .soundscapes.no-stroke {
    min-height: 78px;
    margin-top: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1560px) {
  .soundscapes.fs-120 {
    font-size: 80px;
    min-height: 180px;
  }
  .soundscapes.fs-120.no-stroke {
    font-size: 74px;
    min-height: 158px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .soundscapes.fs-120 {
    font-size: 70px;
    min-height: 150px;
    letter-spacing: 0.8px;
  }
}

@media (max-width: 991.98px) {
  .brand-logo img {
    width: 160px;
    height: 30px;
  }
}

.footer-nav a {
  color: var(--bs-body-color) !important;
}

.footer-nav a:hover {
  color: var(--bs-primary) !important;
}

.event-info {
  --bs-border-color:#525151;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  backdrop-filter: blur(25px);
  margin-top: 40px;
}

.event-info h4, .event-info .h4 {
  margin-bottom: 0;
}

.event-info h2, .event-info .h2, .event-info h4, .event-info .h4, .event-info .event-odometer-heading {
  line-height: .9;
}

.event-info.custom-inner-bg {
  --bs-custom-inner-bg: rgb(255, 255, 255, .1)
    ;
}

.event-info.event-3-info {
  z-index: 9;
  position: relative;
  margin-top: -90px;
}

@media (max-width: 991.98px) {
  .event-info.event-3-info {
    margin-top: 0;
    top: -130px;
  }
}

@media (max-width: 991.98px) {
  .event-info {
    margin-top: 30px;
  }
}

@media (max-width: 767.98px) {
  .event-info {
    margin-top: 200px;
  }
}

.event-info .event-inner {
  padding: 25px 50px;
}

.event-info .event-inner h2, .event-info .event-inner .h2 {
  font-weight: 800;
}

@media (max-width: 991.98px) {
  .event-info .event-inner {
    padding: 30px;
  }
  .event-info .event-inner h4, .event-info .event-inner .h4 {
    font-size: 17px;
  }
}

.event-info .event-content {
  border-right: 1px solid var(--bs-border-color);
}

@media (min-width: 992px) {
  .event-info .event-content {
    padding: 24px 0;
  }
}

@media (max-width: 991.98px) {
  .event-info .event-content {
    border-right: 0;
  }
}

.event-info.event-info-outside {
  margin-top: 0;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -78px;
  margin-left: calc(50% - 705px);
  z-index: 3;
}

@media (max-width: 1199.98px) {
  .event-info.event-info-outside {
    bottom: -120px;
  }
}

@media (max-width: 991.98px) {
  .event-info.event-info-outside {
    bottom: -106px;
  }
}

@media (max-width: 767.98px) {
  .event-info.event-info-outside.event-down-sm {
    bottom: -250px;
  }
}

@media screen and (max-width: 365px) {
  .event-info {
    max-width: 320px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 160px);
  }
}

@media screen and (min-width: 366px) and (max-width: 429px) {
  .event-info {
    max-width: 340px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 170px);
  }
}

@media screen and (min-width: 430px) and (max-width: 575px) {
  .event-info {
    max-width: 380px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 190px);
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .event-info {
    max-width: 540px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 270px);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .event-info {
    max-width: 700px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 350px);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .event-info {
    max-width: 974px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 487px);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .event-info {
    max-width: 1118px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 559px);
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .event-info {
    max-width: 1250px;
  }
  .event-info .event-inner {
    margin-left: -30px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 625px);
  }
}

@media screen and (min-width: 1501px) and (max-width: 1600px) {
  .event-info {
    max-width: 1340px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 670px);
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .event-info {
    max-width: 1380px;
  }
  .event-info.event-info-outside {
    margin-left: calc(50% - 690px);
  }
}

@media screen and (min-width: 1701px) {
  .event-info {
    max-width: 1410px;
  }
}

.buttons-logo.icon-style-3 svg, .contact-plus-icon.icon-style-3 svg {
  margin-bottom: 3px;
}

.star-icon-big, .star-icon-small {
  color: var(--bs-primary);
}

.author-wrapper {
  --bs-author-wrapper-bg: #f6f6f7;
  border-radius: 75px;
  background-color: var(--bs-author-wrapper-bg);
  width: 400px;
}

.author-wrapper .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #fff;
  width: 60px;
  height: 60px;
  background-color: #1E1728;
}

.author-wrapper .hero-author-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-width: 165px;
}

@media (max-width: 575.98px) {
  .author-wrapper .hero-author-title {
    font-size: 18px !important;
    max-width: 110px;
  }
}

@media (max-width: 575.98px) {
  .author-wrapper {
    width: 328px;
  }
}

.commander-wrapper h2, .commander-wrapper .h2 {
  transition: all 0.3s ease-in-out;
}

.commander-wrapper .commander-image {
  border-radius: 30px;
  overflow: hidden;
  max-width: 305px;
  max-height: 350px;
}

.commander-wrapper .commander-image img {
  transition: all 0.3s ease-in-out;
}

@media (max-width: 575.98px) {
  .commander-wrapper .commander-image img {
    width: 100%;
  }
}

.commander-wrapper:hover .commander-image img {
  box-shadow: rgba(255, 255, 255, 0.18) 0px 2px 4px;
  transform: scale(1.04);
}

.commander-wrapper:hover h2, .commander-wrapper:hover .h2 {
  color: var(--bs-primary);
}

.star-icons {
  top: 13%;
  margin-right: -38%;
}

@media (max-width: 1199.98px) {
  .star-icons {
    margin-right: -40%;
  }
}

@media (max-width: 1199.98px) {
  .star-icons {
    margin-right: -48%;
  }
}

@media (max-width: 991.98px) {
  .star-icons {
    margin-right: -15%;
  }
}

@media (max-width: 767.98px) {
  .star-icons {
    margin-right: -34%;
  }
}

@media (max-width: 575.98px) {
  .star-icons {
    top: 3%;
    margin-right: -45%;
  }
}

.star-icons .star-icon-small {
  position: relative;
  left: -5px;
  -webkit-animation: anti-clock-rotation 10s infinite linear;
  animation: anti-clock-rotation 10s infinite linear;
  display: block;
}

.star-icons .star-icon-big {
  -webkit-animation: clock-rotation 10s infinite linear;
  animation: clock-rotation 10s infinite linear;
  display: block;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .star-icon-big svg {
    width: 60px;
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  background-color: #000;
  transition: all 0.5s ease-in-out;
}

.loaded .preloader {
  visibility: hidden;
  opacity: 0;
}

.color-swatch label {
  cursor: pointer;
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-dark);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

.offcanvas.offcanvas-top {
  --bs-offcanvas-width: 100%;
  --bs-offcanvas-height: 100vh;
  --bs-offcanvas-padding-x: 2.7rem;
  --bs-offcanvas-padding-y: 1.8rem;
  --bs-offcanvas-transition: transform 0.5s ease-in-out;
}

@media (max-width: 991.98px) {
  .offcanvas.offcanvas-top {
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
  }
}

.offcanvas.offcanvasShop {
  background-color: var(--bs-body-bg);
}

.offcanvas-light {
  background-color: var(--bs-white);
}

.offcanvas-light .btn-custom-dark:hover {
  color: var(--bs-btn-dark-color);
}

.offcanvas-light .btn-custom-dark:hover svg {
  fill: var(--bs-btn-dark-color);
}

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-white);
}

.arrow-up-right-big {
  transition: all 0.2s ease-in-out;
  color: var(--bs-heading-color);
  stroke: var(--bs-heading-color);
}

.arrow-up-right-big.arrow-secondary {
  color: var(--bs-secondary);
  stroke: var(--bs-secondary);
}

.arrow-up-right-big:hover {
  color: var(--bs-primary);
  stroke: var(--bs-primary);
}

@media (max-width: 575.98px) {
  .arrow-up-right-big svg {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 991.98px) {
  .arrow-up-right-big svg {
    width: 100px;
    height: 100px;
  }
}

.spotify-logo svg, .shazam-logo svg, .rackspace-logo svg {
  fill: var(--bs-headings-color);
}

@media (max-width: 767.98px) {
  .spotify-logo svg, .shazam-logo svg, .rackspace-logo svg {
    width: 100px;
  }
}

.direction-details {
  max-width: 240px;
}

@media (max-width: 991.98px) {
  .direction-details {
    max-width: 100%;
  }
}

.single-pages-dropdown {
  --bs-dropdown-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.single-pages-dropdown .dropdown-toggle::after {
  margin-left: 0.55em;
  vertical-align: middle;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.single-pages-dropdown .dropdown-menu {
  --bs-dropdown-link-hover-color: var(--bs-primary);
  --bs-dropdown-link-active-color: var(--bs-primary);
  --bs-dropdown-link-active-bg: var(--bs-tertiary-bg);
}

.single-pages-dropdown .dropdown-menu.show {
  box-shadow: var(--bs-dropdown-shadow);
  animation: growDown 500ms ease-in-out forwards;
  transform-origin: top center;
}

.single-pages-dropdown .dropdown-menu[data-bs-popper] {
  margin-top: 10px;
}

.single-pages-dropdown .dropdown-item {
  font-family: var(--bs-font-family-style-4);
  font-weight: 600;
}

.offcanvas-pages-dropdown {
  --bs-dropdown-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.offcanvas-pages-dropdown .dropdown-menu {
  --bs-dropdown-font-size: 3.7rem;
  --bs-dropdown-link-hover-color: var(--bs-primary);
  --bs-dropdown-link-active-color: var(--bs-primary);
  --bs-dropdown-link-active-bg: var(--bs-tertiary-bg);
}

.offcanvas-pages-dropdown .dropdown-menu.show {
  display: block;
  right: 0 !important;
  margin-top: 150px !important;
  box-shadow: var(--bs-dropdown-shadow);
  animation: growDown 500ms ease-in-out forwards;
  transform-origin: top center;
}

@media (max-width: 1399.98px) {
  .offcanvas-pages-dropdown .dropdown-menu {
    --bs-dropdown-font-size: 3rem;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-pages-dropdown .dropdown-menu {
    --bs-dropdown-font-size: 1.5rem;
  }
  .offcanvas-pages-dropdown .dropdown-menu.show {
    display: block;
    right: auto !important;
    left: 0 !important;
    margin-top: 55px !important;
    max-width: 220px;
    min-width: 220px;
  }
}

.offcanvas-pages-dropdown .dropdown-item {
  font-weight: 800;
  line-height: 1.2;
  font-family: var(--bs-font-family-style-4);
  white-space: normal;
}

.offcanvas-pages-dropdown .dropdown-toggle::after {
  margin-left: 0.55em;
  vertical-align: middle;
}

.header-1.sticky .single-pages-dropdown .dropdown-menu[data-bs-popper], .header-7.sticky .single-pages-dropdown .dropdown-menu[data-bs-popper] {
  margin-top: 0;
}

.ball {
  width: 10px;
  height: 10px;
  background-image: linear-gradient(90deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  border-radius: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999;
  transition: width 0.5s ease, height 0.5s ease;
}

.cursor-text {
  --bs-cursor-text-color: #fff;
  position: absolute;
  transform: translate(-50%, -80%);
  pointer-events: none;
  opacity: 0;
  z-index: 999;
  transition: opacity 150ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--bs-cursor-text-color);
  font-size: 20px;
  margin-top: 8px;
}

.home-1 {
  --bs-warning: #0db231;
  --bs-warning-rgb: 13, 178, 49;
  --bs-primary: #B20D5D;
  --bs-primary-rgb: 178, 13, 93;
  --bs-secondary: #490AB5;
  --bs-secondary-rgb: 73, 10, 181;
  --bs-headings-color-light: #686666;
  --bs-navbar-toggler-color: #D2D2D2;
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  --bs-link-hover-color: var(--bs-primary);
  --bs-body-color: #5B5C66;
  --bs-navbar-border-color:rgba(255, 255, 255, 0.10);
  --bs-navbar-bg-color: rgba(38, 31, 48, 0.02);
  --bs-navbar-shadow-color: rgba(0, 0, 0, 0.39);
  --bs-separator-color: rgba(136, 132, 146, 0.20);
  --bs-navbar-active-color: var(--bs-primary);
  --bs-custom-inner-bg: rgba(38, 31, 48, 0.22);
  --bs-custom-inner-border-color: rgba(255, 255, 255, 0.11);
  --bs-custom-inner-shadow-color: rgba(0, 0, 0, 0.39);
}

.home-3 {
  --bs-primary: #FCA311;
  --bs-primary-rgb: 252, 163, 17;
  --bs-secondary: #4361EE;
  --bs-secondary-rgb: 67, 97, 238;
  --bs-headings-color-light: #CECCDA;
  --bs-navbar-toggler-color: #333;
  --bs-link-hover-color-rgb: var(--bs-primary);
  --bs-body-color: #5B5C66;
  --bs-navbar-border-color:rgba(255, 255, 255, 0.10);
  --bs-navbar-bg-color: rgba(38, 31, 48, 0.02);
  --bs-navbar-shadow-color: rgba(0, 0, 0, 0.39);
  --bs-navbar-border-color-2: #fff;
  --bs-navbar-bg-color-2: rgba(255, 255, 255, 0.10);
  --bs-navbar-shadow-color-2: rgba(0, 0, 0, 0.10);
  --bs-separator-color: rgba(136, 132, 146, 0.20);
  --bs-custom-inner-bg: #EBEDFF;
  --bs-custom-inner-border-color: #E0E0FF;
  --bs-custom-inner-shadow-color: rgba(255, 255, 255, 0.39);
}

.home-3 a:not(.btn) {
  transition: all 0.2s ease-in-out;
  color: var(--bs-heading-color);
}

.home-3 a:not(.btn):hover {
  color: var(--bs-link-hover-color-rgb);
}

.home-3 a.nav-link.active {
  color: var(--bs-link-hover-color-rgb);
}

.home-3 .lineup-image-hover {
  --bs-headings-color: #CECCDA;
}

.home-3 .lineup-image-hover h5, .home-3 .lineup-image-hover .h5 {
  color: var(--bs-headings-color);
}

.home-3 .highlights-item-2:hover h2, .home-3 .highlights-item-2:hover .h2, .home-3 .highlights-item-2.active h2, .home-3 .highlights-item-2.active .h2 {
  color: var(--bs-headings-color-light);
}

.home-6 {
  --bs-primary: #FCA311;
  --bs-primary-rgb: 252, 163, 17;
  --bs-secondary: #4361EE;
  --bs-secondary-rgb: 67, 97, 238;
  --bs-headings-color-light: #CECCDA;
  --bs-navbar-toggler-color: #333;
  --bs-link-hover-color-rgb: var(--bs-primary);
  --bs-body-color: #5B5C66;
  --bs-navbar-border-color:rgba(255, 255, 255, 0.10);
  --bs-navbar-bg-color: rgba(38, 31, 48, 0.02);
  --bs-navbar-shadow-color: rgba(0, 0, 0, 0.39);
  --bs-navbar-border-color-2: #fff;
  --bs-navbar-bg-color-2: rgba(255, 255, 255, 0.10);
  --bs-navbar-shadow-color-2: rgba(0, 0, 0, 0.10);
  --bs-separator-color: rgba(136, 132, 146, 0.20);
  --bs-custom-inner-bg: #EBEDFF;
  --bs-custom-inner-border-color: #E0E0FF;
  --bs-custom-inner-shadow-color: rgba(255, 255, 255, 0.39);
}

.home-6 a:not(.btn) {
  transition: all 0.2s ease-in-out;
}

.home-6 a:not(.btn):hover {
  color: var(--bs-link-hover-color-rgb);
}

.home-6 a.nav-link.active {
  color: var(--bs-link-hover-color-rgb);
}

.home-6 .highlights-item-2:hover h2, .home-6 .highlights-item-2:hover .h2, .home-6 .highlights-item-2.active h2, .home-6 .highlights-item-2.active .h2 {
  color: var(--bs-headings-color-light);
}

.home-7 {
  --bs-secondary: #FCA311;
  --bs-secondary-rgb: 252, 163, 17;
  --bs-primary: #4361EE;
  --bs-primary-rgb: 67, 97, 238;
  --bs-headings-color-light: #CECCDA;
  --bs-navbar-toggler-color: #333;
  --bs-link-hover-color-rgb: var(--bs-primary);
  --bs-body-color: #5B5C66;
  --bs-navbar-border-color:rgba(255, 255, 255, 0.10);
  --bs-navbar-bg-color: rgba(38, 31, 48, 0.02);
  --bs-navbar-shadow-color: rgba(0, 0, 0, 0.39);
  --bs-navbar-border-color-2: #fff;
  --bs-navbar-bg-color-2: rgba(255, 255, 255, 0.10);
  --bs-navbar-shadow-color-2: rgba(0, 0, 0, 0.10);
  --bs-separator-color: rgba(136, 132, 146, 0.20);
}

.home-7 a:not(.btn) {
  transition: all 0.2s ease-in-out;
}

.home-7 a:not(.btn):hover {
  color: var(--bs-link-hover-color-rgb);
}

.home-7 a.nav-link.active {
  color: var(--bs-link-hover-color-rgb);
}

.home-7 .lineup-image-hover {
  --bs-headings-color: #CECCDA;
}

.home-7 .lineup-image-hover h5, .home-7 .lineup-image-hover .h5 {
  color: var(--bs-headings-color);
}

.home-7 .highlights-item-2:hover h2, .home-7 .highlights-item-2:hover .h2, .home-7 .highlights-item-2.active h2, .home-7 .highlights-item-2.active .h2 {
  color: var(--bs-headings-color-light);
}

.header-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.header-section.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  left: 0;
  animation: fadeInDown .7s ease-in-out 0s 1 normal none running;
  transition: all .3s ease 0s;
}

.header-section .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: var(--bs-headings-color);
  --bs-navbar-color: var(--bs-heading-color);
}

.header-section .nav-link {
  letter-spacing: 1px;
  color: var(--bs-nav-link-color) !important;
}

.header-section .nav-link.active {
  color: var(--bs-primary) !important;
}

.header-section .nav-link:hover {
  color: var(--bs-primary) !important;
}

.header-1 {
  margin: 30px 0 0 0;
}

@media (max-width: 1399.98px) {
  .header-1 {
    margin: 20px 0 0 0;
  }
}

.header-1 .navbar {
  --bs-navbar-padding-x: 20px;
  --bs-navbar-padding-y: 20px;
  --bs-navbar-hover-color: var(--bs-primary);
  --bs-navbar-active-color:var(--bs-headings-color);
  border-radius: 15px !important;
  backdrop-filter: blur(25px);
}

@media (max-width: 1399.98px) {
  .header-1 .navbar {
    --bs-navbar-padding-y: 14px;
  }
}

.header-1 .navbar .nav-link {
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-font-size: 1rem;
  --bs-navbar-nav-link-padding-x:0;
  text-transform: uppercase;
}

.header-1 .navbar .navbar-toggler {
  --bs-navbar-toggler-padding-x: 0;
  border: 0;
}

.header-1 .navbar .navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .header-1 .navbar {
    --bs-navbar-padding-y: 6px;
  }
}

.header-1.sticky {
  margin: 10px 0 0 0;
}

.header-1.sticky .navbar {
  --bs-navbar-padding-y: 8px;
}

.header-2 {
  --bs-header-2-border-bottom: rgba(104, 102, 102, 0.30);
  margin-top: 20px;
}

.header-2 .navbar-toggler {
  --bs-navbar-toggler-padding-x: 0;
  border: 0;
}

.header-2 .navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.header-2.sticky {
  margin-top: 0;
  background-color: var(--bs-body-bg);
  border-bottom: 2px solid var(--bs-header-2-border-bottom);
}

.header-2.sticky .navbar {
  --bs-navbar-padding-y: 6px;
}

.header-3 .navbar {
  --bs-navbar-padding-y: 50px;
  --bs-navbar-padding-x: 60px;
}

@media (max-width: 1399.98px) {
  .header-3 .navbar {
    --bs-navbar-padding-y: 35px;
    --bs-navbar-padding-x: 35px;
  }
}

.header-3.transparent-header .shop-icon-xl, .header-3.transparent-header .offcanvas-menu-icon {
  color: #fff;
}

.header-3.transparent-header .logo-custom-light {
  display: block;
}

.header-3.transparent-header .logo-custom-dark {
  display: none;
}

.header-3 .navbar-nav {
  --bs-navbar-color:var(--bs-white);
  --bs-navbar-hover-color:var(--bs-primary);
}

.header-3 .nav-link {
  color: var(--bs-nav-link-color) !important;
  transition: all 0.2s ease-in-out;
}

.header-3 .nav-link:hover {
  color: var(--bs-navbar-hover-color) !important;
}

.header-3 .nav-link.active {
  color: var(--bs-navbar-hover-color) !important;
}

.header-3 .navbar-toggler {
  --bs-navbar-toggler-padding-x: 0;
  border: 0;
}

.header-3 .navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.header-3.sticky {
  margin-top: 0;
  background-color: var(--bs-white);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 4px;
}

.header-3.sticky .offcanvas-menu-icon svg {
  width: 32px;
  height: 32px;
}

@media (max-width: 991.98px) {
  .header-3.sticky .offcanvas-menu-icon svg {
    width: 24px;
    height: 24px;
  }
}

.header-3.sticky .navbar {
  --bs-navbar-padding-y: 8px;
}

.header-3.sticky.transparent-header .shop-icon-xl, .header-3.sticky.transparent-header .offcanvas-menu-icon {
  color: #000;
}

.header-3.sticky.transparent-header .logo-custom-light {
  display: none;
}

.header-3.sticky.transparent-header .logo-custom-dark {
  display: block;
}

@media (max-width: 991.98px) {
  .header-3 .navbar {
    --bs-navbar-padding-y: 20px;
    --bs-navbar-padding-x: 12px;
  }
}

@media (max-width: 575.98px) {
  .header-3 .navbar {
    --bs-navbar-padding-x: 8px;
  }
}

.header-7 {
  margin: 30px 0 0 0;
}

.header-7 .navbar {
  --bs-navbar-padding-x: 20px;
  --bs-navbar-padding-y: 20px;
  --bs-navbar-hover-color: var(--bs-primary);
  --bs-navbar-active-color:var(--bs-headings-color);
  border-radius: 15px;
  border: 2px solid var(--bs-navbar-border-color-2);
  background: var(--bs-navbar-bg-color-2);
  box-shadow: 1px 0px 39px 0px var(--bs-navbar-shadow-color-2);
  backdrop-filter: blur(25px);
}

.header-7 .navbar .nav-link {
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-font-size: 1rem;
  --bs-navbar-nav-link-padding-x:0;
  text-transform: uppercase;
}

.header-7 .navbar .navbar-toggler {
  --bs-navbar-toggler-padding-x: 0;
  border: 0;
}

.header-7 .navbar .navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .header-7 .navbar {
    --bs-navbar-padding-y: 6px;
  }
}

@media (max-width: 1399.98px) {
  .header-7 .navbar {
    --bs-navbar-padding-x: 16px;
    --bs-navbar-padding-y: 13px;
  }
}

.header-7.sticky {
  margin: 10px 0 0 0;
}

.header-7.sticky .navbar {
  --bs-navbar-padding-y: 6px;
}

@media (max-width: 1399.98px) {
  .header-7 {
    margin: 22px 0 0 0;
  }
}

.header-4 {
  background: #FFF;
  box-shadow: 1px 0px 39px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
}

.header-4 .navbar {
  --bs-navbar-padding-x: 40px;
  --bs-navbar-padding-y: 24px;
  --bs-navbar-hover-color: var(--bs-primary);
  --bs-navbar-active-color:var(--bs-secondary);
  --bs-navbar-color:#5B5C66;
}

.header-4 .navbar .nav-link {
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-font-size: 1rem;
  --bs-navbar-nav-link-padding-x:0;
  text-transform: uppercase;
}

.header-4 .navbar .navbar-toggler {
  --bs-navbar-toggler-padding-x: 0;
  border: 0;
}

.header-4 .navbar .navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .header-4 .navbar {
    --bs-navbar-padding-y: 10px;
    --bs-navbar-padding-x: 14px;
  }
}

.header-4.sticky .navbar {
  --bs-navbar-padding-x: 18px;
  --bs-navbar-padding-y: 8px;
}

.header-5 {
  margin-top: 25px;
}

@media (max-width: 1399.98px) {
  .header-5 {
    margin-top: 20px;
  }
}

.header-5 .navbar {
  --bs-navbar-padding-x: 20px;
  --bs-navbar-padding-y: 20px;
  border-radius: 15px !important;
  backdrop-filter: blur(25px);
}

@media (max-width: 1399.98px) {
  .header-5 .navbar {
    --bs-navbar-padding-y: 12px;
  }
}

.header-5 .navbar-toggler {
  --bs-navbar-toggler-padding-x: 0;
  border: 0;
}

.header-5 .navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.header-5.sticky {
  margin: 10px 0 0 0;
}

.header-5.sticky .navbar {
  --bs-navbar-padding-y: 6px;
}

.header-landing {
  background: transparent;
}

.header-landing .navbar {
  --bs-navbar-padding-x: 40px;
  --bs-navbar-padding-y: 24px;
  --bs-navbar-hover-color: var(--bs-primary);
  --bs-navbar-active-color:var(--bs-secondary);
  --bs-navbar-color: var(--bs-heading-color);
}

.header-landing .navbar .nav-link {
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-font-size: 1rem;
  --bs-navbar-nav-link-padding-x:0;
  text-transform: uppercase;
}

.header-landing .navbar .navbar-toggler {
  --bs-navbar-toggler-padding-x: 0;
  border: 0;
}

.header-landing .navbar .navbar-toggler:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .header-landing .navbar {
    --bs-navbar-padding-y: 12px;
    --bs-navbar-padding-x: 8px;
  }
}

.header-landing.sticky {
  box-shadow: rgba(255, 255, 255, 0.13) 0px 1px 5px;
  background-color: #f5f5f5;
}

.header-landing.sticky .navbar {
  --bs-navbar-padding-x: 8px;
  --bs-navbar-padding-y: 8px;
}

.ordered-list-header .single-pages-dropdown .dropdown-menu[data-bs-popper] {
  margin-top: 0;
}

.ordered-list-header .single-pages-dropdown .dropdown-toggle::after {
  position: relative;
  bottom: 16px;
  right: -20px;
}

@media (max-width: 991.98px) {
  .countdown-style-3 {
    top: -50px;
  }
}

.countdown-item {
  display: flex;
  align-items: center;
}

.countdown-number {
  font-size: 120px;
  font-weight: 800;
}

@media (max-width: 1399.98px) {
  .countdown-number {
    font-size: 90px;
  }
}

@media (max-width: 991.98px) {
  .countdown-number {
    font-size: 80px;
  }
}

@media (max-width: 575.98px) {
  .countdown-number {
    font-size: 60px;
  }
}

.countdown-label {
  --bs-countdown-text-stroke-color: #f2f2f2;
  color: var(--bs-heading-color);
  font-size: 42px;
  font-weight: 700;
  text-transform: capitalize;
  margin-left: -20px;
  z-index: 1;
}

.countdown-label.countdown-text-stroke {
  -webkit-text-stroke: 2px var(--bs-countdown-text-stroke-color);
  font-weight: 900;
}

@media (max-width: 1399.98px) {
  .countdown-label {
    font-size: 32px;
  }
}

@media (max-width: 991.98px) {
  .countdown-label {
    font-size: 30px;
    font-weight: 500;
  }
  .countdown-label.countdown-text-stroke {
    font-size: 32px;
    font-weight: 900;
  }
}

@media (max-width: 575.98px) {
  .countdown-label {
    font-size: 24px;
    margin-left: -10px;
  }
  .countdown-label.countdown-text-stroke {
    -webkit-text-stroke: 1px var(--bs-countdown-text-stroke-color);
    font-size: 25px;
    font-weight: 900;
  }
}

.odometer-value {
  margin-top: 0 !important;
}

.event-odometer-heading {
  color: var(--bs-heading-color);
  font-weight: 800;
}

.event-counter.event-counter-2 .odometer-digit:last-child {
  padding: 0 6px 0 0;
}

.event-counter .odometer-digit {
  padding: 0 1px 0 0;
}

@media (max-width: 1399.98px) {
  .event-counter .odometer.display-2 {
    font-size: 62px;
  }
}

@media (max-width: 1199.98px) {
  .event-counter .odometer.display-2 {
    font-size: 55px;
    line-height: 55px;
  }
  .event-counter h3, .event-counter .h3 {
    font-size: 20px;
  }
}

.event-counter.event-counter-style-2 .display-2 {
  font-size: 110px;
}

.event-counter.event-counter-style-2 h3, .event-counter.event-counter-style-2 .h3 {
  font-size: 36px;
}

@media (max-width: 1399.98px) {
  .event-counter.event-counter-style-2 .display-2 {
    font-size: 80px;
  }
  .event-counter.event-counter-style-2 h3, .event-counter.event-counter-style-2 .h3 {
    font-size: 28px;
  }
}

@media (max-width: 1399.98px) {
  .event-counter.event-counter-style-2 .display-2 {
    font-size: 75px;
  }
  .event-counter.event-counter-style-2 h3, .event-counter.event-counter-style-2 .h3 {
    font-size: 25px;
  }
}

@media (max-width: 991.98px) {
  .event-counter.event-counter-style-2 .display-2 {
    font-size: 60px;
  }
  .event-counter.event-counter-style-2 h3, .event-counter.event-counter-style-2 .h3 {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .event-counter.event-counter-style-2 .display-2 {
    font-size: 52px;
  }
  .event-counter.event-counter-style-2 h3, .event-counter.event-counter-style-2 .h3 {
    font-size: 18px;
  }
}

.countdown-gradient #days {
  -webkit-text-stroke: 2.5px #AA0D64;
  color: transparent;
}

.countdown-gradient #days.primary-text-shadow {
  filter: drop-shadow(10px 9px 46px rgba(170, 13, 100, 0.6)) drop-shadow(0 11px 15px rgba(170, 13, 100, 0.2)) drop-shadow(0 24px 38px rgba(170, 13, 100, 0.2)) drop-shadow(0 -3px 5px rgba(170, 13, 100, 0.2));
}

.countdown-gradient #hours {
  -webkit-text-stroke: 2.5px #8B0C7D;
  color: transparent;
}

.countdown-gradient #hours.primary-text-shadow {
  filter: drop-shadow(10px 9px 46px rgba(139, 12, 125, 0.6)) drop-shadow(0 11px 15px rgba(139, 12, 125, 0.2)) drop-shadow(0 24px 38px rgba(139, 12, 125, 0.2)) drop-shadow(0 -3px 5px rgba(139, 12, 125, 0.2));
}

.countdown-gradient #minutes {
  -webkit-text-stroke: 2.5px #740B91;
  color: transparent;
}

.countdown-gradient #minutes.primary-text-shadow {
  filter: drop-shadow(10px 9px 46px rgba(116, 11, 145, 0.6)) drop-shadow(0 11px 15px rgba(116, 11, 145, 0.2)) drop-shadow(0 24px 38px rgba(116, 11, 145, 0.2)) drop-shadow(0 -3px 5px rgba(116, 11, 145, 0.2));
}

.countdown-gradient #seconds {
  -webkit-text-stroke: 2.5px #4D0AB1;
  color: transparent;
}

.countdown-gradient #seconds.primary-text-shadow {
  filter: drop-shadow(10px 9px 46px rgba(77, 10, 177, 0.6)) drop-shadow(0 11px 15px rgba(77, 10, 177, 0.2)) drop-shadow(0 24px 38px rgba(77, 10, 177, 0.2)) drop-shadow(0 -3px 5px rgba(77, 10, 177, 0.2));
}

.hero-wrapper.box-hero {
  margin: 0 auto;
  max-width: 92.36%;
}

.hero-wrapper.box-hero.parallax::after {
  border-radius: 33px;
}

@media (max-width: 991.98px) {
  .hero-wrapper.box-hero {
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .hero-wrapper.box-hero {
    max-width: 100%;
  }
}

.hero-brand-images {
  padding-top: 20px;
}

@media (max-width: 575.98px) {
  .hero-brand-images {
    flex-direction: row;
  }
  .hero-brand-images img {
    max-width: 88px;
  }
}

@media (min-width: 992px) and (max-width: 1590px) {
  .hero-brand-images {
    padding-top: 6px;
  }
}

.hero-1 {
  margin-top: 160px;
}

@media (max-width: 1399.98px) {
  .hero-1 {
    margin-top: 127px;
  }
}

@media (max-width: 991.98px) {
  .hero-1 {
    margin-top: 100px;
  }
}

.hero-1 .hero-wrapper {
  --bg-parallax-image: url(../images/home-1/hero-image-2.jpg);
  --bg-parallax-opacity: 1;
  padding: 280px 0 70px 0;
}

.hero-1 .hero-wrapper.parallax::after {
  background-position: left center;
}

@media (max-width: 1399.98px) {
  .hero-1 .hero-wrapper {
    padding: 150px 0 60px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-1 .hero-wrapper {
    max-width: 100%;
    padding: 220px 0 40px 0;
  }
  .hero-1 .hero-wrapper.parallax::after {
    background-position: center;
  }
}

@media (max-width: 767.98px) {
  .hero-1 .hero-wrapper {
    max-width: 100%;
    padding: 75px 0 40px 0;
  }
}

.hero-5 {
  margin-top: 150px;
}

@media (max-width: 1399.98px) {
  .hero-5 {
    margin-top: 123px;
  }
}

@media (max-width: 991.98px) {
  .hero-5 {
    margin-top: 112px;
  }
}

.hero-5 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-5-bg.jpg);
  --bg-parallax-opacity: 1;
  padding: 200px 0 40px 0;
}

@media (max-width: 1399.98px) {
  .hero-5 .hero-wrapper {
    padding: 150px 0 60px 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-5 .hero-wrapper {
    padding: 130px 0 50px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-5 .hero-wrapper {
    max-width: 100%;
    padding: 220px 0 40px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-5 .hero-wrapper {
    max-width: 100%;
    padding: 75px 0 40px 0;
  }
  .hero-5 .hero-wrapper .event-info {
    margin-top: 0;
  }
}

.hero-2 .hero-wrapper {
  --bg-parallax-image: url(../images/home-2/hero-2-bg.jpg);
  --bg-parallax-opacity: 1;
  padding: 380px 0 145px 0;
}

@media (max-width: 1399.98px) {
  .hero-2 .hero-wrapper {
    padding: 230px 0 160px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-2 .hero-wrapper {
    max-width: 100%;
    padding: 350px 0 140px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-2 .hero-wrapper {
    max-width: 100%;
    padding: 500px 0 320px 0;
  }
}

.hero-3 .hero-inner-text {
  --bs-heading-color: #fff;
}

.hero-3 .hero-wrapper {
  --bg-parallax-image: url(../images/home-3/hero-3-bg.jpg);
  --bg-parallax-opacity: 1;
  padding: 330px 60px 185px 60px;
}

.hero-3 .hero-wrapper.parallax::after {
  background-position: top center;
}

@media (max-width: 1399.98px) {
  .hero-3 .hero-wrapper {
    padding: 190px 40px 100px 40px;
  }
}

@media (max-width: 767.98px) {
  .hero-3 .hero-wrapper {
    padding: 300px 16px 40px 16px;
  }
}

@media (max-width: 575.98px) {
  .hero-3 .hero-wrapper {
    padding: 300px 16px 100px 16px;
  }
}

.hero-3 .hero-wrapper .hero-6-texts {
  z-index: 1;
  position: relative;
}

.hero-3 .hero-wrapper .musicfest-image {
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .hero-3 .hero-wrapper .musicfest-image {
    margin-left: 8px;
  }
}

@media (max-width: 767.98px) {
  .hero-3 .hero-wrapper .musicfest-image {
    margin-left: 4px;
  }
}

.social-links {
  position: absolute;
  right: 60px;
  bottom: 195px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.social-links h3, .social-links .h3 {
  color: var(--bs-white);
  transition: all 0.2s ease-in-out;
  margin-bottom: 0;
}

.social-links a:hover h3, .social-links a:hover .h3 {
  color: var(--bs-primary);
}

@media (max-width: 1399.98px) {
  .social-links {
    bottom: 115px;
    right: 26px;
    gap: 15px;
  }
  .social-links h3, .social-links .h3 {
    font-size: 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .social-links {
    bottom: 110px;
    right: 36px;
    gap: 15px;
  }
}

@media (max-width: 767.98px) {
  .social-links {
    bottom: 50px;
  }
}

@media (max-width: 575.98px) {
  .social-links {
    flex-direction: row;
    right: auto;
    left: 18px;
    bottom: 62px;
  }
}

@media screen and (max-width: 1310px) {
  .social-links a h3, .social-links a .h3 {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1310px) {
  .social-links {
    right: 100px;
    bottom: 112px;
    gap: 30px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .social-links {
    right: 70px;
    bottom: 116px;
    gap: 30px;
  }
}

.hero-4 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-4.jpg);
  --bg-parallax-opacity: 1;
  padding: 280px 0 70px 0;
}

@media (max-width: 1399.98px) {
  .hero-4 .hero-wrapper {
    padding: 200px 0 60px 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-4 .hero-wrapper {
    padding: 180px 0 50px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-4 .hero-wrapper {
    max-width: 100%;
    padding: 220px 0 40px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-4 .hero-wrapper {
    max-width: 100%;
    padding: 140px 0 40px 0;
  }
}

.hero-7 {
  --bs-hero-7-bg:  var(--bs-body-bg);
  --bs-hero-7-border-color: rgba(0, 0, 0, .06);
  background-color: var(--bs-hero-7-bg);
  border-bottom: 1px solid var(--bs-hero-7-border-color);
}

.hero-7 .odometer-value {
  font-family: 'Plus Jakarta Sans' , sans-serif;
}

.hero-7 .hero-wrapper {
  --bg-parallax-image: url(../images/home-7/hero-7-bg.png);
  --bg-parallax-opacity: 1;
  padding: 280px 0 130px 0;
}

@media (max-width: 1399.98px) {
  .hero-7 .hero-wrapper {
    padding: 130px 0 80px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-7 .hero-wrapper {
    max-width: 100%;
    padding: 220px 0 80px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-7 .hero-wrapper {
    max-width: 100%;
    padding: 140px 0 60px 0;
  }
}

.hero-image-mask img {
  border-radius: 150px;
}

.hero-8 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-8-bg.jpg);
  padding: 280px 0 200px 0;
}

@media (max-width: 1399.98px) {
  .hero-8 .hero-wrapper {
    padding: 180px 0 160px 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-8 .hero-wrapper {
    padding: 180px 0 200px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-8 .hero-wrapper {
    max-width: 100%;
    padding: 220px 0 140px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-8 .hero-wrapper {
    max-width: 100%;
    padding: 130px 0 510px 0;
  }
}

@media (max-width: 575.98px) {
  .hero-8 .hero-wrapper {
    max-width: 100%;
    padding: 130px 0 340px 0;
  }
}

.hero-8 .hero-wrapper:after {
  opacity: var(--bg-parallax-opacity, 1);
}

.hero-8-inner {
  max-width: 950px;
}

@media (max-width: 1199.98px) {
  .hero-8-inner {
    max-width: 750px;
  }
}

@media (max-width: 991.98px) {
  .hero-8-inner {
    max-width: 450px;
  }
}

@media (max-width: 767.98px) {
  .hero-8-inner {
    max-width: 430px;
  }
}

.hero-heading-text {
  line-height: 93.182%;
  letter-spacing: -1.6px;
  font-size: 160px;
  font-weight: 900;
}

@media (max-width: 1399.98px) {
  .hero-heading-text {
    font-size: 115px;
  }
}

@media (max-width: 1199.98px) {
  .hero-heading-text {
    font-size: 100px;
  }
}

@media (max-width: 991.98px) {
  .hero-heading-text {
    font-size: 90px;
  }
}

@media (max-width: 575.98px) {
  .hero-heading-text {
    font-size: 70px;
  }
}

.hero-8-image {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 11%;
  z-index: 2;
}

@media (max-width: 1399.98px) {
  .hero-8-image {
    margin-right: 7%;
  }
  .hero-8-image img {
    max-width: 650px;
  }
}

@media (max-width: 1199.98px) {
  .hero-8-image {
    margin-right: 1%;
  }
  .hero-8-image img {
    max-width: 600px;
  }
}

@media (max-width: 991.98px) {
  .hero-8-image {
    margin-right: -28%;
  }
  .hero-8-image img {
    max-width: 700px;
  }
}

@media (max-width: 767.98px) {
  .hero-8-image {
    margin-right: 15%;
  }
  .hero-8-image img {
    max-width: 500px;
  }
}

@media (max-width: 575.98px) {
  .hero-8-image {
    margin-right: 2%;
  }
  .hero-8-image img {
    width: 340px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1750px) {
  .hero-8-image {
    margin-right: 0;
  }
}

.banner-1 {
  --bg-parallax-image: url(../images/banner-1.jpg);
  --bg-parallax-opacity: 1;
  --bs-banner-heading-color: #fff;
  padding: 250px 0 70px 0;
}

.banner-1.parallax::after {
  background-position: center bottom;
}

.banner-1 .banner-heading {
  color: var(--bs-banner-heading-color);
}

@media (max-width: 991.98px) {
  .banner-1 {
    padding: 140px 0 60px 0;
  }
}

.banner-1.banner-2 {
  --bg-parallax-image: url(../images/banner-2.jpg);
}

.banner-1.banner-3 {
  --bg-parallax-image: url(../images/banner-3.jpg);
}

.ticket-1 {
  --bg-parallax-image: url(../images/home-1/testimonial-bg.png);
  --bg-parallax-opacity: 1;
}

.ticket-content-1 {
  max-width: 620px;
  width: 100%;
}

.ticket-content-1 .input-number {
  background: no-repeat;
  border: 0;
  width: 38px;
  color: var(--bs-heading-color);
  display: inline-block;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

.ticket-content-1 .ticket-icon {
  --bs-ticket-icons-border-color: #686666;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-color: var(--bs-ticket-icons-border-color);
}

.ticket-content-1 .ticket-icon:hover {
  border-color: var(--bs-heading-color);
  color: var(--bs-white);
}

@media (max-width: 575.98px) {
  .ticket-content-1 .ticket-icon {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 1199.98px) {
  .ticket-content-1 {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .ticket-content-1 .btn-outline-primary {
    --bs-btn-padding-x: 1.7rem;
  }
}

@media (max-width: 575.98px) {
  .ticket-content-1 .btn-outline-primary {
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 14px;
    --bs-btn-padding-y: 0.6rem;
  }
  .ticket-content-1 .btn-outline-primary .buttons-logo svg {
    width: 22px;
  }
}

.ticket-form-wrapper {
  --bs-border-color: rgba(0, 0, 0, 0.11);
  overflow: hidden;
  margin-bottom: 10px;
  backdrop-filter: blur(25px);
}

.ticket-form-wrapper .ticket-form {
  border-bottom: 2px solid var(--bs-border-color);
  margin-bottom: 0;
  padding-left: 0;
}

.ticket-form-wrapper .ticket-form:last-child {
  border-bottom: 2px solid transparent;
}

.ticket-form-wrapper .ticket-form label {
  font-weight: 600;
  font-size: 18px;
  padding: 1rem 2rem;
  cursor: pointer;
}

.ticket-form-wrapper .ticket-form label span {
  color: var(--bs-heading-color);
  font-weight: 700;
  font-size: 18px;
}

@media (max-width: 991.98px) {
  .ticket-form-wrapper .ticket-form label {
    padding: 1rem;
    font-size: 16px;
  }
  .ticket-form-wrapper .ticket-form label span {
    font-weight: 600;
    font-size: 16px;
  }
}

.ticket-form-wrapper .form-check-input[type="radio"] {
  display: none;
}

.ticket-form-wrapper .border-transparent {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  margin-top: -2px;
}

.ticket-form-wrapper .border-transparent label span {
  color: #d2d2d2;
}

.ticket-content-2 {
  border: 2px solid rgba(255, 255, 255, 0.11);
  border-radius: 30px;
  --bs-heading-color: #d2d2d2;
  width: fit-content;
}

.ticket-content-2 .ticket-content-2-inner {
  background: linear-gradient(180deg, var(--bs-primary) 14.06%, var(--bs-secondary) 95.2%);
  border-radius: 28px;
  max-width: 412px;
  width: 100%;
}

.ticket-content-2 .ticket-content-2-inner span.fs-1 {
  line-height: 1;
}

.ticket-content-2 .ticket-content-2-inner.parallax::after {
  background-size: auto;
  background-position: center;
  overflow: hidden;
  border-radius: 30px;
}

.ticket-content-2 .ticket-content-2-inner .ticket-arrow {
  position: absolute;
  right: 2rem;
  bottom: 24px;
  width: 65px;
  height: 65px;
  border: 2px solid #fff;
}

.ticket-content-2 .ticket-content-2-inner .ticket-arrow:hover {
  color: #fff;
}

@media (max-width: 1199.98px) {
  .ticket-content-2 .ticket-content-2-inner {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .ticket-content-2 .ticket-content-2-inner h2, .ticket-content-2 .ticket-content-2-inner .h2 {
    max-width: 280px;
  }
}

@media (max-width: 575.98px) {
  .ticket-content-2 .ticket-content-2-inner h2, .ticket-content-2 .ticket-content-2-inner .h2 {
    max-width: 250px;
  }
}

.ticket-content-2 .progress {
  border-radius: 40px;
  width: 270px;
  --progress-bg: #fff;
  background-color: var(--progress-bg);
  height: 64px;
  position: relative;
}

@media (max-width: 1199.98px) {
  .ticket-content-2 .progress {
    min-width: 270px;
    max-width: 530px;
    width: auto;
  }
}

@media (max-width: 767.98px) {
  .ticket-content-2 .progress {
    min-width: 230px;
    max-width: 230px;
  }
}

.ticket-content-2 .progress::before {
  content: "73% filled";
  position: absolute;
  top: 17px;
  left: 30px;
  color: #fff;
  font-family: var(--bs-font-family-style-4);
  font-size: 18px;
  font-weight: 700;
}

.ticket-content-2 .progress::after {
  content: "27%";
  position: absolute;
  top: 17px;
  right: 10px;
  color: #490AB5;
  font-family: var(--bs-font-family-style-4);
  font-size: 18px;
  font-weight: 700;
}

.ticket-content-2 .progress .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  margin: 2px;
  border-radius: 40px;
  --progress-gradient: linear-gradient(90deg, var(--bs-primary) 1.72%, var(--bs-secondary) 99.9%);
  background-image: var(--progress-gradient);
  animation: progressAnimation 8s;
}

@media (max-width: 1199.98px) {
  .ticket-content-2 {
    width: 100%;
  }
}

@media screen and (min-width: 345px) and (max-width: 400px) {
  .ticket-content-2 .progress {
    min-width: 185px;
    max-width: 185px;
  }
  .ticket-content-2 .progress::after {
    top: 18px;
    font-size: 17px;
    right: 6px;
  }
  .ticket-content-2 .progress::before {
    top: 18px;
    left: 20px;
    font-size: 17px;
  }
}

@media screen and (min-width: 310px) and (max-width: 344.9px) {
  .ticket-content-2 .progress {
    min-width: 160px;
    max-width: 160px;
  }
  .ticket-content-2 .progress::after {
    top: 20px;
    font-size: 16px;
    right: 6px;
  }
  .ticket-content-2 .progress::before {
    top: 20px;
    left: 20px;
    font-size: 16px;
  }
}

.ticket-images img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.ticket-content-3 .brand {
  max-width: 195px;
  width: 100%;
  padding: 15px 28px;
  backdrop-filter: blur(5px);
}

.ticket-content-3 .brand h2, .ticket-content-3 .brand .h2 {
  line-height: 1.08;
}

@media (max-width: 991.98px) {
  .ticket-content-3 .brand {
    text-align: center;
  }
}

.ticket-swiper .ticket-swiper-pagination {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  width: 50px;
  height: 20px;
  margin-top: 9px;
  margin-right: 26px;
}

.ticket-swiper .ticket-swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  position: relative;
  background: #fff;
  opacity: 1;
}

.ticket-swiper .ticket-swiper-pagination .swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  background: var(--bs-primary);
  border-radius: 50%;
  z-index: 9999;
}

.ticket-swiper .ticket-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  background: #1E1E1E;
}

.ticket-wrapper {
  --bg-parallax-image: url(../images/about-ticket-image.jpg);
  --bg-parallax-opacity: 1;
  padding: 100px 50px 90px 50px;
}

@media (max-width: 991.98px) {
  .ticket-wrapper {
    padding: 60px 30px 50px 30px;
  }
}

@media (max-width: 575.98px) {
  .ticket-wrapper {
    padding: 40px 20px;
  }
}

.ticket-wrapper.parallax::after {
  border-radius: 34px;
}

.ticket-wrapper .ticket-inner {
  max-width: 534px;
}

.ticket-wrapper .ticket-inner .ticket-text {
  font-size: 22px;
}

@keyframes rotateText {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes move-y {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}

@keyframes move-x {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30px);
  }
}

@keyframes textMove {
  from {
    left: 0;
  }
  to {
    left: -200px;
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes loadingAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.3);
  }
}

@keyframes clock-rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes anti-clock-rotation {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes scroll-animation {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes landing-image-floating {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-25px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes progressAnimation {
  0% {
    width: 5%;
    background-color: var(--progress-bg);
  }
  100% {
    width: 73%;
    background-color: var(--progress-bg);
  }
}

@keyframes progressAnimation-1 {
  0% {
    width: 5%;
    background-color: var(--progress-bg);
  }
  100% {
    width: 85%;
    background-color: var(--progress-bg);
  }
}

@keyframes progressAnimation-2 {
  0% {
    width: 5%;
    background-color: var(--progress-bg);
  }
  100% {
    width: 90%;
    background-color: var(--progress-bg);
  }
}

.about-1 .ellipse-image-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: -100px;
  margin-left: -35px;
  z-index: -1;
  animation: move-y 4s infinite alternate;
}

.about-image-1 {
  z-index: 1;
  max-width: 582px;
  width: 100%;
  position: relative;
}

.about-image-1 .about-image-wrapper {
  --bs-about-img-border-radius: 30px;
}

.about-image-1 .about-image-wrapper img {
  border-radius: var(--bs-about-img-border-radius);
}

.about-image-1 .about-image-wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: var(--bs-about-img-border-radius);
  background: linear-gradient(224deg, rgba(var(--bs-primary-rgb), 0.6) 4.06%, rgba(var(--bs-secondary-rgb), 0.5) 76.9%);
}

.about-content-wrapper {
  --bs-about-content-margin-right: 65px;
  margin-right: var(--bs-about-content-margin-right);
}

@media (max-width: 991.98px) {
  .about-content-wrapper {
    --bs-about-content-margin-right: 30px;
  }
}

.about-image-2 {
  --bs-about-img-margin-top: 50px;
  --bs-about-img-margin-left: 65px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: var(--bs-about-img-margin-top);
  margin-left: var(--bs-about-img-margin-left);
  max-width: 582px;
  max-height: 440px;
  background: linear-gradient(223deg, var(--bs-primary) 4.06%, var(--bs-secondary) 92.2%);
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.about-image-2 img {
  border-radius: 33px;
}

@media (max-width: 991.98px) {
  .about-image-2 {
    --bs-about-img-margin-top: 24px;
    --bs-about-img-margin-left: 26px;
  }
}

.about-3-bg {
  --bs-about-3-bg: #EBEDFF;
  background-color: var(--bs-about-3-bg);
}

.hall-image img {
  border-radius: 30px !important;
}

.lineup-contents .ellipse-image-2 {
  position: absolute;
  bottom: -45px;
  left: -78px;
  z-index: -999;
  animation: move-x 4s infinite alternate;
}

@media (max-width: 575.98px) {
  .lineup-contents .ellipse-image-2 {
    left: auto;
    right: 0;
    animation: move-y 4s infinite alternate;
  }
}

.lineup-contents .lineup-right-content {
  max-width: 445px;
  width: 100%;
}

.progress-gradient .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: linear-gradient(110deg, var(--bs-primary) 12%, var(--bs-secondary) 100%);
}

.swiper-custom-progress {
  --swiper-theme-color: var(--bs-primary);
  --swiper-pagination-line-color: #d9d9d9;
  --bs-swiper-btn-next-color: var(--bs-heading-color);
  --bs-swiper-btn-prev-color: rgba(var(--bs-heading-color-rgb),.5);
}

.swiper-custom-progress .swiper-button-progress svg {
  fill: var(--bs-headings-color);
}

.swiper-custom-progress .swiper-button-prev, .swiper-custom-progress .swiper-button-next {
  bottom: -64px;
  top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.swiper-custom-progress .swiper-button-prev.swiper-button-disabled, .swiper-custom-progress .swiper-button-next.swiper-button-disabled {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.swiper-custom-progress .swiper-button-prev::after, .swiper-custom-progress .swiper-button-next::after {
  content: none;
}

.swiper-custom-progress .swiper-button-prev span, .swiper-custom-progress .swiper-button-next span {
  margin-top: -3px;
}

@media (max-width: 991.98px) {
  .swiper-custom-progress .swiper-button-prev, .swiper-custom-progress .swiper-button-next {
    bottom: -55px;
  }
}

.swiper-custom-progress .swiper-button-prev {
  left: 0;
  border-color: var(--bs-swiper-btn-prev-color);
  transition: all 0.2s ease-in-out;
}

.swiper-custom-progress .swiper-button-prev svg {
  fill: var(--bs-swiper-btn-prev-color);
  transition: all 0.2s ease-in-out;
}

.swiper-custom-progress .swiper-button-prev:hover {
  border-color: var(--bs-primary);
}

.swiper-custom-progress .swiper-button-prev:hover svg {
  fill: var(--bs-primary);
}

.swiper-custom-progress .swiper-button-prev span {
  margin-right: 2px;
}

.swiper-custom-progress .swiper-button-next {
  right: auto;
  left: 40px;
  border-color: var(--bs-swiper-btn-next-color);
  transition: all 0.2s ease-in-out;
}

.swiper-custom-progress .swiper-button-next svg {
  fill: var(--bs-swiper-btn-next-color);
  transition: all 0.2s ease-in-out;
}

.swiper-custom-progress .swiper-button-next:hover {
  border-color: var(--bs-primary);
}

.swiper-custom-progress .swiper-button-next:hover svg {
  fill: var(--bs-primary);
}

@media (max-width: 991.98px) {
  .swiper-custom-progress .swiper-button-next {
    left: 6%;
  }
}

@media (max-width: 767.98px) {
  .swiper-custom-progress .swiper-button-next {
    left: 8%;
  }
}

.swiper-custom-progress .lineup-swiper-pagination {
  width: 89%;
  height: 2px;
  left: 11%;
  top: auto;
  bottom: -52px;
}

@media (max-width: 1399.98px) {
  .swiper-custom-progress .lineup-swiper-pagination {
    width: 88%;
    left: 12%;
  }
}

@media (max-width: 1199.98px) {
  .swiper-custom-progress .lineup-swiper-pagination {
    width: 86%;
    left: 14%;
  }
}

@media (max-width: 991.98px) {
  .swiper-custom-progress .lineup-swiper-pagination {
    bottom: -43px;
    left: 22%;
    width: 77%;
  }
}

.swiper-custom-progress .lineup-swiper-pagination.swiper-pagination-progressbar {
  background: var(--swiper-pagination-line-color);
  border-radius: 10px;
}

.swiper-custom-progress.progress-style-1 {
  --swiper-theme-color: var(--bs-primary);
  --swiper-pagination-line-color: #D9D9D9;
}

.lineup-image-wrapper {
  transition: all 0.5s ease-in-out;
}

.lineup-image-wrapper .lineup-image-hover {
  --bs-lineup-headings-color: #CECCDA;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: -20px;
  left: 16px;
}

.lineup-image-wrapper .lineup-image-hover h5, .lineup-image-wrapper .lineup-image-hover .h5 {
  color: var(--bs-lineup-headings-color);
}

.lineup-image-wrapper .lineup-image-hover .author-name {
  font-family: var(--bs-font-family-style-2);
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 42px;
  color: var(--bs-lineup-headings-color);
}

@media (max-width: 1399.98px) {
  .lineup-image-wrapper .lineup-image-hover .author-name {
    font-size: 24px;
  }
}

.lineup-image-wrapper .lineup-image::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 30px;
  background: linear-gradient(190deg, rgba(178, 13, 93, 0) 4.66%, rgba(21, 2, 64, 0.81) 86.28%);
}

.lineup-image-wrapper .lineup-image.lineup-image-style-2::after {
  background: linear-gradient(174deg, rgba(178, 13, 93, 0) 46.95%, rgba(var(--bs-primary-rgb), 0.81) 103.38%);
}

.lineup-image-wrapper img {
  border-radius: 30px !important;
  display: block;
  width: 100%;
}

.lineup-image-wrapper .line-up-hover-content {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.lineup-image-wrapper:hover .lineup-image-hover {
  bottom: 32px;
}

.lineup-image-wrapper:hover .line-up-hover-content {
  visibility: visible;
  opacity: 1;
}

.lineup-right-content .lineup-link {
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid;
}

.line-up-icons {
  transition: all 0.2s ease-in-out;
  --bs-lineup-icons-color: #CECCDA;
}

.line-up-icons a {
  color: var(--bs-lineup-icons-color);
}

.line-up-icons a:hover {
  color: var(--bs-primary);
}

.lineup-2 .ellipse-image-2, .lineup-2 .ellipse-image-5, .lineup-2 .ellipse-image-7 {
  position: absolute;
  bottom: -45px;
  left: -78px;
  z-index: -999;
  animation: move-x 4s infinite alternate;
}

@media (max-width: 575.98px) {
  .lineup-2 .ellipse-image-2, .lineup-2 .ellipse-image-5, .lineup-2 .ellipse-image-7 {
    left: auto;
    right: 0;
    animation: move-x 4s infinite alternate;
  }
}

.highlight-section {
  --bs-highlights-bg: #f5f5f5;
  --bs-highlights-border-color: rgba(104, 102, 102, 0.30);
}

.highlight-section .ellipse-image-1 {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-top: 15px;
  animation: move-y 4s infinite  alternate;
}

.highlight-1 {
  --bg-parallax-image: url(../images/home-1/highlights-item-bg.png);
  --bg-parallax-opacity: 1;
  background-color: var(--bs-highlights-bg);
}

.highlight-1 .text-wrapper, .highlight-1 .highlights-text {
  position: relative;
}

.highlights-item {
  border: 2px solid var(--bs-highlights-border-color);
  border-radius: 30px;
  transition: background-image 0.3s ease-in-out;
  height: 300px;
  margin: 2px 0;
}

.highlights-item h2, .highlights-item .h2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 300px;
  transition: all 0.2s ease-in-out;
}

.highlights-item:hover, .highlights-item.active {
  background-image: url('<svg xmlns="http://www.w3.org/2000/svg" width="466" height="334" viewBox="0 0 466 334" fill="none"><g filter="url(#filter0_bd_2322_700)"><path d="M15 48C15 31.4315 28.4315 18 45 18H405C421.569 18 435 31.4315 435 48V196C435 212.569 421.569 226 405 226H400.5H399.5C374.647 226 354.5 246.147 354.5 271V276C354.5 292.569 341.069 306 324.5 306H45C28.4315 306 15 292.569 15 276V48Z" fill="#261F30" fill-opacity="0.1" shape-rendering="crispEdges"/><path d="M45 17C27.8792 17 14 30.8792 14 48V276C14 293.121 27.8792 307 45 307H324.5C341.621 307 355.5 293.121 355.5 276V271C355.5 246.699 375.199 227 399.5 227H400.5H405C422.121 227 436 213.121 436 196V48C436 30.8792 422.121 17 405 17H45Z" stroke="white" stroke-opacity="0.1" stroke-width="2" shape-rendering="crispEdges"/></g><defs><filter id="filter0_bd_2322_700" x="-27" y="-24" width="504" height="372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImageFix" stdDeviation="20"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2322_700"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="8" dy="5"/><feGaussianBlur stdDeviation="10.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.39 0"/>    <feBlend mode="normal" in2="effect1_backgroundBlur_2322_700" result="effect2_dropShadow_2322_700"/><feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2322_700" result="shape"/></filter></defs></svg>');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.highlights-item:hover h2, .highlights-item:hover .h2, .highlights-item.active h2, .highlights-item.active .h2 {
  color: #d2d2d2;
}

@media (max-width: 991.98px) {
  .highlights-item {
    height: 280px;
  }
}

@media (max-width: 767.98px) {
  .highlights-item {
    max-width: 375px;
    height: 260px;
  }
}

.highlights-item-2 {
  border: 2px solid var(--bs-highlights-border-color);
  border-radius: 30px;
  transition: all 0.3s;
  height: 300px;
  max-width: 420px;
}

.highlights-item-2 h2, .highlights-item-2 .h2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 300px;
  transition: all 0.3s ease-in-out;
}

.highlights-item-2 .highlights-arrow {
  position: absolute;
  bottom: 6px;
  right: 6px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

@media (max-width: 575.98px) {
  .highlights-item-2 .highlights-arrow {
    right: auto;
    left: 32px;
    bottom: 60px;
  }
  .highlights-item-2 .highlights-arrow .ticket-arrow {
    width: 46px;
    height: 46px;
  }
}

.highlights-item-2.active {
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/highlights-2.png);
  border-color: transparent;
}

@media (max-width: 575.98px) {
  .highlights-item-2.active {
    background-size: contain;
  }
}

.highlights-item-2.active .highlights-arrow {
  visibility: visible;
  opacity: 1;
}

.highlights-item-2.highlight-2-items-hover:hover {
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/highlights-2.png);
  border-color: transparent;
}

.highlights-item-2.highlight-2-items-hover:hover .highlights-arrow {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 575.98px) {
  .highlights-item-2 .highlights-icon {
    margin-top: 10px;
  }
  .highlights-item-2 .highlights-icon img {
    width: 50px;
    height: 50px;
  }
}

.highlights-item-3 {
  border: 2px solid var(--bs-highlights-border-color);
  border-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.highlights-item-3 h3, .highlights-item-3 .h3 {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-bottom: 2px;
}

.highlights-item-3:hover {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.highlights-item-3:hover h3, .highlights-item-3:hover .h3 {
  color: var(--bs-primary);
}

.highlights-item-3 p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.highlights-icon-style-1 svg {
  fill: var(--bs-primary);
}

.highlights-link .arrow-up-short svg {
  fill: var(--bs-primary);
}

.swiper.highlight-swiper-overflow {
  overflow: visible;
}

.schedule-section {
  --bs-custom-inner-bg: rgba(38, 31, 48, 0.22);
  --bs-custom-inner-border-color: rgba(255, 255, 255, 0.11);
  --bs-custom-inner-shadow-color: rgba(0, 0, 0, 0.39);
}

.schedule-1 {
  --bs-scheduletabs-active-bg: #f6f4f9;
  --bs-schedule-color-light: #5B5C66;
}

.schedule-1 .ellipse-image-2, .schedule-1 .ellipse-image-3, .schedule-1 .ellipse-image-7 {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -130px;
  margin-top: 75px;
  z-index: -1;
  animation: move-y 4s infinite  alternate;
}

@media (max-width: 767.98px) {
  .schedule-1 .ellipse-image-2, .schedule-1 .ellipse-image-3, .schedule-1 .ellipse-image-7 {
    margin-right: 0;
    margin-top: -40px;
  }
}

.schedule-left-content {
  position: relative;
}

.schedule-left-content .ellipse-image-1 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -50px;
  z-index: -1;
  animation: move-y 4s infinite  alternate;
}

@media (max-width: 767.98px) {
  .schedule-left-content .ellipse-image-1 {
    top: 0;
    bottom: auto;
    margin-right: 0;
    margin-top: 55px;
  }
}

.schedule-time {
  min-width: 148px;
  word-wrap: break-word;
}

.schedule-tabs-content li {
  border-bottom: 2px solid var(--bs-custom-inner-border-color);
  padding-bottom: 22px;
}

.schedule-tabs-content li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.schedule-tabs {
  backdrop-filter: blur(5px);
  padding: 20px;
}

@media (max-width: 991.98px) {
  .schedule-tabs {
    padding: 16px 22px;
  }
}

@media (max-width: 767.98px) {
  .schedule-tabs {
    padding: 12px;
  }
}

.schedule-tabs .schedule-button {
  --bs-schedule-buttons-padding: 18px 60px;
  background: transparent;
  border: 0;
  padding: var(--bs-schedule-buttons-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule-tabs .schedule-button span {
  color: var(--bs-schedule-color-light);
}

.schedule-tabs .schedule-button.active {
  border-radius: 25px;
  background: var(--bs-scheduletabs-active-bg);
}

.schedule-tabs .schedule-button.active span {
  color: var(--bs-heading-color);
}

@media (max-width: 767.98px) {
  .schedule-tabs .schedule-button.active {
    border-radius: 20px;
  }
}

@media (max-width: 991.98px) {
  .schedule-tabs .schedule-button {
    --bs-schedule-buttons-padding: 17px 40px;
  }
}

@media (max-width: 767.98px) {
  .schedule-tabs .schedule-button {
    --bs-schedule-buttons-padding: 10px 18px;
  }
}

@media screen and (min-width: 992.1px) and (max-width: 1200px) {
  .schedule-tabs .schedule-button {
    --bs-schedule-buttons-padding: 18px 24px;
  }
}

@media screen and (min-width: 1201.1px) and (max-width: 1399.9px) {
  .schedule-tabs .schedule-button {
    --bs-schedule-buttons-padding: 18px 40px;
  }
}

.schedule-image {
  --bg-mask-image: url(../images/home-1/schedule-image-1.png);
}

.schedule-wrapper {
  backdrop-filter: blur(25px);
}

.schedule-wrapper li {
  display: flex;
  flex-direction: row;
  gap: 70px;
  flex: 0 0 auto;
  padding: 30px;
  border-bottom: 2px solid var(--bs-custom-inner-border-color);
}

.schedule-wrapper li:last-child {
  border-bottom: 0;
}

@media (max-width: 575.98px) {
  .schedule-wrapper li {
    flex-direction: column;
    gap: 20px;
  }
}

.schedule-date {
  position: relative;
  left: 20px;
}

.schedule-date:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 80px;
  background: var(--bs-primary);
  left: -20px;
  top: 0;
}

.schedule-author {
  max-width: 80px;
}

.schedule-author img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.schedule-style-3 {
  --bs-scheduletabs-active-bg: #F6F4F9;
  --bs-custom-inner-border-color: rgba(104, 102, 102, 0.30);
  --bs-custom-inner-bg:rgba(255, 255, 255, 0.10);
  --bs-custom-inner-shadow-color: rgba(0, 0, 0, 0.10);
}

.schedule-style-3 .schedule-tabs {
  border-radius: 30px;
  border: 0;
  background: var(--bs-custom-inner-bg);
  box-shadow: 1px 0px 39px 0px var(--bs-custom-inner-shadow-color);
}

.sponsor-1 .ellipse-image-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  margin-left: -80px;
  margin-top: -15px;
  animation: move-y 4s infinite alternate;
}

@media (max-width: 991.98px) {
  .sponsor-1 .ellipse-image-1 {
    left: auto;
    right: 0;
  }
}

.sponsors-type {
  position: relative;
  left: 25px;
}

.sponsors-type::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: var(--bs-primary);
  left: -25px;
}

.sponsors-type.sponsors-type-style-2::before {
  background: var(--bs-secondary);
}

.sponsor-wrapper {
  --bs-border-color:rgba(104, 102, 102, 0.30);
  border: 2px solid var(--bs-border-color);
  border-radius: 10px;
  width: 264px;
  height: 80px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 3px;
  overflow: hidden;
}

@media (max-width: 1399.98px) {
  .sponsor-wrapper {
    width: 225px;
    height: 68px;
  }
}

@media (max-width: 1199.98px) {
  .sponsor-wrapper {
    width: 188px;
    height: 70px;
  }
}

@media (max-width: 767.98px) {
  .sponsor-wrapper {
    width: 160px;
    height: 60px;
  }
  .sponsor-wrapper img {
    max-width: 142px;
  }
}

.brand-image {
  padding: 2px 0;
}

@media (max-width: 767.98px) {
  .brand-image img {
    max-width: 140px;
  }
}

@media (max-width: 575.98px) {
  .brand-image img {
    max-width: 130px;
  }
}

.gallery-image img {
  --bs-gallery-image-height: 257px;
  height: var(--bs-gallery-image-height) !important;
  object-fit: cover;
  border-radius: 30px !important;
  width: 100%;
}

@media (max-width: 1399.98px) {
  .gallery-image img {
    --bs-gallery-image-height: 245px;
  }
}

@media (max-width: 991.98px) {
  .gallery-image img {
    --bs-gallery-image-height: 230px;
  }
}

@media (max-width: 767.98px) {
  .gallery-image img {
    --bs-gallery-image-height: 220px;
  }
}

.swiper_gallery .swiper-slide {
  width: 380px;
  max-width: 70vw;
}

.swiper_gallery .swiper-wrapper {
  transition-timing-function: linear;
}

.swiper_gallery2 {
  margin-top: 35px;
}

.swiper_gallery2 .swiper-slide {
  width: 380px;
  max-width: 70vw;
}

.swiper_gallery2 .swiper-slide:nth-child(3n+1) {
  width: 180px;
}

.swiper_gallery2 .swiper-wrapper {
  transition-timing-function: linear;
}

.faq-wrapper {
  --bs-custom-inner-bg: #EBEDFF;
}

.faq-wrapper .accordion {
  --bs-accordion-bg: var(--bs-custom-inner-bg);
  --bs-accordion-active-bg: var(--bs-custom-inner-bg);
  --bs-accordion-active-color: var(--bs-heading-color);
  --bs-accordion-btn-color: var(--bs-heading-color);
  --bs-accordion-active-bg: var(--bs-custom-inner-bg);
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-body-padding-x: 44px;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-btn-padding-x: 44px;
  --bs-accordion-btn-padding-y: 1.5rem;
  --bs-accordion-inner-border-radius:30px;
  --bs-border-radius:30px;
  max-width: 830px;
  left: 13px;
}

.faq-wrapper .accordion .accordion-item {
  overflow: hidden;
}

.faq-wrapper .accordion .accordion-body {
  background-color: var(--bs-custom-inner-bg);
}

.faq-wrapper .accordion .accordion-button {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .faq-wrapper .accordion .accordion-button {
    font-size: 16px;
  }
  .faq-wrapper .accordion .accordion-button span {
    max-width: 256px;
  }
}

.faq-wrapper .accordion .accordion-button::after {
  content: none;
}

.faq-wrapper .accordion .accordion-button.collapsed .faq-arrow {
  transform: rotate(135deg);
}

.faq-wrapper .accordion .accordion-button .faq-arrow {
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 767.98px) {
  .faq-wrapper .accordion .accordion-button .faq-arrow svg {
    width: 28px;
  }
}

.faq-wrapper .accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.faq-wrapper .accordion .accordion-button:focus, .faq-wrapper .accordion .accordion-button:focus-visible, .faq-wrapper .accordion .accordion-button:focus-within {
  border: none;
  box-shadow: none;
}

@media (max-width: 767.98px) {
  .faq-wrapper .accordion {
    --bs-accordion-btn-padding-x: 12px;
    --bs-accordion-body-padding-x: 12px;
  }
}

.faq-wrapper .ellipse-image-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -50px;
  margin-left: -135px;
  z-index: -1;
  animation: move-x 4s infinite  alternate;
}

@media (max-width: 767.98px) {
  .faq-wrapper .ellipse-image-2 {
    margin-right: 0;
    margin-top: -40px;
  }
}

.blog-content-wrapper .ellipse-image-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -90px;
  margin-left: -45px;
  z-index: -1;
  animation: move-y 4s infinite alternate;
}

@media (max-width: 767.98px) {
  .blog-content-wrapper .ellipse-image-1 {
    bottom: auto;
    top: 0;
  }
}

.blog-content-image {
  border-radius: 32px;
}

.blog-content {
  width: 100%;
  backdrop-filter: blur(5px);
  transition: all 0.2s ease-in-out;
  border-radius: 30px;
}

.blog-content .blog-content-2 {
  padding: 22px 22px 30px 22px;
}

@media (max-width: 575.98px) {
  .blog-content .blog-content-2 {
    padding: 16px 16px 30px 16px;
  }
}

.blog-content .blog-content-3 {
  padding: 22px 22px 30px 22px;
  border: 2px solid var(--bs-border-color);
  border-radius: 30px;
}

.blog-content .blog-content-4 {
  padding: 22px 22px 22px 40px;
}

@media (max-width: 991.98px) {
  .blog-content .blog-content-4 {
    padding: 22px;
  }
}

.blog-content .calendar {
  color: var(--bs-body-color);
}

.blog-content .blog-image {
  text-align: center;
}

.blog-content .blog-image img {
  border-radius: 16px !important;
}

@media (max-width: 767.98px) {
  .blog-content .blog-image img {
    width: 100%;
  }
}

.blog-content .download-link {
  transition: all 0.2s ease-in-out;
  width: fit-content;
}

.blog-content .ticket-arrow.arrow-up-right svg {
  transition: transform .3s ease-in-out;
}

.blog-content:hover .download-link span {
  color: var(--bs-primary);
}

.blog-content:hover .ticket-arrow.arrow-up-right svg {
  transform: rotate(45deg);
}

.blog-content.blog-gradient-border {
  transition: all 0.2s ease-in-out;
}

.blog-content.blog-gradient-border:hover:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 30px;
  transition: all 0.5s;
  background: rgba(var(--bs-primary-rgb), 1);
  background: -moz-linear-gradient(30deg, var(--bs-primary) 5%, var(--bs-secondary) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(var(--bs-primary-rgb), 1)), color-stop(100%, rgba(var(--bs-primary-rgb), 1)));
  background: -webkit-linear-gradient(30deg, var(--bs-primary) 5%, var(--bs-secondary) 100%);
  background: -o-linear-gradient(30deg, var(--bs-primary) 5%, var(--bs-secondary) 100%);
  background: -ms-linear-gradient(30deg, var(--bs-primary) 5%, var(--bs-secondary) 100%);
  background: linear-gradient(30deg, var(--bs-primary) 5%, var(--bs-secondary) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--bs-primary)', endColorstr='var(--bs-secondary)', GradientType=1 );
}

.blog-content.blog-gradient-border:hover:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 2px;
  z-index: -1;
  border-radius: 30px;
  background: var(--bs-body-bg);
}

@media (max-width: 991.98px) {
  .blog-left-content .blog-link {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 991.98px) {
  .blog-left-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.blog-horizontal .blog-swiper.swiper {
  overflow: visible;
}

.blog-inner-content, .blog-swiper-wrapper {
  --bs-border-color: #CECCDA;
  --bs-link-color-rgb: var(--bs-heading-color);
  max-width: 760px;
  margin: 0 auto;
}

.blog-inner-content p, .blog-swiper-wrapper p {
  letter-spacing: .2px;
  font-family: var(--bs-font-family-style-4);
  color: var(--bs-heading-color);
}

.blog-inner-content .children {
  list-style: none;
}

.text-sm {
  font-size: 14px;
  letter-spacing: .14px;
}

.blog-heading {
  letter-spacing: .58px;
}

.blog-inner-heading {
  font-size: 28px;
}

@media (max-width: 991.98px) {
  .blog-inner-heading {
    font-size: 20px;
  }
}

.blog-inner-text {
  font-size: 20px;
}

@media (max-width: 991.98px) {
  .blog-inner-text {
    font-size: 16px;
  }
}

.blog-inner-content .children {
  list-style: none;
}

.blog-inner-content blockquote.wp-block-quote {
  border-radius: 30px;
  border: 2px solid var(--bs-border-color);
  padding: 50px 35px 30px 35px;
}

.blog-inner-content blockquote.wp-block-quote .block-quote-icon {
  color: var(--bs-primary);
  position: relative;
  bottom: 18px;
}

.blog-swiper-image img {
  border-radius: 32px !important;
}

@media (max-width: 575.98px) {
  .blog-swiper-image img {
    width: 100%;
  }
}

.blog-order-list {
  padding-left: 1.5rem;
}

.blog-order-list li {
  letter-spacing: .2px;
  font-family: var(--bs-font-family-style-4);
  color: var(--bs-heading-color);
}

.blog-order-list li span {
  font-weight: 700;
}

.blog-order-list li::marker {
  font-weight: 700;
}

.post-navigation {
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1px solid var(--bs-border-color);
}

.blog-swiper-wrapper {
  max-width: 870px;
  margin: 0 auto;
}

.blog-author-image {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 auto;
}

.comment-reply {
  padding-left: 65px;
}

.blog-forms, .contact-us-form {
  --bs-border-color: #CECCDA;
  --blog-froms-bg: #F6F4F9;
}

.blog-forms textarea, .contact-us-form textarea {
  resize: none;
}

.blog-forms .form-control, .contact-us-form .form-control {
  border: 1px solid var(--bs-border-color);
  background-color: var(--blog-froms-bg);
  border-radius: 8px;
  padding: 0.81rem 0.875rem;
}

.blog-forms .form-control:focus, .contact-us-form .form-control:focus {
  --bs-border-color: var(--bs-primary);
  box-shadow: none;
}

.blog-forms .form-control::placeholder, .contact-us-form .form-control::placeholder {
  color: var(--bs-heading-color);
}

.social-icons-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

@media (max-width: 991.98px) {
  .social-icons-list {
    display: none;
  }
  .social-icons-list.social-icons-toggle {
    display: flex;
    position: absolute;
    top: -22px;
    left: 55px;
    padding: 16px 24px;
    border-radius: 15px;
    background: #fff;
    flex-direction: row;
  }
}

.social-share-icons {
  --bs-social-icons-color: #888492;
  position: sticky;
  top: 140px;
  width: fit-content;
  padding-right: 60px;
  z-index: 1;
  color: var(--bs-social-icons-color);
}

.social-share-icons svg:hover {
  fill: var(--bs-primary);
}

.social-share-icons .social-share-icon-1 {
  position: relative;
}

.social-share-icons .social-share-icon-1::before {
  content: "1.5k";
  position: absolute;
  left: 0;
  bottom: 0;
  color: var(--bs-social-icons-color);
  font-weight: 700;
  font-size: 16px;
  font-family: var(--bs-font-family-style-4);
  margin-bottom: -30px;
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .social-share-icons .social-share-icon-1::before {
    content: none;
  }
}

.blog-pagination .pagination {
  --bs-pagination-focus-color:none;
  --bs-pagination-focus-bg:none;
  --bs-pagination-focus-box-shadow:none;
  --bs-pagination-padding-y: .8rem;
  --bs-pagination-padding-x: 1.2rem;
}

.subscription-1 .ellipse-image-2 {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -60px;
  margin-right: -80px;
  z-index: -1;
  animation: move-y 4s infinite  alternate;
}

@media (max-width: 767.98px) {
  .subscription-1 .ellipse-image-2 {
    margin-top: -60px;
    margin-right: -10px;
  }
}

.subscription-form {
  --bs-subscription-border-color: rgba(0, 0, 0, 0.1);
  --bs-subscription-placeholder-color: rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
}

.subscription-form .form-control {
  --bs-border-radius: 20px;
  border: 2px solid var(--bs-subscription-border-color);
  padding: 1.5rem 1rem;
}

@media (max-width: 767.98px) {
  .subscription-form .form-control {
    padding: 0.75rem;
  }
}

.subscription-form .form-control::placeholder {
  color: var(--bs-subscription-placeholder-color);
  font-family: var(--bs-font-family-style-2);
  font-size: 22px;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .subscription-form .form-control::placeholder {
    font-size: 18px;
  }
}

.subscription-form .form-control:focus {
  box-shadow: none;
  border: 2px solid var(--bs-primary);
}

.subscription-form .form-control:focus + .subscription-form-arrow {
  color: var(--bs-primary);
}

.subscription-form .subscription-form-arrow {
  --bs-subscription-arrow-top-spacing: 18px;
  --bs-subscription-arrow-right-spacing: 25px;
  position: absolute;
  top: var(--bs-subscription-arrow-top-spacing);
  right: var(--bs-subscription-arrow-right-spacing);
  border: 0;
  background: transparent;
  color: var(--bs-subscription-placeholder-color);
}

@media (max-width: 767.98px) {
  .subscription-form .subscription-form-arrow {
    --bs-subscription-arrow-top-spacing: 6px;
    --bs-subscription-arrow-right-spacing: 9px;
  }
  .subscription-form .subscription-form-arrow svg {
    width: 30px;
  }
}

.subscription-2 {
  --bs-subscription-2-bg: #F6F4F9;
  background-color: var(--bs-subscription-2-bg);
}

.contact-1 {
  --bs-contact-1-bg:#F6F4F9;
  background-color: var(--bs-contact-1-bg);
}

.contact-lists li {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--bs-font-family-style-4);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 767.98px) {
  .contact-lists li {
    font-size: 22px;
  }
}

.contact-lists li:hover {
  padding-left: 5px;
}

.contact-icons {
  --bs-link-color: #838490;
  --bs-link-color-rgb: 131, 132, 144;
}

.contact-details {
  max-width: 280px;
}

.contact-details h3, .contact-details .h3 {
  width: fit-content;
}

.custom-heading-color-1, .custom-heading-color-2 {
  --bs-heading-color: #28303F;
}

.award-section {
  background-color: var(--bs-award-bg);
}

.award-1 {
  --bs-award-bg: #0C0614;
}

.award-2 {
  --bs-custom-inner-bg: #EBEDFF;
  --bs-award-bg: var(--bs-custom-inner-bg);
}

.award-2 .btn-outline-gradient:after, .award-2 .btn-outline-gradient.btn:after {
  background: var(--bs-award-bg);
}

.award-left-content {
  max-width: 533px;
}

.award-icon {
  border: 1px solid var(--bs-primary);
  border-radius: 15px;
  width: 60px;
  height: 60px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
}

.award-icon.award-icon-style-2 {
  border: none;
  --bs-btn-active-border-color: transparent;
  position: relative;
  text-decoration: none;
  -webkit-background-clip: text !important;
  z-index: 9;
}

.award-icon.award-icon-style-2:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 11px;
  transition: all 0.5s;
  background: rgba(var(--bs-primary-rgb), 1);
  background: -moz-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(var(--bs-primary-rgb), 1)), color-stop(100%, rgba(var(--bs-primary-rgb), 1)));
  background: -webkit-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: -o-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: -ms-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--bs-primary)', endColorstr='var(--bs-secondary)', GradientType=1 );
}

.award-icon.award-icon-style-2:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 2px;
  z-index: -1;
  border-radius: 9px;
  transition: all 0.5s;
  background: var(--bs-award-bg);
}

.award-image {
  --bs-award-img-width: 945px;
  --bs-award-img-height: 1110px;
  --bs-award-img-border-radius: 33px 0 0 33px;
  width: var(--bs-award-img-width);
  height: var(--bs-award-img-height);
}

.award-image img {
  border-radius: var(--bs-award-img-border-radius);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .award-image {
    --bs-award-img-width: auto;
    --bs-award-img-height: auto;
    --bs-award-img-border-radius: 30px;
  }
  .award-image img {
    max-width: 100%;
    height: auto;
  }
}

.bg-ticket {
  --bs-pricing-border-color: #d2d2d2;
  --bs-pricing-border-radius: 30px ;
  --bs-pricing-border-width: 2px ;
  --bs-pricing-icon-color: var(--bs-primary);
  --bs-ticket-bg: #fff;
  --bs-circle-fill-color: var(--bs-primary);
  --bs-circle-bg: rgba(var(--bs-primary-rgb),.4) ;
}

.bg-ticket .pricing-top-content {
  border-top: var(--bs-pricing-border-width) solid var(--bs-pricing-border-color);
  border-left: var(--bs-pricing-border-width) solid var(--bs-pricing-border-color);
  border-right: var(--bs-pricing-border-width) solid var(--bs-pricing-border-color);
  border-radius: var(--bs-pricing-border-radius) var(--bs-pricing-border-radius) 0 0;
  padding: 50px 50px 60px 50px;
  background-color: var(--bs-ticket-bg);
}

@media (max-width: 1399.98px) {
  .bg-ticket .pricing-top-content {
    padding: 40px 30px 40px 30px;
  }
}

@media (max-width: 1199.98px) {
  .bg-ticket .pricing-top-content {
    padding: 50px 50px 60px 50px;
  }
}

.bg-ticket .pricing-bottom-content {
  border-bottom: var(--bs-pricing-border-width) solid var(--bs-pricing-border-color);
  border-left: var(--bs-pricing-border-width) solid var(--bs-pricing-border-color);
  border-right: var(--bs-pricing-border-width) solid var(--bs-pricing-border-color);
  border-radius: 0 0 var(--bs-pricing-border-radius) var(--bs-pricing-border-radius);
  background-color: var(--bs-ticket-bg);
  padding-bottom: 46px;
  display: flex;
  justify-content: center;
  margin-top: -1px;
}

.bg-ticket .pricing-bottom-content .btn-outline-gradient, .bg-ticket .pricing-bottom-content .btn-outline-gradient.btn {
  text-transform: none;
  padding: 12px 45.5px !important;
}

.bg-ticket .pricing-bottom-content .btn-outline-gradient:after, .bg-ticket .pricing-bottom-content .btn-outline-gradient.btn:after {
  background: var(--bs-ticket-bg);
}

.bg-ticket .pricing-bottom-content .btn-outline-gradient:hover::after, .bg-ticket .pricing-bottom-content .btn-outline-gradient.btn:hover::after {
  background: -webkit-linear-gradient(6deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
}

.bg-ticket .pricing-list {
  --bs-circle-fill-color: var(--bs-primary);
  margin-bottom: 0;
}

.bg-ticket .pricing-list li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bg-ticket .pricing-list li .check-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-circle-bg);
  border-radius: 50%;
  color: var(--bs-circle-fill-color);
}

.bg-ticket .pricing-list li .check-icon svg {
  width: 20px;
  height: 20px;
}

.pricing-separator {
  --bs-pricing-separator-stroke-color: var(--bs-pricing-border-color);
}

.pricing-separator svg {
  fill: currentColor;
  color: var(--bs-ticket-bg);
  stroke: var(--bs-pricing-separator-stroke-color);
}

@media (min-width: 767.9px) and (max-width: 991.9px) {
  .bg-ticket .pricing-top-content {
    padding: 40px 30px 40px 30px;
  }
}

@media (min-width: 320px) and (max-width: 445px) {
  .bg-ticket .pricing-top-content {
    padding: 40px 30px 40px 30px;
  }
}

.pricing-subtitle {
  color: var(--bs-body-color);
}

.pricing-icon {
  color: var(--bs-pricing-icon-color);
}

.merchandise-1 {
  --bs-merchandise-1-bg: #F6F4F9;
  --bs-merchandise-border: rgba(104, 102, 102, 0.30);
  --bs-merchandise-wrapper-bg: #fff;
  background-color: var(--bs-merchandise-1-bg);
}

.merchandise-1 h5, .merchandise-1 .h5 {
  color: var(--bs-heading-color);
}

.merchandise-1 .ellipse-image-4, .merchandise-1 .ellipse-image-6 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -56px;
  margin-bottom: -55px;
  animation: move-y 4s infinite  alternate;
}

@media (max-width: 767.98px) {
  .merchandise-1 .ellipse-image-4, .merchandise-1 .ellipse-image-6 {
    margin-right: 0;
    margin-top: -40px;
  }
}

.merchandise-wrapper {
  padding: 20px 20px 24px 20px;
  border-radius: 30px;
  border: 2px solid var(--bs-merchandise-border);
  max-width: 310px;
  background-color: var(--bs-merchandise-wrapper-bg);
  transition: all 0.2s ease-in-out;
}

.merchandise-wrapper .btn {
  --bs-btn-font-size: 14px;
}

.merchandise-wrapper .btn-outline-primary, .merchandise-wrapper .btn-outline-secondary {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: .65rem;
}

.merchandise-wrapper .merchandise-image {
  overflow: hidden;
  border-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.merchandise-wrapper img {
  transition: all 0.2s ease-in-out;
}

.merchandise-wrapper:hover {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.merchandise-wrapper:hover img {
  transform: scale(1.07);
}

.merchandise-wrapper.merchandise-dark:hover {
  box-shadow: rgba(255, 255, 255, 0.18) 0px 2px 4px;
}

.product-information {
  max-width: 142px;
}

.eventiva-landing {
  --bs-body-bg: #fff;
  --bs-body-color: #04000A;
  --bs-body-color-light: #8EA3CA;
  --bs-secondary: #B20D5D;
  --bs-secondary-rgb: 178, 13, 93;
  --bs-primary: #4361EE;
  --bs-primary-rgb: 67, 97, 238;
  --bs-link-hover-color-rgb: var(--bs-primary);
}

.landing-inner-bg {
  --bs-landing-inner-bg: #f5f5f5;
  background-color: var(--bs-landing-inner-bg);
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 5px;
}

.check-circle-fill svg {
  fill: transparent;
}

.hero-landing {
  position: relative;
  --bg-parallax-image: url(../images/hero-landing-bg.png);
  --bg-parallax-opacity: 1;
  padding-top: 235px;
}

@media (max-width: 991.98px) {
  .hero-landing {
    padding-top: 155px;
  }
  .hero-landing .hero-landing-image-3 img, .hero-landing .hero-landing-image-4 img, .hero-landing .hero-landing-image-5 img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

@media (max-width: 575.98px) {
  .hero-landing {
    padding-top: 100px;
  }
}

.hero-landing .hero-txt {
  max-width: 960px;
}

.hero-landing .hero-txt h1, .hero-landing .hero-txt .h1 {
  padding-bottom: 3px;
}

.hero-landing .hero-txt .hero-text-inner {
  color: var(--bs-body-color);
  font-weight: 500;
  font-size: 22px;
  max-width: 800px;
}

@media (max-width: 991.98px) {
  .hero-landing .hero-txt .hero-text-inner {
    font-size: 16px;
  }
}

.hero-landing-image-1 {
  position: absolute;
  top: 110px;
  right: 0;
  z-index: -9;
}

.hero-landing-image-2 {
  position: absolute;
  bottom: calc(50% - 176px);
  right: 0;
  z-index: -9;
}

.features-landing {
  --bs-features-heading-color: var(--bs-heading-color);
  --bs-features-body-color: #838490;
}

.features-landing .features-image {
  width: fit-content;
  position: relative;
}

.features-landing .features-icon {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}

.features-landing .features-heading {
  color: var(--bs-features-heading-color);
  font-family: var(--bs-font-family-style-4);
  font-size: 22px;
  font-weight: 700;
}

.features-landing .features-text {
  color: var(--bs-features-body-color);
  font-family: var(--bs-font-family-style-4);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.features-landing .circle-features svg {
  fill: transparent;
}

.sticky-header {
  position: sticky;
  top: 80px;
}

@media (max-width: 991.98px) {
  .sticky-header {
    position: relative;
    top: 0;
  }
}

.technologies-wrapper {
  --bs-technologies-heading-color: #617AA8;
  --bs-technologies-text-color: #8EA3CA;
}

.technologies-wrapper .technologies-heading {
  color: var(--bs-technologies-heading-color);
  font-family: var(--bs-font-family-style-4);
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .technologies-wrapper .technologies-heading {
    font-size: 18px;
  }
}

.technologies-wrapper .technologies-text {
  color: var(--bs-technologies-text-color);
  font-family: var(--bs-font-family-style-4);
  font-size: 26px;
  font-weight: 500;
  line-height: 148.5%;
  letter-spacing: 0.26px;
}

@media (max-width: 991.98px) {
  .technologies-wrapper .technologies-text {
    font-size: 16px;
  }
}

.technologies-sm {
  width: 230px;
  height: 235px;
  padding: 24px 30px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .technologies-sm {
    width: 162px;
    height: 168px;
    padding: 8px 10px;
  }
}

.technologies-md {
  width: 515px;
  height: 230px;
  padding: 24px 0 24px 36px;
  position: relative;
  overflow: hidden;
}

.technologies-md .technologies-gradient-text {
  position: absolute;
  max-width: 300px;
  bottom: 15px;
  letter-spacing: .1px;
  line-height: 93.5%;
  padding-bottom: 12px;
  margin-bottom: 0;
  max-width: 285px;
}

.technologies-md .text-gradient {
  background: linear-gradient(90deg, var(--bs-primary) 8.83%, var(--bs-secondary) 59.83%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.technologies-md .text-gradient.text-gradient-style-2 {
  --bs-primary: #FCA311;
}

.technologies-md .technologies-huge-text {
  background: linear-gradient(180deg, var(--bs-technologies-heading-color) -108.04%, rgba(97, 122, 168, 0) 94.64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--bs-font-family-style-4);
  font-size: 150px;
  font-weight: 800;
  position: relative;
  top: -38px;
}

.technologies-md img {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 991.98px) {
  .technologies-md {
    width: 335px;
    height: 210px;
    padding: 15px 0 22px 20px;
  }
  .technologies-md .technologies-gradient-text {
    max-width: 205px;
  }
}

.technologies-lg {
  width: 800px;
  height: 500px;
  position: relative;
  padding: 50px;
  display: flex;
  align-items: center;
}

.technologies-lg::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url(../images/technologies-lg-mask.png);
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.technologies-lg .technologies-lg-content {
  position: relative;
  z-index: 9;
}

.technologies-lg .text-gradient {
  background: linear-gradient(90deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.technologies-lg .text-gradient.text-gradient-style-2 {
  --bs-primary: #FCA311;
}

.technologies-lg .technologies-lg-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.technologies-lg .technologies-lg-heading {
  font-size: 100px;
}

.technologies-lg .custom-badge {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 8px;
  margin-right: 56px;
  background: #4361EE;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: var(--bs-font-family-style-4);
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

@media (max-width: 1399.98px) {
  .technologies-lg .custom-badge {
    margin-top: 13px;
    margin-right: -23px;
  }
}

@media (max-width: 991.98px) {
  .technologies-lg .custom-badge {
    margin-top: 0px;
    margin-right: 18px;
    width: 30px;
    height: 30px;
    font-size: 10px;
  }
}

@media (max-width: 991.98px) {
  .technologies-lg {
    width: 335px;
    height: 210px;
    padding: 30px;
  }
  .technologies-lg .frame-image {
    width: 35px;
  }
  .technologies-lg .technologies-lg-heading {
    font-size: 38px;
  }
}

@media (min-width: 992px) and (max-width: 1199.9px) {
  .technologies-sm {
    width: 188px;
    height: 226px;
    padding: 24px 16px;
  }
  .technologies-wrapper .technologies-heading {
    font-size: 19px;
  }
  .technologies-lg {
    width: 500px;
    height: 480px;
  }
  .technologies-wrapper .technologies-text {
    font-size: 21px;
  }
  .technologies-lg .technologies-lg-heading {
    font-size: 86px;
  }
}

.demos h2, .demos .h2 {
  padding-bottom: 2px;
}

.demos p {
  padding: 0 15%;
  margin: 20px 0 0;
  font-weight: 300;
  font-size: 24px;
}

@media (max-width: 991.98px) {
  .demos p {
    padding: 0;
    font-size: 20px;
  }
}

.demos .demo-title {
  --bs-demo-title-color: var(--bs-heading-color);
  color: var(--bs-demo-title-color);
  font-weight: 800;
  font-size: 22px;
  line-height: 1;
  font-family: var(--bs-font-family-style-4);
  position: relative;
  top: 22px;
  transition: all 400ms ease-in-out;
}

.demos .demo-image {
  position: relative;
  top: 0;
  transition: all 400ms ease-in-out;
  padding: 40px 16px 40px 16px;
}

.demos .demo-image .dots {
  position: absolute;
  top: 8px;
}

.demos .demo-image:hover {
  top: -12px;
}

.demos .demo-image:hover .demo-title {
  background: linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0;
}

.loading-wrapper {
  --bs-loading-text-color: var(--bs-heading-color);
  --bs-category-bg: #0DB10A;
}

.loading-wrapper .category {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 31px;
  background-color: var(--bs-category-bg);
  border-radius: 8px;
  color: #fff;
  font-family: var(--bs-font-family-style-4);
  font-size: 18px;
  font-weight: 500;
}

.loading-heading {
  color: var(--bs-loading-text-color);
  font-family: var(--bs-font-family-style-4);
  font-size: 45px;
  font-weight: 700;
}

.loading-desciption {
  color: var(--bs-loading-text-color);
  font-family: var(--bs-font-family-style-4);
  font-size: 19px;
  font-weight: 500;
  max-width: 130px;
}

.loading-progress {
  --progress-bg: rgba(0, 0, 0, 0.1);
  --progress-gradient: linear-gradient(90deg, #B20D5D 1.72%, #FCA311 99.9%);
}

.progress {
  border-radius: 40px;
  width: 330px;
  background-color: var(--progress-bg);
  height: 20px;
  position: relative;
}

.progress .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  margin: 4px;
  border-radius: 40px;
  background-image: var(--progress-gradient);
  animation: progressAnimation-1 8s;
}

.progress .progress-bar.progress-bar-2 {
  animation: progressAnimation-2 8s;
}

.pricing-landing-wrapper {
  --bs-pricing-text-color: var(--bs-heading-color);
}

.pricing-landing-wrapper .separator {
  max-width: 434px;
}

.pricing-heading {
  color: var(--bs-pricing-text-color);
  font-family: var(--bs-font-family-style-4);
  font-size: 60px;
  font-weight: 700;
}

@media (max-width: 1399.98px) {
  .pricing-heading {
    font-size: 52px;
  }
}

@media (max-width: 1199.98px) {
  .pricing-heading {
    font-size: 50px;
  }
}

@media (max-width: 991.98px) {
  .pricing-heading {
    font-size: 40px;
  }
}

@media (max-width: 767.98px) {
  .pricing-heading {
    font-size: 30px;
  }
}

.pricing-amount {
  color: var(--bs-pricing-text-color);
  font-family: var(--bs-font-family-style-4);
  font-weight: 700;
}

.pricing-landing-image {
  transform: translatey(0px);
  animation: landing-image-floating 5s ease-in-out infinite;
  position: relative;
  top: 12.5px;
}

.dark-wrapper-bg {
  --bs-dark-wrapper-bg: #28303F;
  padding: 100px 50px;
  border-radius: 30px;
  background-color: var(--bs-dark-wrapper-bg);
  max-width: 1420px;
  width: 100%;
  margin: 0 auto;
}

.dark-wrapper-bg .cta-text {
  color: var(--bs-white) !important;
}

.dark-wrapper-bg .arrow-up-right-big svg {
  fill: var(--bs-primary);
  stroke: var(--bs-primary);
}

.dark-wrapper-bg .arrow-up-right-big.arrow-big-style-2 svg {
  fill: var(--bs-secondary);
  stroke: var(--bs-secondary);
}

.dark-wrapper-bg .countdown-label {
  color: var(--bs-white);
}

.dark-wrapper-bg .cta-percent-off {
  margin-left: -10px;
}

@media screen and (max-width: 575px) {
  .dark-wrapper-bg {
    width: calc(100vw - 20px);
    padding: 20px 8px;
  }
  .dark-wrapper-bg .countdown-number {
    font-size: 48px;
  }
  .dark-wrapper-bg .countdown-label {
    font-size: 20px;
  }
}

@media screen and (min-width: 991.9px) and (max-width: 1199.9px) {
  .cta-text.display-3 {
    font-size: 45px;
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .dark-wrapper-bg {
    max-width: 520px;
    padding: 30px 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .dark-wrapper-bg {
    max-width: 720px;
    padding: 30px 20px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1150px) {
  .dark-wrapper-bg {
    max-width: 950px;
    padding: 50px 20px;
  }
}

@media screen and (min-width: 1151px) and (max-width: 1249px) {
  .dark-wrapper-bg {
    max-width: 1140px;
    padding: 60px 20px;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1355px) {
  .dark-wrapper-bg {
    max-width: 1230px;
    padding: 70px 20px;
  }
}

@media screen and (min-width: 1356px) and (max-width: 1450px) {
  .dark-wrapper-bg {
    max-width: 1330px;
    padding: 80px 20px;
  }
}

.cta-1 .cta-text {
  max-width: 500px;
}

.cta-1 .dark-wrapper-bg .odometer-auto-theme, .cta-1 .dark-wrapper-bg .cta-percent, .cta-1 .dark-wrapper-bg .cta-off {
  font-family: var(--bs-font-family-style-4);
}

.cta-1 .dark-wrapper-bg .odometer-auto-theme .odometer-digit:last-child {
  padding: 0 10px 0 0;
  margin-left: -10px;
}

@media (max-width: 991.98px) {
  .cta-1 .dark-wrapper-bg .odometer-auto-theme .odometer-digit:last-child {
    padding: 0 5px 0 0;
    margin-left: -7px;
  }
}

.cta-percent {
  --bs-cta-percent-font-size: 100px;
  line-height: 1;
  font-size: var(--bs-cta-percent-font-size);
}

@media (max-width: 1399.98px) {
  .cta-percent {
    --bs-cta-percent-font-size: 75px;
  }
}

@media (max-width: 1199.98px) {
  .cta-percent {
    --bs-cta-percent-font-size: 70px;
  }
}

@media (max-width: 991.98px) {
  .cta-percent {
    --bs-cta-percent-font-size: 50px;
  }
}

.cta-off {
  --bs-cta-off-font-size: 60px;
  --bs-cta-off-bottom-spacing: 10px;
  font-size: var(--bs-cta-off-font-size);
  line-height: 1;
  position: relative;
  bottom: var(--bs-cta-off-bottom-spacing);
}

@media (max-width: 1399.98px) {
  .cta-off {
    --bs-cta-off-font-size: 54px;
  }
}

@media (max-width: 1199.98px) {
  .cta-off {
    --bs-cta-off-font-size: 50px;
  }
}

@media (max-width: 991.98px) {
  .cta-off {
    --bs-cta-off-font-size: 30px;
    --bs-cta-off-bottom-spacing: 5px;
  }
}

.btn {
  --bs-btn-padding-x: 2.7rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 600;
  --bs-btn-font-family: var(--bs-font-family-style-2);
  --bs-btn-border-radius: 10px;
  --bs-btn-hover-bg: transparent;
  --bs-btn-active-bg: transparent;
  --bs-btn-light-color: var(--bs-heading-color);
  --bs-btn-light-2-color: #fff;
  letter-spacing: .5px;
}

.btn.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-x: 23px;
  --bs-btn-padding-y: 11px;
  --bs-btn-font-weight: 500;
  --bs-btn-font-family: var(--bs-font-family-style-4);
  --bs-btn-border-width: 1px;
}

.btn.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-x: 4.5rem;
}

.btn.btn-wide {
  --bs-btn-padding-x: 4rem;
}

@media (max-width: 767.98px) {
  .btn.btn-wide {
    --bs-btn-padding-x: 3rem;
  }
}

.btn.btn-rounded {
  --bs-btn-border-radius: 45px;
}

.btn svg {
  transition: all 0.2s ease-in-out;
}

.btn-primary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-border-width: 2px;
  --bs-btn-hover-color: var(--bs-heading-color);
  --bs-btn-active-color: var(--bs-heading-color);
}

.btn-secondary {
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-border-width: 2px;
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-active-color: var(--bs-secondary);
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-heading-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-border-width: 2px;
}

.btn-outline-primary.btn-outline-style-2 {
  --bs-btn-color: var(--bs-primary);
}

.btn-outline-secondary {
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-border-width: 2px;
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
}

.btn-custom-light {
  --bs-btn-color: var(--bs-btn-light-color) !important;
}

.btn-custom-light:hover {
  --bs-btn-color: var(--bs-heading-color)!important;
}

.btn-custom-light:hover svg {
  fill: var(--bs-heading-color);
}

.btn-light-style-2 {
  --bs-btn-color: var(--bs-btn-light-2-color);
}

.btn-light-style-2 svg {
  fill: var(--bs-btn-light-2-color);
}

.btn-light-style-2:hover {
  --bs-btn-color: var(--bs-heading-color)!important;
}

.btn-light-style-2:hover svg {
  fill: var(--bs-heading-color);
}

.btn-custom-dark {
  --bs-btn-dark-color: #000000;
  --bs-btn-custom-hover-color: #000000;
  --bs-btn-color: var(--bs-btn-dark-color);
  --bs-btn-hover-color: var(--bs-btn-custom-hover-color)!important;
  --bs-btn-active-color: var(--bs-btn-custom-hover-color)!important;
  color: var(--bs-btn-dark-color);
}

.btn-map-direction {
  width: fit-content;
  --bs-btn-border-width: 2px !important;
}

.btn-gradient {
  background-image: linear-gradient(90deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  --bs-btn-padding-x: 2.9rem;
  --bs-btn-padding-y: 0.8rem;
  --bs-btn-border-width: 0;
  --bs-btn-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
  transition: all 0.2s ease-in-out;
}

.btn-gradient:hover {
  background-image: linear-gradient(90deg, var(--bs-secondary) 0%, var(--bs-primary) 100%);
}

.btn-gradient svg {
  fill: currentColor;
}

.btn-gradient.btn-map-direction {
  --bs-btn-border-width: 0 !important;
}

.btn-outline-gradient, .btn-outline-gradient.btn {
  --bs-btn-active-border-color: transparent;
  position: relative;
  text-decoration: none;
  -webkit-background-clip: text !important;
  z-index: 9;
  padding: 17px 39px !important;
  border-radius: 10px !important;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--bs-font-family-style-2);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  --bs-btn-color: var(--bs-heading-color) !important;
  --bs-btn-hover-color: #fff !important;
}

.btn-outline-gradient:hover, .btn-outline-gradient.btn:hover {
  border-radius: 10px;
}

.btn-outline-gradient:hover:after, .btn-outline-gradient.btn:hover:after {
  background: -webkit-linear-gradient(6deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
}

.btn-outline-gradient:hover:before, .btn-outline-gradient.btn:hover:before {
  background: -webkit-linear-gradient(6deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
}

.btn-outline-gradient:hover svg, .btn-outline-gradient.btn:hover svg {
  transition: all 0s ease-in-out;
}

.btn-outline-gradient:before, .btn-outline-gradient.btn:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 11px;
  transition: all 0.5s;
  background: rgba(var(--bs-primary-rgb), 1);
  background: -moz-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(var(--bs-primary-rgb), 1)), color-stop(100%, rgba(var(--bs-primary-rgb), 1)));
  background: -webkit-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: -o-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: -ms-linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  background: linear-gradient(96deg, var(--bs-primary) 0%, var(--bs-secondary) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--bs-primary)', endColorstr='var(--bs-secondary)', GradientType=1 );
}

.btn-outline-gradient:after, .btn-outline-gradient.btn:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 2px;
  z-index: -1;
  border-radius: 9px;
  transition: all 0.5s;
  background: var(--bs-body-bg);
}

@media (max-width: 991.98px) {
  .btn-outline-gradient, .btn-outline-gradient.btn {
    padding: 13px 24px !important;
  }
}

.btn-outline-gradient.btn-gradient-style-2, .btn-outline-gradient.btn.btn-gradient-style-2 {
  padding: 12.7px 48px !important;
}

.btn-outline-gradient.btn-gradient-style-2::before, .btn-outline-gradient.btn.btn-gradient-style-2::before {
  border-radius: 45px;
}

.btn-outline-gradient.btn-gradient-style-2::after, .btn-outline-gradient.btn.btn-gradient-style-2::after {
  border-radius: 43px;
}

.btn-outline-gradient.btn-gradient-style-2:hover::after, .btn-outline-gradient.btn.btn-gradient-style-2:hover::after {
  background: var(--bs-body-bg) !important;
}

body {
  padding-right: 0 !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  --bs-body-font-size: 18px;
}

@media (max-width: 1199.98px) {
  body {
    overflow-x: hidden;
  }
}

@media (max-width: 991.98px) {
  body {
    --bs-body-font-size: 16px;
  }
}

html {
  overflow-x: hidden;
}

.custom-roboto {
  font-family: var(--bs-font-family-style-2) !important;
}

.custom-sans {
  font-family: var(--bs-font-family-style-1) !important;
}

.custom-jakarta {
  font-family: var(--bs-font-family-style-4) !important;
}

.custom-poppins {
  font-family: var(--bs-font-family-style-3) !important;
}

.custom-font-style-2 {
  font-weight: 500;
  margin-bottom: 0;
}

.fw-extra-bold {
  font-weight: 800;
}

.fw-extra-huge-bold {
  font-weight: 900;
}

.extra-small {
  font-size: 12px;
  color: var(--bs-headings-color-light);
}

.fs-100 {
  font-size: 100px;
}

@media (max-width: 1399.98px) {
  .fs-100 {
    font-size: 80px;
  }
}

@media (max-width: 1199.98px) {
  .fs-100 {
    font-size: 70px;
  }
}

@media (max-width: 991.98px) {
  .fs-100 {
    font-size: 45px;
  }
}

@media (max-width: 767.98px) {
  .fs-100 {
    font-size: 40px;
  }
}

.fs-120 {
  font-size: 120px;
}

@media (max-width: 1399.98px) {
  .fs-120 {
    font-size: 90px;
  }
}

@media (max-width: 1199.98px) {
  .fs-120 {
    font-size: 80px;
  }
}

@media (max-width: 991.98px) {
  .fs-120 {
    font-size: 50px;
  }
}

@media (max-width: 575.98px) {
  .fs-120 {
    font-size: 37px;
  }
}

.fs-160 {
  font-size: 160px;
}

@media (max-width: 1399.98px) {
  .fs-160 {
    font-size: 130px;
  }
}

@media (max-width: 1199.98px) {
  .fs-160 {
    font-size: 100px;
  }
}

@media (max-width: 991.98px) {
  .fs-160 {
    font-size: 60px;
  }
}

@media (max-width: 575.98px) {
  .fs-160 {
    font-size: 50px;
  }
}

.fs-180 {
  font-size: 180px;
}

@media (max-width: 1399.98px) {
  .fs-180 {
    font-size: 140px;
  }
}

@media (max-width: 1199.98px) {
  .fs-180 {
    font-size: 120px;
  }
}

@media (max-width: 991.98px) {
  .fs-180 {
    font-size: 80px;
  }
}

@media (max-width: 575.98px) {
  .fs-180 {
    font-size: 60px;
  }
}

.fs-180-style-2 {
  font-size: 180px;
  line-height: 1;
}

@media (max-width: 1399.98px) {
  .fs-180-style-2 {
    font-size: 140px;
  }
}

@media (max-width: 1199.98px) {
  .fs-180-style-2 {
    font-size: 120px;
  }
}

@media (max-width: 991.98px) {
  .fs-180-style-2 {
    font-size: 96px;
  }
}

.extra-huge-text-1 {
  font-size: 200px;
  line-height: 175px;
  white-space: nowrap;
}

@media (max-width: 991.98px) {
  .extra-huge-text-1 {
    font-size: 100px;
    line-height: 80px;
  }
}

@media (max-width: 575.98px) {
  .extra-huge-text-1 {
    font-size: 60px;
    letter-spacing: -1.9px;
    line-height: 60px;
  }
}

@media (min-width: 1200px) and (max-width: 1590px) {
  .extra-huge-text-1 {
    font-size: 145px !important;
    line-height: 134px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .extra-huge-text-1 {
    font-size: 125px;
    line-height: 105px;
  }
}

.extra-huge-text-2 {
  font-size: 320px;
  font-weight: 900;
  position: relative;
  line-height: 240px;
  letter-spacing: -10.8px;
}

@media (max-width: 991.98px) {
  .extra-huge-text-2 {
    font-size: 170px;
    line-height: 135px;
    font-weight: 800;
    letter-spacing: -4.8px;
  }
}

@media (max-width: 575.98px) {
  .extra-huge-text-2 {
    font-size: 105px;
    line-height: 98px;
    font-weight: 700;
  }
}

@media (min-width: 1200px) and (max-width: 1590px) {
  .extra-huge-text-2 {
    font-size: 274px !important;
    line-height: 210px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .extra-huge-text-2 {
    font-size: 222px;
    line-height: 180px;
    letter-spacing: -7.8px;
  }
}

.extra-huge-text {
  font-size: 220px;
  position: relative;
  line-height: 200px;
}

@media (max-width: 1399.98px) {
  .extra-huge-text {
    font-size: 180px;
    line-height: 180px;
  }
}

@media (max-width: 1199.98px) {
  .extra-huge-text {
    font-size: 160px;
    line-height: 160px;
  }
}

@media (max-width: 991.98px) {
  .extra-huge-text {
    font-size: 100px;
    line-height: 120px;
  }
}

@media (max-width: 767.98px) {
  .extra-huge-text {
    font-size: 70px;
    line-height: 100px;
  }
}

@media (max-width: 575.98px) {
  .extra-huge-text {
    font-size: 54px;
    line-height: 56px;
  }
}

.customise-display-6 {
  --bs-heading-color: #d2d2d2;
  font-size: 42px;
  position: relative;
  color: var(--bs-heading-color);
}

@media (max-width: 1199.98px) {
  .customise-display-6 {
    font-size: 32px;
  }
}

@media (max-width: 991.98px) {
  .customise-display-6 {
    font-size: 24px;
  }
}

@media (max-width: 767.98px) {
  .customise-display-6 {
    font-size: 20px;
  }
}

.music-fest {
  --bs-heading-color: #d2d2d2;
  color: var(--bs-heading-color);
  font-size: 80px;
}

@media (max-width: 1399.98px) {
  .music-fest {
    font-size: 68px;
  }
}

@media (max-width: 1199.98px) {
  .music-fest {
    font-size: 58px;
  }
}

@media (max-width: 991.98px) {
  .music-fest {
    font-size: 48px;
  }
}

@media (max-width: 767.98px) {
  .music-fest {
    font-size: 38px;
  }
}

.sub-title.display-3 {
  line-height: 1;
}

@media (max-width: 991.98px) {
  .sub-title.display-3 {
    line-height: 1.2;
  }
}

.sub-title .gradient-subtitle {
  max-height: 122px;
  margin-top: -44px;
  width: 100%;
  filter: drop-shadow(20px 9px 36px rgba(var(--bs-primary-rgb), 0.3)) drop-shadow(2px 5px 15px rgba(var(--bs-primary-rgb), 0.4)) drop-shadow(2px 4px 8px rgba(var(--bs-secondary-rgb), 0.3)) drop-shadow(-2px -10px 5px rgba(var(--bs-secondary-rgb), 0.4));
}

@media (max-width: 991.98px) {
  .sub-title .gradient-subtitle {
    max-height: 118px;
    margin-top: -55px;
  }
}

.map-image {
  --bg-parallax-image: url(../images/map-tile.png);
  --bg-parallax-opacity: 1;
  height: 320px;
}

.map-image.parallax::after {
  border-radius: 30px;
}

.map-image .map-marker {
  position: absolute;
  top: 60px;
  left: 42%;
  color: var(--bs-primary);
}

.map-image .map-marker.map-marker-style-1 {
  color: var(--bs-heading-color);
}

.map-image .map-marker svg {
  height: 40px;
  width: 40px;
}

.map-image.map-image-2 {
  --bg-parallax-image: url(../images/map-tile-2.jpg);
  --bg-parallax-opacity: 1;
  height: 575px;
}

@media (max-width: 767.98px) {
  .map-image.map-image-2 {
    height: 375px;
  }
}

.map-image.map-image-2 .map-popup-content, .map-image.map-image-2 .map-marker {
  display: none;
}

.map-image.map-image-2 .marker-content {
  position: absolute;
  top: 200px;
  left: 170px;
}

.map-image.map-image-2 .marker-content .map-popup-content, .map-image.map-image-2 .marker-content .map-marker {
  display: block;
  left: 0;
}

.map-image.map-image-2 .marker-content .map-marker {
  top: 0;
}

.map-image.map-image-2 .marker-content .map-popup-content {
  top: 50px;
  left: 12px;
  border: 2px solid rgba(255, 255, 255, 0.63);
  background: rgba(255, 255, 255, 0.8);
  padding: 32px 30px;
}

.map-image.map-image-2 .marker-content .map-popup-content p {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .map-image.map-image-2 .marker-content {
    top: 80px;
    left: 125px;
  }
}

@media (max-width: 575.98px) {
  .map-image.map-image-2 .marker-content {
    top: 80px;
    left: 40px;
  }
}

.map-popup-content {
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.63);
  background: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(5px);
  width: 240px;
  height: auto;
  padding: 8px 16px 14px 12px;
  position: absolute;
  left: 47%;
  top: 110px;
}

.map-popup-content h3, .map-popup-content .h3, .map-popup-content p {
  color: var(--bs-heading-color);
  font-weight: 600;
}

.map-popup-content p {
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .map-popup-content {
    left: calc(50% - 120px);
  }
}

@media screen and (min-width: 991px) and (max-width: 1399px) {
  .map-popup-content {
    left: 28%;
  }
}

.eventiva-marker-icon svg {
  width: 100%;
  height: 100%;
  fill: var(--bs-primary);
}

.leaflet-popup-close-button {
  top: 5px !important;
  right: 6px !important;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.leaflet-popup-close-button span {
  position: relative;
  bottom: 1px;
  color: var(--bs-heading-color);
  transition: all 0.2s ease-in-out;
}

.leaflet-popup-close-button:hover span {
  color: var(--bs-white);
}

.leaflet-pane {
  z-index: 40;
}

.leaflet-top, .leaflet-bottom {
  z-index: 50;
}

.leaflet-popup-tip-container {
  width: 0;
}

.leaflet-routing-collapse-btn {
  right: 30px;
  color: var(--bs-primary);
  cursor: pointer;
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn {
  left: 2px;
  top: -1px;
  width: 26px;
  height: 23px;
  background-repeat: no-repeat;
}

#RoutingMap {
  width: 100%;
  min-height: calc(100vh - 250px);
}

.modal#RoutingMapModal {
  --bs-modal-width: 100%;
  z-index: 999999;
}

.modal#RoutingMapModal .eventiva-destination-marker-icon {
  margin-left: -12px;
  margin-top: -35px;
}

.modal#RoutingMapModal .eventiva-destination-marker-icon svg {
  fill: var(--bs-primary);
}

.modal#RoutingMapModal .eventiva-current-marker-icon svg {
  fill: green;
}

.routing-map-modal img.leaflet-marker-icon {
  opacity: 0;
}

.leaflet-container {
  font-family: var(--bs-font-family-style-1);
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 2px solid rgba(var(--bs-primary-rgb), 0.7);
  background-clip: padding-box;
}

.leaflet-routing-container {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important;
}

.leaflet-popup-content {
  min-width: 184px;
  margin: 0;
}

@media (min-width: 1200px) {
  .direction-style-2 {
    position: relative;
    left: -38px;
  }
}

@media (min-width: 1400px) {
  .direction-style-2 {
    position: relative;
    left: -18px;
  }
}

@media (min-width: 1450px) {
  .direction-style-2 {
    position: relative;
    left: -60px;
  }
}

/*# sourceMappingURL=style.css.map */