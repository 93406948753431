
.countdown-style-3{
  @include media-breakpoint-down(lg) {
    top: -50px;
  }
}

.countdown-item {
  display: flex;
  align-items: center;  
}

.countdown-number {    
  font-size: 120px;
  font-weight: 800; 
  @include media-breakpoint-down(xxl) {
    font-size: 90px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 80px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 60px;
  }
}

.countdown-label {
  --#{$prefix}countdown-text-stroke-color: #f2f2f2;
  color: var(--#{$prefix}heading-color);
  font-size: 42px;
  font-weight: 700;
  text-transform: capitalize; 
  margin-left: -20px;
  z-index: 1; 
  &.countdown-text-stroke{
    -webkit-text-stroke: 2px var(--#{$prefix}countdown-text-stroke-color);
    font-weight: 900;
  }    
  @include media-breakpoint-down(xxl) {
    font-size: 32px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 30px;
    font-weight: 500;
    &.countdown-text-stroke{
      font-size: 32px;  
      font-weight: 900;
    }
  }
  @include media-breakpoint-down(sm) {
    font-size: 24px;
    margin-left: -10px;

    &.countdown-text-stroke{
      -webkit-text-stroke: 1px var(--#{$prefix}countdown-text-stroke-color);
      font-size: 25px;  
      font-weight: 900;
    }      
  }
}  

.odometer-value{
  margin-top: 0 !important;
}

.event-odometer-heading{
  color: var(--#{$prefix}heading-color);
  font-weight: 800;
}

.event-counter{
  &.event-counter-2{
    .odometer-digit:last-child{
      padding: 0 6px 0 0;
    }    
  }
  .odometer-digit{
    padding: 0 1px 0 0;
  }    
  @include media-breakpoint-down(xxl){
    .odometer.display-2{
      font-size: 62px;
    }
  }
  @include media-breakpoint-down(xl){
    .odometer.display-2{
      font-size: 55px;
      line-height: 55px;
    }
    h3{
      font-size: 20px;
    }
  }
}

.event-counter.event-counter-style-2{
  .display-2{
    font-size: 110px;
  }
  h3{
    font-size: 36px;
  }
  @include media-breakpoint-down(xxl){
    .display-2{
      font-size: 80px;
    }
    h3{
      font-size: 28px;
    }
  }
  @include media-breakpoint-down(xxl){
    .display-2{
      font-size: 75px;
    }
    h3{
      font-size: 25px;
    }
  }
  @include media-breakpoint-down(lg){
    .display-2{
      font-size: 60px;
    }
    h3{
      font-size: 20px;
    }
  }
  @include media-breakpoint-down(md){
    .display-2{
      font-size: 52px;
    }
    h3{
      font-size: 18px;
    }
  }
}


.countdown-gradient {

    #days{
      -webkit-text-stroke: 2.5px #AA0D64;
      color: transparent;
        &.primary-text-shadow{  
          filter: drop-shadow(10px 9px 46px rgba(170, 13, 100,.6))
                  drop-shadow(0 11px 15px rgba(170, 13, 100,.20))
                  drop-shadow(0 24px 38px rgba(170, 13, 100,.20))
                  drop-shadow(0 -3px 5px rgba(170, 13, 100,.20));
        }
    }
    #hours{
      -webkit-text-stroke: 2.5px #8B0C7D;
      color: transparent;
        &.primary-text-shadow{  
          filter: drop-shadow(10px 9px 46px rgba(139, 12, 125,.6))
                  drop-shadow(0 11px 15px rgba(139, 12, 125,.20))
                  drop-shadow(0 24px 38px rgba(139, 12, 125,.20))
                  drop-shadow(0 -3px 5px rgba(139, 12, 125,.20));
        }
    }
    #minutes{
      -webkit-text-stroke: 2.5px #740B91;
      color: transparent;
        &.primary-text-shadow{  
          filter: drop-shadow(10px 9px 46px rgba(116, 11, 145,.6))
                  drop-shadow(0 11px 15px rgba(116, 11, 145,.20))
                  drop-shadow(0 24px 38px rgba(116, 11, 145,.20))
                  drop-shadow(0 -3px 5px rgba(116, 11, 145,.20));
        }
    }
    #seconds{
      -webkit-text-stroke: 2.5px #4D0AB1;
      color: transparent;
      &.primary-text-shadow{  
        filter: drop-shadow(10px 9px 46px rgba(77, 10, 177,.6))
                drop-shadow(0 11px 15px rgba(77, 10, 177,.20))
                drop-shadow(0 24px 38px rgba(77, 10, 177,.20))
                drop-shadow(0 -3px 5px rgba(77, 10, 177,.20));
      }
    }
}