
// ======================== Faq-1 ==========================>>>>
.faq-wrapper{
    --#{$prefix}custom-inner-bg: #EBEDFF;
    .accordion{
        --#{$prefix}accordion-bg: var(--#{$prefix}custom-inner-bg);
        --#{$prefix}accordion-active-bg: var(--#{$prefix}custom-inner-bg);
        --#{$prefix}accordion-active-color: var(--#{$prefix}heading-color);
        --#{$prefix}accordion-btn-color: var(--#{$prefix}heading-color);
        --#{$prefix}accordion-active-bg: var(--#{$prefix}custom-inner-bg);
        --#{$prefix}accordion-btn-focus-border-color: none;
        --#{$prefix}accordion-body-padding-x: 44px;
        --#{$prefix}accordion-body-padding-y: 1rem;
        --#{$prefix}accordion-btn-padding-x: 44px;
        --#{$prefix}accordion-btn-padding-y: 1.5rem; 
        --#{$prefix}accordion-inner-border-radius :30px; 
        --#{$prefix}border-radius:30px;
        max-width: 830px;
        left: 13px;
        
        .accordion-item{
            overflow: hidden;
        }
        .accordion-body{
            background-color: var(--#{$prefix}custom-inner-bg);            
        }     
        .accordion-button  {
            font-size: 18px;
            font-weight: 700;            
            @include media-breakpoint-down(md) {
                font-size: 16px;  
                span{
                    max-width: 256px;
                }
            }
            &::after{
                content: none;
            }
            &.collapsed {
                .faq-arrow{
                    transform: rotate(135deg);
                }
            }
            .faq-arrow{
                transform: rotate(45deg); 
                transition: all 0.3s ease-in-out;
                svg{                    
                    @include media-breakpoint-down(md) {
                        width: 28px;  
                    }
                }
            }
            &:not(.collapsed){
                box-shadow: none;
            }

            &:focus,&:focus-visible,&:focus-within{
                border: none;
                box-shadow: none;
            }
        }       
        @include media-breakpoint-down(md) {
            --#{$prefix}accordion-btn-padding-x: 12px;
            --#{$prefix}accordion-body-padding-x: 12px;
        }  
    }
    .ellipse-image-2{
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: -50px;
        margin-left: -135px;
        z-index: -1;
        animation: move-x 4s infinite  alternate;
        @include media-breakpoint-down(md) {
            margin-right: 0;
            margin-top: -40px;  
        }
    }
}
// ======================== Faq-1 ==========================>>>>
